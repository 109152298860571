import { AppName } from '@infinitusai/api';
import { useAuth } from '@infinitusai/auth';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useReducer } from 'react';

import { ClientEventType } from '@infinitus/generated/frontend-common';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import {
  useExperiment,
  vonageSdkUpgradeParticipant,
  NEW_VONAGE_SDK_COHORT_KEY,
} from '@infinitus/hooks/useExperiment';
import { useVoice } from '@infinitus/voice';

import NexmoClientService, {
  defaultLogEventHandler,
  EarMuffState,
  Events,
  LogEventFunction,
  MuteState,
} from './NexmoClientService';
import NexmoContext, { NexmoClientWrapper } from './NexmoContext';
import VonageClientService from './VonageClientService';
import { webRtcIssueDetector } from './WebRtcIssueDetector';
import { getNexmoSdkVersion } from './version';

export interface NexmoClientProviderProps {
  appName: AppName;
  children?: ReactNode;
  logEvent?: LogEventFunction;
}

export enum ConnectionState {
  UNKNOWN = 'Unknown',
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Audio connecting...',
  CONNECTED = 'Audio connected',
}

type NexmoState = Pick<
  NexmoClientWrapper,
  'muteState' | 'changingMuteState' | 'earmuffState' | 'connectionState'
>;

const initialState = {
  muteState: MuteState.UNKNOWN,
  earmuffState: EarMuffState.UNKNOWN,
  connectionState: ConnectionState.DISCONNECTED,
  changingMuteState: false,
};

const reducer = (state: NexmoState, action: { payload: any; type: Events }): NexmoState => {
  switch (action.type) {
    case Events.DISCONNECT_DETECTED:
    case Events.LOGIN_FAILED:
    case Events.LEFT_CONVERSATION:
      return initialState;
    case Events.EARMUFF_STATE_CHANGE:
      return {
        ...state,
        earmuffState: action.payload,
      };
    case Events.JOINING_CONVERSATION:
      return {
        ...state,
        connectionState: ConnectionState.CONNECTING,
      };
    case Events.MUTE_STATE_CHANGE:
      return {
        ...state,
        muteState: action.payload,
      };
    case Events.CHANGING_MUTE_STATE:
      return {
        ...state,
        changingMuteState: true,
      };
    case Events.CHANGED_MUTE_STATE:
      return {
        ...state,
        changingMuteState: false,
      };
    case Events.JOINED_CONVERSATION:
      return {
        ...state,
        connectionState: ConnectionState.CONNECTED,
      };
    case Events.LOGIN_SUCCEEDED:
    case Events.ATTEMPTING_AUTO_RECONNECT:
      return state;
  }
};

// start watching peer connections. For the time being we will not stop watching the connections
webRtcIssueDetector.watchNewPeerConnections();

export function NexmoClientProvider({
  appName,
  children,
  logEvent = defaultLogEventHandler,
}: NexmoClientProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { loading: loadingExperiment, isInCohort } = useExperiment({
    experiment: vonageSdkUpgradeParticipant(user?.email ?? ''),
  });
  const nexmoClientIsReady = appName !== AppName.OPERATOR || !loadingExperiment;
  const useNewSdkExperiment = appName === AppName.OPERATOR && isInCohort(NEW_VONAGE_SDK_COHORT_KEY);

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    microphoneDeviceId,
    speakerDeviceId,
    devicesLastUpdated,
    autoGainControl,
    echoCancellation,
    noiseSuppression,
  } = useVoice();

  const logEventRef = useRef<LogEventFunction>();
  logEventRef.current = logEvent;

  // nexmoClient will be instantiated just once.
  const nexmoClient = useMemo(() => {
    const version = getNexmoSdkVersion();
    const useVonageSdk =
      appName === AppName.FASTTRACK ? version === '@vonage/client-sdk' : useNewSdkExperiment;
    const NexmoSdkClientService = useVonageSdk ? VonageClientService : NexmoClientService;
    console.log(`[Nexmo] Using ${useVonageSdk ? 'new' : 'old'} Nexmo SDK`);
    return new NexmoSdkClientService(undefined, {
      appName,
      logEvent: async (event) => {
        if (logEventRef.current) void logEventRef.current(event);
        console.log(JSON.stringify(event));
      },
    });
  }, [appName, useNewSdkExperiment]);

  // Whenever the selected microphone device changes, we need to update the nexmo client
  useEffect(() => {
    if (microphoneDeviceId)
      void nexmoClient.updateAudioConstraints({
        deviceId: { exact: microphoneDeviceId },
        autoGainControl,
        echoCancellation,
        noiseSuppression,
      });
  }, [
    nexmoClient,
    microphoneDeviceId,
    devicesLastUpdated,
    autoGainControl,
    echoCancellation,
    noiseSuppression,
  ]);

  // Whenever the selected speaker device changes, we need to update the nexmo client
  useEffect(() => {
    if (speakerDeviceId) void nexmoClient.updateOutputDevice(speakerDeviceId);
  }, [nexmoClient, speakerDeviceId, devicesLastUpdated]);

  useEffect(() => {
    // For all events emitted from nexmo client, let's add a listener to dispatch events.
    Object.keys(Events).forEach((e) => {
      nexmoClient.on(e, (arg: any) => {
        dispatch({ type: e as Events, payload: arg });

        // Display user feedback when we automatically attempt to recover from a disconnect
        if (e === Events.ATTEMPTING_AUTO_RECONNECT) {
          enqueueSnackbar('Call audio issue, attempting to recover...', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          });
        }
      });
    });

    return () => {
      nexmoClient.removeAllListeners();
    };
  }, [enqueueSnackbar, nexmoClient]);

  useEffect(() => {
    const initializeNexmoService = async () => {
      // Assert that we're signed in before attempting to query the backend
      if (user) {
        try {
          await nexmoClient.initialize();
        } catch (e) {
          console.error(`[Nexmo] Failed to initialize nexmo service: ${e}`);
        }
      }
    };

    void initializeNexmoService();
  }, [nexmoClient, user]);

  // When the user signs out, we should log out of the Nexmo client
  useEffect(() => {
    if (!user) {
      void nexmoClient.logout();
    }
  }, [nexmoClient, user]);

  // Forward the attach request directly to the Nexmo client, but intercept it
  // to add timing measurements
  const attach = useCallback(
    async (...args: Parameters<NexmoClientService['attach']>) => {
      console.log(`[Nexmo] NexmoClientProvider: Attaching to conversation`);
      try {
        await nexmoClient.attach(...args);
      } catch (e: any) {
        enqueueSnackbar(`Failed to connect audio, please leave the call, refresh and join again`, {
          variant: 'error',
        });
        console.error(`[Nexmo] Nexmo failed to connect audio: ${JSON.stringify(e.message)}`);
        throw e;
      }
    },
    [enqueueSnackbar, nexmoClient]
  );

  const handleSetEarmuffed = (earmuffed: boolean) => {
    void nexmoClient.setEarmuffed(earmuffed);
    void logEvent({
      message: `NexmoClientProvider: Operator set earmuffed to ${earmuffed}`,
      clientEventType: ClientEventType.CALL_ACTIONS,
      meta: {
        earmuffed,
      },
    });
    console.log(`[Nexmo] NexmoClientProvider: Operator set earmuffed to ${earmuffed}`);
  };

  const handleSetMuted = useCallback(
    (muted: boolean) => {
      void nexmoClient.setMuted(muted);
      void logEvent({
        message: `NexmoClientProvider: Operator set muted to ${muted}`,
        clientEventType: ClientEventType.CALL_ACTIONS,
        meta: {
          muted,
        },
      });
      console.log(`[Nexmo] NexmoClientProvider: Operator set muted to ${muted}`);
    },
    [logEvent, nexmoClient]
  );

  return (
    <NexmoContext.Provider
      value={{
        ...state,
        nexmoClientIsReady,
        attach,
        maybeLeaveConversation: nexmoClient.maybeLeaveConversation,
        setEarmuffed: handleSetEarmuffed,
        setMuted: handleSetMuted,
        startNewSession: nexmoClient.startNewSession,
        deleteSession: () => nexmoClient.logout(),
        changeInputAudioSource: (microphoneDeviceId: string) =>
          nexmoClient.updateAudioConstraints({ deviceId: { exact: microphoneDeviceId } }),
      }}
    >
      {children}
    </NexmoContext.Provider>
  );
}
