import Typography from '@mui/material/Typography';

import { Icon, IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import { Link } from 'components/Link';

import useStyles from './BreadcrumbStyles';

interface Props {
  iconName?: IconNames;
  iconOutlined?: boolean;
  index?: number;
  size?: ShirtSizes;
  title: string;
  to?: string;
}

function Breadcrumb({ iconName, iconOutlined, index, size, title, to }: Props) {
  const classes = useStyles();
  let typeVariant: 'h1' | 'h2' | 'h6' | 'subtitle1' = 'h2';
  if (index === 0) {
    if (!size) {
      typeVariant = 'h1';
    }
  }
  if (size === ShirtSizes.SM) {
    typeVariant = 'h6';
  } else if (size === ShirtSizes.XS) {
    typeVariant = 'subtitle1';
  }
  let el = (
    <Typography sx={classes.title} variant={typeVariant}>
      {title}
    </Typography>
  );
  if (to) {
    el = (
      <Link sx={classes.link} to={to}>
        {el}
      </Link>
    );
  }
  if (iconName) {
    el = (
      <>
        <Icon name={iconName} outlined={iconOutlined} size={ShirtSizes.LG} sx={classes.icon} />
        {el}
      </>
    );
  }
  return <div style={classes.breadcrumb}>{el}</div>;
}

export default Breadcrumb;
