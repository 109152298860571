/**
 * Microphone permissions.
 */
export enum MicrophonePermission {
  DENIED = 'denied',
  GRANTED = 'granted',
  PROMPT = 'prompt',
  UNKNOWN = 'unknown',
}

/**
 * The audio device info
 */
export interface AudioDevice {
  id: string;
  label: string;
}

/**
 * The initial audio device state.
 */
export const initialState: VoiceState = {
  error: null,
  loading: false,
  microphoneMuted: true,
  microphonePermission: MicrophonePermission.UNKNOWN,
  microphoneDevices: [],
  speakerDevices: [],
  devicesLastUpdated: new Date().getTime(),
  autoGainControl: sessionStorage.getItem('autoGainControl') !== 'false',
  echoCancellation: sessionStorage.getItem('echoCancellation') !== 'false',
  noiseSuppression: sessionStorage.getItem('noiseSuppression') !== 'false',
};

/**
 * The audio device state which.
 */
export interface VoiceState {
  autoGainControl: boolean;
  devicesLastUpdated: number;
  echoCancellation: boolean;
  error: Error | null;
  loading: boolean;
  microphoneDeviceId?: string;
  microphoneDevices: AudioDevice[];
  microphoneMuted: boolean;
  microphonePermission: MicrophonePermission;
  noiseSuppression: boolean;
  speakerDeviceId?: string;
  speakerDevices: AudioDevice[];
}

export interface VoiceContextInterface extends VoiceState {
  /**
   * Checks microphone permission.
   */
  checkMicrophonePermission: () => Promise<MicrophonePermission>;
  /**
   * Load media devices.
   */
  loadMediaDevices: () => void;
  /**
   * Play tone
   */
  playTone: (tone: string) => Promise<void>;
  /**
   * Requests microphone permission.
   */
  requestMicrophonePermission: () => Promise<MicrophonePermission>;
  /**
   * Toggle automatic gain control.
   */
  setAutoGainControl: (enabled: boolean) => void;
  /**
   * Toggle echo cancellation
   */
  setEchoCancellation: (enabled: boolean) => void;
  /**
   * Sets the microphone device.
   */
  setMicrophoneDevice: (deviceId: string) => void;
  /**
   * Toggle noise suppression.
   */
  setNoiseSuppression: (enabled: boolean) => void;
  /**
   * Sets the speaker device.
   */
  setSpeakerDevice: (deviceId: string) => void;
}
