import { assertUnreachable } from '@infinitus/utils';
import { FieldSource } from 'generated/gql/graphql';
import {
  CallOutputsFragment_outputs_value,
  CallOutputsQuery_callOutputs_outputs_value_DateType,
  ReadOutputValue_readOutputValue,
  ReadOutputValue_readOutputValue_value,
  RulesQuery_rules_effects_OutputFieldValueEffect_expectedValue,
} from 'types/gqlMapping';
import { Output } from 'types/graphql-simple';

type ExtractedOutputValue = string | string[] | number | boolean;

interface ExtractedOutputValueOptions {
  // The date formats are based on dayjs date formats: https://day.js.org/docs/en/display/format
  dateFormat: 'M/D/YYYY' | 'YYYY/MM/DD';
}

const defaultOptions: ExtractedOutputValueOptions = {
  dateFormat: 'YYYY/MM/DD',
};

export function extractOutputValue(
  outputValue:
    | CallOutputsFragment_outputs_value
    | ReadOutputValue_readOutputValue_value
    | RulesQuery_rules_effects_OutputFieldValueEffect_expectedValue,
  options?: Partial<ExtractedOutputValueOptions>
) {
  const optionsWithDefaults = {
    ...defaultOptions,
    ...options,
  };
  let returnValue: ExtractedOutputValue | undefined;

  const __typename = outputValue?.__typename;
  switch (__typename) {
    case 'StringType':
      returnValue = outputValue.string;
      break;
    case 'IntType':
      returnValue = outputValue.int;
      break;
    case 'BooleanType':
      returnValue = outputValue.bool;
      break;
    case 'MoneyType':
      returnValue = outputValue.amountInCents;
      break;
    case 'EnumType':
      returnValue = outputValue.enum;
      break;
    case 'EnumsType':
      returnValue = outputValue.enums;
      break;
    case 'DateType':
      const date = outputValue as CallOutputsQuery_callOutputs_outputs_value_DateType;
      switch (optionsWithDefaults.dateFormat) {
        case 'M/D/YYYY':
          returnValue = `${date.month}/${date.day}/${date.year}`;
          break;
        case 'YYYY/MM/DD':
          const yearStr = date.year.toString().padStart(4, '0');
          const monthStr = date.month.toString().padStart(2, '0');
          const dayStr = date.day.toString().padStart(2, '0');
          returnValue = `${yearStr}/${monthStr}/${dayStr}`;
          break;
        default:
          assertUnreachable(optionsWithDefaults.dateFormat);
      }
      break;
    case 'AgentCouldNotAnswerType':
      returnValue = outputValue.agentCouldNotAnswer;
      break;
    default:
      try {
        assertUnreachable(__typename);
      } catch (e: any) {
        console.error(`hit unexpected code path in extractOutputValue: ${JSON.stringify(e)}`);
      }
  }
  return returnValue;
}

export const readSingleCallOutputValue = (
  callOutputs: Output[] | undefined,
  fieldName: string
): ExtractedOutputValue | undefined => {
  if (!Array.isArray(callOutputs)) return undefined;
  const outputObj = callOutputs.find((output) => output.name === fieldName);
  if (!outputObj || !outputObj.value) return undefined;
  return extractOutputValue(outputObj.value);
};

export const readCallOutputValueForSource = (
  callOutput: Output | ReadOutputValue_readOutputValue,
  fieldSource: FieldSource
): ExtractedOutputValue | undefined => {
  if (callOutput.source === fieldSource) {
    if (!callOutput.value) return undefined;
    return extractOutputValue(callOutput.value);
  }

  const fieldSourceOutput = callOutput.additionalSources.find((s) => s.source === fieldSource);
  if (!fieldSourceOutput || !fieldSourceOutput.value) return undefined;
  return extractOutputValue(fieldSourceOutput.value);
};

export const callOutputValueIsEmpty = (
  callOutput: Output | ReadOutputValue_readOutputValue
): boolean => {
  // Call output value is empty/unset
  if (!callOutput.value) return true;

  const val = extractOutputValue(callOutput.value);

  if (val === undefined) return true;

  // Check for StringType or EnumType value
  if (typeof val === 'string' && val === '') return true;

  // Check for EnumsType value
  if (Array.isArray(val) && val.length === 0) return true;

  // TODO: Maybe add checks for remaining output types? Other types should be
  // undefined if empty, but if not, we should add checks

  return false;
};
