// polyfill
// @ts-ignore
var AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

var dtmfFrequencies = {
  '1': { f1: 697, f2: 1209 },
  '2': { f1: 697, f2: 1336 },
  '3': { f1: 697, f2: 1477 },
  '4': { f1: 770, f2: 1209 },
  '5': { f1: 770, f2: 1336 },
  '6': { f1: 770, f2: 1477 },
  '7': { f1: 852, f2: 1209 },
  '8': { f1: 852, f2: 1336 },
  '9': { f1: 852, f2: 1477 },
  '*': { f1: 941, f2: 1209 },
  '0': { f1: 941, f2: 1336 },
  '#': { f1: 941, f2: 1477 },
};

class Tone {
  audio: HTMLAudioElement;
  context: AudioContext;
  freq1: number;
  freq2: number;
  osc1?: OscillatorNode;
  osc2?: OscillatorNode;
  gainNode?: GainNode;
  filter?: BiquadFilterNode;

  constructor(context: AudioContext, freq1: number, freq2: number) {
    this.audio = new Audio();
    this.context = context;
    this.freq1 = freq1;
    this.freq2 = freq2;
  }

  async setup(deviceId?: string) {
    this.osc1 = this.context.createOscillator();
    this.osc2 = this.context.createOscillator();
    this.osc1.frequency.value = this.freq1;
    this.osc2.frequency.value = this.freq2;

    this.gainNode = this.context.createGain();
    this.gainNode.gain.value = 0.25;

    this.filter = this.context.createBiquadFilter();
    this.filter.type = 'lowpass';
    this.filter.frequency.value = 8000;

    this.osc1.connect(this.gainNode);
    this.osc2.connect(this.gainNode);

    this.gainNode.connect(this.filter);

    // Create a MediaStreamDestination to output the audio through the HTMLAudioElement
    const streamDestination = this.context.createMediaStreamDestination();
    this.filter?.connect(streamDestination); // Connect Tone's gainNode to the destination
    this.audio.srcObject = streamDestination.stream;

    // If a deviceId was provided, we use setSinkId to select the output device
    // @ts-ignore
    if (deviceId && this.audio.setSinkId) {
      // @ts-ignore
      await this.audio.setSinkId(deviceId);
    }
  }

  cleanup() {
    this.osc1?.disconnect();
    this.osc2?.disconnect();
    this.gainNode?.disconnect();
    this.filter?.disconnect();
  }

  async start() {
    await this.audio.play();
    this.osc1?.start(0);
    this.osc2?.start(0);
  }

  stop() {
    this.osc1?.stop(0);
    this.osc2?.stop(0);
    this.audio.onended = () => {
      this.audio.pause();
      this.audio.srcObject = null;
    };
  }
}

export async function playAudioTone(tone: string, deviceId?: string) {
  const audioContext = AudioContext ? new AudioContext() : null;
  const frequencyPair = dtmfFrequencies[tone as keyof typeof dtmfFrequencies];
  if (!frequencyPair || !audioContext) return; // Only play tone for valid dtmf characters
  const dtmf = new Tone(audioContext, frequencyPair.f1, frequencyPair.f2);

  try {
    await dtmf.setup(deviceId);
    await dtmf.start();
    setTimeout(() => {
      dtmf.stop();
      dtmf.cleanup();
    }, 200);
  } catch (error) {
    console.error('Error playing audio tone:', error);
  }
}
