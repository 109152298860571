import { useAuth } from '@infinitusai/auth';
import Tracker from '@openreplay/tracker';
import { useEffect } from 'react';

import {
  OPEN_REPLAY_ENABLED_COHORT_KEY,
  openReplayParticipant,
  useExperiment,
} from '@infinitus/hooks/useExperiment';
import { UUID } from '@infinitus/types';
import { excludeProp } from '@infinitus/utils/excludeProp';
import { isLocalhost } from '@infinitus/utils/isLocalhost';

const createTracker = () => {
  const ingestPoint = process.env.REACT_APP_OPENREPLAY_INGEST_POINT;
  const projectKey = process.env.REACT_APP_OPENREPLAY_PROJECT_KEY;
  // Set __DISABLE_SECURE_MODE to allow OpenReplay to work from localhost https://docs.openreplay.com/en/v1.7.0/sdk/constructor/#security
  const disableSecureMode = isLocalhost;
  if (!projectKey) {
    console.info(
      'REACT_APP_OPENREPLAY_PROJECT_KEY is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  if (!ingestPoint) {
    console.info(
      'REACT_APP_OPENREPLAY_INGEST_POINT is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  return new Tracker({
    projectKey,
    ingestPoint,
    __DISABLE_SECURE_MODE: disableSecureMode,
  });
};

// Global instance since there only needs to be one
const tracker = createTracker();

const useOpenReplayTracker = () => {
  const { user } = useAuth();
  const { isInCohort } = useExperiment({
    experiment: openReplayParticipant(user?.email || ''),
  });

  useEffect(() => {
    if (tracker && user?.email && isInCohort(OPEN_REPLAY_ENABLED_COHORT_KEY)) {
      void tracker.start({
        userID: user.email,
      });
      return () => {
        tracker.stop();
      };
    }
  }, [isInCohort, user?.email]);
};

export const OpenReplayTracker = () => {
  useOpenReplayTracker();
  return null;
};

// Add additional events to this union
export type OpenReplayCustomEvent =
  | {
      callUuid: UUID;
      eventName: 'CallPageImpression';
      orgUuid: UUID;
      taskUuid: UUID;
    }
  | {
      eventName: 'TaskPageImpression';
      orgUuid: UUID;
      taskUuid: UUID;
    };

export const trackOpenReplayEvent = (event: OpenReplayCustomEvent) => {
  const eventWithoutEventName = excludeProp(event, 'eventName');
  if (tracker) {
    tracker.event(event.eventName, eventWithoutEventName);
  }
};
