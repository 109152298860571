import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { logCriticalReqMetrics } from '@infinitus/hooks/useLogBuffer';

export type RequestTiming = {
  endTime?: number;
  startTime: number;
};

export type CriticalReqLoggingContext = {
  collectionWindowMs: number;
  logged: boolean;
  networkRequestTimings: Map<string, RequestTiming>;
  startTime: number;
};

export type TimingMetric = Required<RequestTiming> & {
  componentName: string;
  durationMs: number;
};

export type CriticalReqMetrics = {
  blockingRequestDurationMs: number;
  componentMetrics: TimingMetric[];
  durationMs: number;
  startTime: number;
};

// Use singleton instance
export const criticalReqState: CriticalReqLoggingContext = {
  networkRequestTimings: new Map(),
  startTime: Date.now(),
  collectionWindowMs: 2000,
  logged: false,
};

/* 
  A page can be composed of multiple components, some of which are critical
  for user interaction and some are not. The time at which the last critical
  component is loaded is considered the "page load time". To avoid having to
  manually define all the critical components at the top level of each page,
  we let a component define itself as critical using the usePageLoadLogger
  hook.
  */
export const CriticalReqLogger = () => {
  const location = useLocation();
  const intervalHandleRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const calcLoadTimeAndMaybeLog = useCallback(() => {
    if (intervalHandleRef.current === undefined || criticalReqState.logged) {
      // Already logged
      return;
    }
    if (criticalReqState.networkRequestTimings.size === 0) {
      // No network requests made, so no page load time to calculate
      return;
    }

    const allTimingsResolved = _.every(
      Array.from(criticalReqState.networkRequestTimings.values()),
      (timing) => timing.endTime !== undefined
    );

    if (!allTimingsResolved) {
      // Wait for all timings to be resolved
      return;
    }

    const maxEndTime =
      _.max(
        Array.from(criticalReqState.networkRequestTimings.values()).map(
          (timing) => timing.endTime ?? 0
        )
      ) ?? 0;

    logCriticalReqMetrics(createCriticalReqMetrics(criticalReqState, maxEndTime));
    // Once logged, stop logging and stop the interval
    criticalReqState.logged = true;
    clearInterval(intervalHandleRef.current);
    intervalHandleRef.current = undefined;
  }, []);

  const reset = useCallback(() => {
    criticalReqState.networkRequestTimings.clear();
    criticalReqState.startTime = Date.now();
    criticalReqState.logged = false;
    if (intervalHandleRef.current === undefined) {
      intervalHandleRef.current = setInterval(
        calcLoadTimeAndMaybeLog,
        criticalReqState.collectionWindowMs
      );
    }
  }, [calcLoadTimeAndMaybeLog]);

  useEffect(() => {
    // Initial timer start
    reset();

    return () => {
      // Try to log one last time before unmounting
      calcLoadTimeAndMaybeLog();
      clearInterval(intervalHandleRef.current);
    };
  }, [calcLoadTimeAndMaybeLog, reset]);

  // Detect route changes and reset the start time
  useEffect(() => {
    // Try to log one last time before unmounting
    calcLoadTimeAndMaybeLog();
    // Reset the state since we're on a new page
    reset();
  }, [calcLoadTimeAndMaybeLog, location.pathname, reset]);

  return null;
};

const createCriticalReqMetrics = (
  context: CriticalReqLoggingContext,
  endTime: number
): CriticalReqMetrics => {
  const componentMetrics: TimingMetric[] = [];

  for (const [componentName, timing] of context.networkRequestTimings.entries()) {
    if (timing.endTime === undefined) {
      continue;
    }
    componentMetrics.push({
      componentName,
      durationMs: timing.endTime - timing.startTime,
      endTime: timing.endTime,
      startTime: timing.startTime,
    });
  }

  return {
    startTime: context.startTime,
    componentMetrics,
    durationMs: endTime - context.startTime,
    blockingRequestDurationMs: blockingRequestTimeMs(context.networkRequestTimings),
  };
};

const blockingRequestTimeMs = (networkRequestTimings: Map<string, RequestTiming>) => {
  const ranges: [number, number][] = Array.from(networkRequestTimings.values()).map((timing) => [
    timing.startTime,
    timing.endTime ?? timing.startTime,
  ]);
  const mergedRanges = mergeRanges(ranges);
  return mergedRanges.reduce(
    (totalBlockingTime, [start, end]) => totalBlockingTime + (end - start),
    0
  );
};

/**
 * Given a list of ranges [START, END], return a list of ranges where any
 * overlaps have been merged.
 *
 * Graphical Example:
 * Input:
 *   [------]    [---]
 *      [-]    [---]
 * Expected Output:
 *   [------]  [-----]
 *
 * Numerical Example:
 * Input: [[2,9],[5,7],[12,16], [14,18]]
 * Output: [[2,9],[12,18]]
 * @param ranges
 */
const mergeRanges = (ranges: [number, number][]): [number, number][] => {
  const sortedRanges = _.sortBy(ranges, (range) => range[0]);
  const mergedRanges: [number, number][] = [];

  let currentRange: [number, number] = sortedRanges[0];
  for (let i = 1; i < sortedRanges.length; i++) {
    const nextRange = sortedRanges[i];
    if (nextRange[0] <= currentRange[1]) {
      // Overlap
      currentRange[1] = Math.max(currentRange[1], nextRange[1]);
    } else {
      // No overlap
      mergedRanges.push(currentRange);
      currentRange = nextRange;
    }
  }
  mergedRanges.push(currentRange);

  return mergedRanges;
};
