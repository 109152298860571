// eslint-disable-next-line no-restricted-imports
import dayjs, { Dayjs } from 'dayjs';

import { initDayjsPlugins } from '@infinitus/utils/dayjsConfig';

let didInit = false;

// dayjs has an internal guard against plugin re-init, but we might as well have one too
if (!didInit) {
  initDayjsPlugins(dayjs);
  didInit = true;
}

// Get the previous business day from a given dayjs object
export const getPreviousBusinessDay = (dayjsObj: Dayjs) => {
  let previousDay = dayjsObj.subtract(1, 'day');
  // If the previous day is a weekend, we keep going back until we find a business day
  // 0 is Sunday, 6 is Saturday. We want to skip these days
  while (previousDay.day() === 0 || previousDay.day() === 6) {
    previousDay = previousDay.subtract(1, 'day');
  }
  return previousDay;
};

// Get a previous business day from a given dayjs object
// and given number of days that we want to go back
export const subtractBusinessDays = (dayjsObj: Dayjs, numDays: number) => {
  let newDayjsObj = dayjsObj;
  for (let i = 0; i < numDays; i++) {
    newDayjsObj = getPreviousBusinessDay(newDayjsObj);
  }
  return newDayjsObj;
};

export default dayjs;
export type { Dayjs };
