import { graphql } from '@infinitus/generated/frontend-common';

// Only used in FastTrack
export const ALL_ACTIVE_CALLS = graphql(/* GraphQL */ `
  query AllActiveCalls($orgUuid: ID!) {
    allActiveCalls(orgUUID: $orgUuid) {
      orgUuid
      eligibleOperators
      call {
        id
        createTimeMillis
        phase
        audioStartMillis
        audioEndMillis
        operators {
          callRole
          operatorEmail
          isPresentOnCall
          isProficient
        }
        useRecordedGreeting
        callCreatorEmail
        outboundNumber
        phoneNumber
      }
      task {
        id
        customerAssignedId
        isInMultitaskCall
        state
        taskType
        operatorTrainerMode
        flexibleInputs {
          ...FlexibleInputFragment
        }
      }
    }
  }
`);
