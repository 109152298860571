import { useState, useCallback } from 'react';

export default function useMyRecordingBlobs() {
  const [greetingRecordings, setGreetingRecordings] = useState<Map<string, Blob>>(new Map());

  const addGreetingBlobs = useCallback(
    (
      greetingBlobs: { blob: Blob; name: string }[],
      options?: { clearExistingEntries: boolean }
    ) => {
      setGreetingRecordings((currentGreetingRecordings) => {
        const newGreetingRecordings = new Map(currentGreetingRecordings.entries());
        if (options?.clearExistingEntries) {
          newGreetingRecordings.clear();
        }
        for (const { name, blob } of greetingBlobs) {
          newGreetingRecordings.set(name, blob);
        }
        return newGreetingRecordings;
      });
    },
    []
  );

  const deleteGreetingBlob = useCallback(
    (name: string) =>
      setGreetingRecordings((currentGreetingRecordings) => {
        currentGreetingRecordings.delete(name);
        return new Map(currentGreetingRecordings.entries());
      }),
    []
  );

  return {
    greetingRecordings,
    addGreetingBlobs,
    deleteGreetingBlob,
  };
}
