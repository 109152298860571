// Use this UUID as a way to uniquely identify this browser tab session
export const browserSessionUuid = crypto.randomUUID();

// For type-safe lowercasing with string literal types
export function lowercase<T extends string = string>(input: T): Lowercase<T> {
  return input.toLowerCase() as Lowercase<T>;
}

// Utility to trick typescript into ensuring all cases are handled, before this
// function is called. particularly helpful for switch-cases
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
