import { InputLabelProps, InputProps, SxProps } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import {
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  ReactNode,
  useEffect,
  useState,
} from 'react';

interface TextFieldProps {
  autoFocus?: boolean;
  debounced?: boolean;
  debounceInterval?: number;
  disabled?: boolean;
  endAdornment?: ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | JSX.Element;
  hideLabel?: boolean;
  InputLabelProps?: InputLabelProps;
  InputProps?: InputProps;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  label: string;
  maxRows?: number;
  multiline?: boolean;
  onBlur?: FocusEventHandler;
  onChange: (val: string) => unknown;
  onFocus?: FocusEventHandler;
  onInput?: FormEventHandler;
  onKeyDown?: KeyboardEventHandler;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  spellCheck?: boolean;
  startAdornment?: ReactNode;
  sx?: SxProps;
  testId?: string;
  type?: string;
  value: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

export function TextField({
  autoFocus,
  debounced = false,
  debounceInterval = 200,
  disabled,
  endAdornment,
  error,
  fullWidth,
  helperText,
  hideLabel,
  InputLabelProps,
  InputProps,
  inputRef,
  label,
  maxRows = 3,
  multiline,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  pattern,
  placeholder,
  required,
  size = 'small',
  spellCheck = true,
  startAdornment,
  sx,
  testId,
  value,
  variant,
  type,
}: TextFieldProps) {
  const [innerValue, setInnerValue] = useState(value);
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);
  const [showPlaceholder, setShowPlaceholder] = useState(hideLabel === true);

  useEffect(() => {
    if (!debounced) return;
    setInnerValue((curInnerValue: string) => {
      // If concurrent updates to inner value, keep the most up to date
      if (curInnerValue !== innerValue) {
        return curInnerValue;
      } else {
        return value;
      }
    });
  }, [value]); // eslint-disable-line

  function handleBlur(evt: React.FocusEvent<HTMLInputElement>) {
    if (placeholder && !hideLabel) {
      setShowPlaceholder(false);
    }
    if (onBlur) {
      onBlur(evt);
    }
  }

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (!debounced) return onChange(evt?.target.value);

    setInnerValue(evt.target.value);
    if (timeoutRef) clearTimeout(timeoutRef);
    setTimeoutRef(
      setTimeout(() => {
        setTimeoutRef(null);
        return onChange(evt?.target.value);
      }, debounceInterval)
    );
  }

  function handleFocus(evt: React.FocusEvent<HTMLInputElement>) {
    if (placeholder) {
      setShowPlaceholder(true);
    }
    if (onFocus) {
      onFocus(evt);
    }
  }

  return (
    <MuiTextField
      aria-label={label}
      autoFocus={autoFocus}
      data-cy={testId}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      hiddenLabel={hideLabel}
      InputLabelProps={InputLabelProps}
      InputProps={{
        ...InputProps,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
      }}
      inputProps={{
        pattern: pattern,
      }}
      // Applies directly to the HTML <input> element
      inputRef={inputRef}
      type={type}
      {...(!hideLabel && { label })}
      maxRows={multiline ? maxRows : undefined}
      multiline={multiline}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onInput={onInput}
      onKeyDown={onKeyDown}
      placeholder={showPlaceholder ? placeholder : ''}
      required={required}
      size={size}
      spellCheck={spellCheck}
      sx={sx}
      value={debounced ? innerValue : value}
      variant={variant}
    />
  );
}
