import { useAuth, UserType } from '@infinitusai/auth';
import { useAppState, ErrorMessage, NotFoundPage, Heartbeat } from '@infinitusai/shared';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, Navigate, useParams, Outlet } from 'react-router-dom';

import { useNexmoClient } from '@infinitus/nexmo';
import { ErrorType } from 'utils/errors';

import AccountPage from './account/AccountPage';
import SpeedTestPage from './speedTest';

// Dynamically import Admin/Operator routes
const AdminPage = React.lazy(() => import('pages/admin'));
const OperatorPage = React.lazy(() => import('pages/operator'));
const UnifiedQueuePage = React.lazy(() => import('pages/queue')); // Lazy load to avoid affecting styling for another comments

function PortalPage() {
  const { orgName } = useParams();
  const inactivityTimeout = 1000 * 60 * 60 * 24 * 7; // 1 week inactivity
  const [error, setError] = React.useState<Error | null>(null);
  const { user, orgs, updatePermissions, hasUserType } = useAuth();
  const { nexmoClientIsReady } = useNexmoClient();
  const [orgLoading, setOrgLoading] = React.useState(true);
  const {
    org,
    orgName: orgNameAppState,
    updateOrg,
    setOrgUuid,
    setOrgName,
    setIsLoading,
  } = useAppState();
  const orgUuid = orgs?.find((org) => org.name === orgName)?.uuid;

  React.useEffect(() => {
    if (orgUuid && orgName) {
      setIsLoading(true);
      setOrgLoading(true);
      setOrgUuid(orgUuid);
      setOrgName(orgName);
      Promise.all([updateOrg(user, orgUuid), updatePermissions(user, orgUuid)])
        .then(() => {
          setError(null);
          setIsLoading(false);
          setOrgLoading(false);
        })
        .catch((error) => {
          setError(typeof error === 'string' ? new Error(error) : error);
          setIsLoading(false);
          setOrgLoading(false);
        });
    } else {
      setError(null);
      setIsLoading(false);
      setOrgLoading(false);
    }
  }, [user, orgUuid, orgName, setOrgUuid, setOrgName, setIsLoading, updateOrg, updatePermissions]);

  // If user routes to org they dont have access to in portal, we now show unauthorized page.
  // Since a user orgs loads before we render any pages, we can check if the orguuid exists by looking up orgs by orgName from url param.
  // OrgUuid should always exists or the user doesnt have access to the org.
  if (!orgUuid)
    return <ErrorMessage entity="page" errorType={ErrorType.UNAUTHORIZED} showUserInfo />;

  // Need to wait will the app state orgName updates so that components under this component tree do not get a stale orgName
  if (orgLoading || orgName !== orgNameAppState) return null;

  if (error) return <ErrorMessage entity="page" errorType={error.name} showUserInfo />;

  if (!org || !hasUserType([UserType.OPERATOR, UserType.ADMIN]))
    return <ErrorMessage entity="page" errorType={ErrorType.UNAUTHORIZED} showUserInfo />;

  // Need to wait for the nexmo client to be ready to use to avoid calling methods
  // before the client selection has stabilized
  if (!nexmoClientIsReady) return null;

  return (
    <React.Fragment>
      <Heartbeat inactivityTimeout={inactivityTimeout} />
      <Helmet
        defaultTitle={`${org?.displayName || 'Organization'} | Operator | Infinitus`}
        titleTemplate={`%s | ${org?.displayName || 'Organization'} | Operator | Infinitus`}
      />
      <Routes>
        <Route element={<Navigate replace to="queue" />} index />
        <Route element={<AdminPage />} path="admin/*" />
        <Route element={<OperatorPage />} index path="operator/*" />
        <Route element={<AccountPage />} path="account" />
        <Route element={<SpeedTestPage />} path="speedTest" />
        <Route element={<UnifiedQueuePage />} path="queue" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
      <Outlet />
    </React.Fragment>
  );
}
export default PortalPage;
