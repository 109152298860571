/**
 * The confirm options used for the confirm function
 */
export interface ConfirmOptions {
  cancelText?: string;
  confirmText?: string;
  description?: string;
  footnote?: string;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
}

/**
 * The confirm state to maintain state of the confirm dialog
 */
export interface ConfirmState extends ConfirmOptions {
  open: boolean;
}

/**
 * The initial confirm state.
 */
export const initialConfirmState: ConfirmState = {
  open: false,
  title: '',
  description: '',
  footnote: '',
  confirmText: '',
  cancelText: '',
  onConfirm: () => {},
  onCancel: () => {},
  isLoading: false,
};
