import { AppName } from '@infinitusai/api';
import { PropsWithChildren, useMemo } from 'react';

import useAppConfig from '@infinitus/hooks/useAppConfig';
import { FastTrackApi, OperatorPortalApi } from '@infinitus/utils/api';

import ApiContext from './ApiContext';

const ApiContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { appName } = useAppConfig();
  const api = useMemo(() => {
    if (appName === AppName.OPERATOR) return new OperatorPortalApi();
    if (appName === AppName.FASTTRACK) return new FastTrackApi();

    throw new Error(`Unsupported application ${appName}`);
  }, [appName]);

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
