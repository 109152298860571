import { AppName } from '@infinitusai/api';
import { Context, useContext } from 'react';

import ApiContext from './ApiContext';
import { ApiContextData } from './types';
export { default as ApiContextProvider } from './ApiContextProvider';

const useApi = <T extends AppName>() => {
  const context = useContext<ApiContextData<T>>(ApiContext as Context<ApiContextData<T>>);
  if (!context) {
    throw new Error('useApi must be used within ApiProvider');
  }
  return context;
};

export default useApi;
