import { FileRejection } from 'react-dropzone';

import { logUiEvent } from '@infinitus/hooks/useLogBuffer';

export interface FileEvent {
  eventName: string;
  files?: File[];
  label: string;
  rejectedFiles?: FileRejection[];
}

export default function logFileEvent({ eventName, files, label, rejectedFiles }: FileEvent) {
  logUiEvent({
    componentName: 'FileInput',
    componentLabel: String(label),
    eventName: eventName,
    eventMeta: {
      files: files && [...files],
      rejectedFiles: rejectedFiles,
    },
  });
}
