import { Theme } from '@mui/material';

import { IconColors } from '@infinitus/components/Icon/IconTypes';
import ShirtSizes from '@infinitus/types/shirt-sizes';

const getColorStyles = (theme: Theme): Record<IconColors, { color: string }> => ({
  action: {
    color: theme.palette.action.active,
  },
  white: {
    color: theme.palette.common.white,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  info: {
    color: theme.palette.info.light,
  },
  error: {
    color: theme.palette.error.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
});

const FONT_SIZE = {
  [ShirtSizes.XS]: 16,
  [ShirtSizes.SM]: 20,
  [ShirtSizes.MD]: 24,
  [ShirtSizes.LG]: 32,
  [ShirtSizes.XL]: 40,
};

// TODO: Convert to using this
export const getStyles = (color: IconColors, size: ShirtSizes, theme: Theme) => {
  return {
    ...getColorStyles(theme)[color],
    cursor: 'inherit',
    fontSize: FONT_SIZE[size],
    userSelect: 'none',
  };
};
