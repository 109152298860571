import { gql } from '@apollo/client';

export const INCIDENT_FIELDS = gql`
  fragment IncidentFields on Incident {
    id
    description
    createTimeMillis
    onCall
    reportedByUID
    taskUUID
    callUUID
    environment
    logs
    imageURLs
    pageURL

    incidentType {
      id
      summary
      subcategory
      category
      priority
    }

    org {
      id
      displayName
      name
    }

    payer {
      id
      name
    }

    issues {
      id
      summary
    }
  }
`;

export const INCIDENTS = gql`
  query GetIncidents($filter: IncidentFilterInput = {}) {
    incidents(filter: $filter) {
      ...IncidentFields
    }
  }
  ${INCIDENT_FIELDS}
`;
