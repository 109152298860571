import useLocalStorage from '@rehooks/local-storage';
import { useCallback, useMemo } from 'react';

import { LOCAL_STORAGE_NAV_STATE_KEY } from 'utils/localStorage';

export function useNavState() {
  const [navStateArray, setNavState] = useLocalStorage<string[]>(LOCAL_STORAGE_NAV_STATE_KEY, []);

  const setNavItemState = useCallback(
    (id: string, isOpen: boolean) => {
      let newState = new Set(navStateArray);
      if (isOpen) {
        newState.add(id);
      } else {
        newState.delete(id);
      }

      setNavState(Array.from(newState));
    },
    [setNavState, navStateArray]
  );

  const navState = useMemo(() => new Set(navStateArray), [navStateArray]);

  return useMemo(
    () => ({
      navState,
      setNavItemState,
    }),
    [navState, setNavItemState]
  );
}
