import { Chip, styled } from '@mui/material';
import { amber, green, red } from '@mui/material/colors';
import classNames from 'classnames';

import { getChipLabel, Status } from './helpers';

const PREFIX = 'GreetingsChip';
const classes = {
  greetingsChip: `${PREFIX}-greetingsChip`,
  greetingsApproved: `${PREFIX}-greetingsApproved`,
  greetingsPending: `${PREFIX}-greetingsPending`,
  greetingsMissing: `${PREFIX}-greetingsMissing`,
};
const GreetingsChipStyled = styled(Chip)(({ theme }) => ({
  [`&.${classes.greetingsChip}`]: {
    color: theme.palette.common.white,
    borderRadius: 8,
    fontSize: '0.54rem',
    fontWeight: 'bold',
    lineHeight: '1.2rem',
    height: '1.6rem',
    padding: '0.96px 9.6px',
    borderWidth: '1.2px',
    borderStyle: 'solid',
    textTransform: 'uppercase',
  },
  [`&.${classes.greetingsApproved}`]: {
    borderColor: green[600],
    background: green[600],
  },
  [`&.${classes.greetingsPending}`]: {
    borderColor: amber[600],
    background: amber[600],
  },
  [`&.${classes.greetingsMissing}`]: {
    borderColor: red[600],
    background: red[600],
  },
}));

interface GreetingsChipProps {
  // When no label is provided, a default chip label will be set based on the given status
  label?: string;
  status: Status;
}

export function GreetingsChip({ label, status }: GreetingsChipProps) {
  const statusLabel = label ?? getChipLabel(status);

  return (
    <GreetingsChipStyled
      className={classNames({
        [classes.greetingsChip]: true,
        [classes.greetingsApproved]: status === 'APPROVED',
        [classes.greetingsPending]: status === 'PENDING',
        [classes.greetingsMissing]: status === 'MISSING',
      })}
      label={statusLabel}
    />
  );
}
