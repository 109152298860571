import { FocusEvent, MouseEvent, ReactNode } from 'react';
import innerText from 'react-innertext';

import { logUiEvent } from '@infinitus/hooks/useLogBuffer';

export interface ButtonEvent {
  buttonName?: string;
  componentName?: string;
  event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>;
  formId?: string;
  meta?: object;
  text: ReactNode;
}

interface ButtonEventLog extends ButtonEvent {
  sharedProps: {
    componentLabel: string;
    componentName: string;
    eventMeta: any;
    eventName: string;
  };
}
interface ButtonFocusEvent extends ButtonEventLog {
  event: FocusEvent<HTMLButtonElement>;
}
interface ButtonMouseEvent extends ButtonEventLog {
  event: MouseEvent<HTMLButtonElement>;
}

const focusEventNames = ['blur', 'focus'];
const mouseEventNames = ['click', 'mouseenter', 'mouseleave'];

export function logButtonEvent({
  componentName = 'Button',
  event,
  formId,
  meta,
  text,
}: ButtonEvent) {
  const target = event.target as Element;
  const parent = target.parentElement;
  const sharedProps = {
    componentName: componentName,
    componentLabel: innerText(text),
    eventMeta: {
      ...meta,
      className: target.className,
      formId: formId,
      nodeName: target.nodeName,
      parentNodeName: parent && parent.nodeName,
      parentClassName: parent && parent.className,
    },
    eventName: event.type,
  };
  if (mouseEventNames.includes(event.type)) {
    logMouseEvent({ event, sharedProps } as ButtonMouseEvent);
  } else if (focusEventNames.includes(event.type)) {
    logFocusEvent({ event, sharedProps } as ButtonFocusEvent);
  } else {
    logUiEvent(sharedProps);
  }
}

function logFocusEvent({ sharedProps }: ButtonFocusEvent) {
  logUiEvent(sharedProps);
}

export function logMouseEvent({ event, sharedProps }: ButtonMouseEvent) {
  const logProps = { ...sharedProps };
  logProps.eventMeta = {
    ...sharedProps.eventMeta,
    clientX: event.clientX,
    clientY: event.clientY,
  };
  logUiEvent(logProps);
}
