import { useEffect } from 'react';

import { ClientEventType } from '@infinitus/generated/frontend-common';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';

interface EventLoopChunkTiming {
  betweenTimestamp: [number, number];
  taskRunDelay: number;
}

interface EventLoopDelayMonitorOptions {
  onDelayDetected: (chunkTimings: EventLoopChunkTiming[]) => void;
}

class EventLoopDelayMonitor {
  static _CHUNK_INTERVAL_MS = 1_000;
  static _CHUNKS_IN_WINDOW = 10;
  static _DELAY_THRESHOLD_MS = 100;

  private _running = false;
  private _intervalId: NodeJS.Timeout | null = null;
  private _onDelayDetected: EventLoopDelayMonitorOptions['onDelayDetected'];

  constructor({ onDelayDetected }: EventLoopDelayMonitorOptions) {
    this._onDelayDetected = onDelayDetected;
  }

  start() {
    if (this._running) {
      return;
    }
    this._running = true;

    let remainingMeasures = EventLoopDelayMonitor._CHUNKS_IN_WINDOW;
    let _lastTaskRunTimestamp = Date.now();
    let chunkMeasurements: EventLoopChunkTiming[] = [];
    const measure = () => {
      const intervalStart = _lastTaskRunTimestamp;
      const intervalEnd = Date.now();
      const taskRunDelay =
        intervalEnd - _lastTaskRunTimestamp - EventLoopDelayMonitor._CHUNK_INTERVAL_MS;
      _lastTaskRunTimestamp = intervalEnd;

      chunkMeasurements.push({
        betweenTimestamp: [intervalStart, intervalEnd],
        taskRunDelay,
      });
      --remainingMeasures;

      if (remainingMeasures <= 0) {
        const cumulativeDelayMs = chunkMeasurements.reduce(
          (acc, cur) => acc + Math.max(cur.taskRunDelay, 0),
          0
        );
        // The following link describes tolerability of delay for a user: https://ux.stackexchange.com/a/42688
        // We cant precisely measure delay for every interaction, however, a proxy to
        // detect thread blocking is to notify when there is significant delay with running
        // a scheduled task.
        if (cumulativeDelayMs > EventLoopDelayMonitor._DELAY_THRESHOLD_MS) {
          this._onDelayDetected(chunkMeasurements);
        }
        // Reset the state for the next window
        chunkMeasurements = [];
        remainingMeasures = EventLoopDelayMonitor._CHUNKS_IN_WINDOW;
      }

      this._intervalId = setTimeout(measure, EventLoopDelayMonitor._CHUNK_INTERVAL_MS);
    };
    this._intervalId = setTimeout(measure, EventLoopDelayMonitor._CHUNK_INTERVAL_MS);
  }

  stop() {
    this._running = false;
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
  }
}

export function EventLoopDelayLogger() {
  useEffect(() => {
    const monitor = new EventLoopDelayMonitor({
      onDelayDetected: (chunks) => {
        void logEventToBigQuery({
          clientEventType: ClientEventType.PERFORMANCE_MEASUREMENT,
          message: 'Detected Event Loop Scheduling Delay',
          meta: {
            chunks,
            start: chunks[0].betweenTimestamp[0],
            end: chunks[chunks.length - 1].betweenTimestamp[1],
            totalChunkDelay: chunks.reduce((acc, cur) => acc + cur.taskRunDelay, 0),
          },
        });
      },
    });
    monitor.start();
    return () => {
      monitor.stop();
    };
  }, []);

  return null;
}
