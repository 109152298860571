import { useCallback, useEffect, useState } from 'react';

import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import useInterval from '@infinitus/hooks/useInterval';
import { logConnection } from '@infinitus/hooks/useLogBuffer';

const LOG_INTERVAL_MIN = 5;
const SNACKBAR_KEY = 'slow-network-snackbar';
const SNACKBAR_DURATION_MS = 10_000;

// return true if the effective network connection is slow.
// the effective connection type is computed using other measurable connection stats
// on the NetworkInformation interface (https://wicg.github.io/netinfo/#dom-networkinformation-effectivetype).
function isNetworkConnectionSlow(network?: NetworkInformation) {
  if (!network?.effectiveType) return false;
  if (['3g', '4g'].includes(network.effectiveType)) {
    return false;
  }
  if (['slow-2g', '2g'].includes(network.effectiveType)) {
    return true;
  }

  console.error(`hit unexpected code path in isNetworkConnectionSlow`);

  return false;
}

export function NetworkLogger() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [hasSlowConnection, setHasSlowConnection] = useState<boolean>();

  const trackNetworkInformation = useCallback<typeof logConnection>((...args) => {
    setHasSlowConnection(isNetworkConnectionSlow(args[0]));
    logConnection(...args);
  }, []);

  // Show a snackbar if the connection starts/becomes slow
  useEffect(() => {
    if (hasSlowConnection) {
      enqueueSnackbar(
        'Your internet connection is slow or unreliable. Please check your connection.',
        {
          key: SNACKBAR_KEY,
          variant: 'warning',
          autoHideDuration: SNACKBAR_DURATION_MS,
        }
      );
      logConnection(window.navigator.connection, 'slowConnection');
    } else {
      closeSnackbar(SNACKBAR_KEY);
      logConnection(window.navigator.connection, 'goodConnection');
    }
  }, [closeSnackbar, enqueueSnackbar, hasSlowConnection]);

  // Log connection change,
  useEffect(() => {
    if (window.navigator?.connection?.onchange === null) {
      window.navigator.connection.addEventListener('change', (evt) => {
        if (evt?.currentTarget === null) return;
        if (!window.navigator.connection) return;
        trackNetworkInformation(window.navigator.connection, 'change');
      });
    }
  }, [trackNetworkInformation]);

  // Log connection every 5 minutes
  useInterval(() => {
    trackNetworkInformation(window.navigator.connection, 'timed');
  }, LOG_INTERVAL_MIN * 60 * 1000);

  return null;
}
