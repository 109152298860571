import * as React from 'react';

import { ConfirmOptions } from './ConfirmTypes';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ConfirmProvider>.');
};

/**
 * The Confirm Context
 */
const ConfirmContext = React.createContext<(options: ConfirmOptions) => void>(stub);

export default ConfirmContext;
