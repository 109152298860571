import { Tooltip as MuiTooltip, SxProps } from '@mui/material';
import { CSSProperties } from 'react';

interface Props {
  arrow?: boolean;
  children: React.ReactNode;
  enterDelay?: number;
  interactive?: boolean;
  leaveDelay?: number;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  popperSx?: SxProps;
  style?: CSSProperties;
  sx?: SxProps;
  title: React.ReactNode | string;
}

export function Tooltip({
  arrow = false,
  children,
  enterDelay = 200,
  interactive = false,
  leaveDelay = 0,
  placement = 'top',
  popperSx,
  sx,
  title,
}: Props) {
  return (
    <MuiTooltip
      arrow={arrow}
      componentsProps={{
        popper: {
          sx: {
            cursor: 'default',
            // Ensures hovering over the Popper (which contains `title`)
            // doesn't keep it open without disabling interaction
            // via `disableInteractive`
            pointerEvents: interactive ? 'none' : undefined,
            ...popperSx,
          },
        },
        tooltip: {
          sx: sx,
        },
      }}
      enterDelay={enterDelay}
      enterNextDelay={enterDelay * 0.6}
      leaveDelay={leaveDelay}
      placement={placement}
      sx={sx}
      title={title}
    >
      {/* Use div to eliminate the need for forwarding refs */}
      <div>{children}</div>
    </MuiTooltip>
  );
}
