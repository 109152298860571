import { InMemoryCache, makeVar, Reference, useReactiveVar } from '@apollo/client';

import generatedIntrospection from '@infinitus/generated/fragment-matcher';
import {
  CallRole,
  ConversationVersionEnum,
  OutputValidation,
  SubscribeToCallSubscription,
  SubscribeToTaskSubscription,
  SubscribeToTopSuggestionSubscription,
} from 'generated/gql/graphql';
import { ChatLog, ChatLogs, Output } from 'types/graphql-simple';
import { JSONStringifyOrder } from 'utils';

import { rulesQueryTypePolicies } from './rulesCache';

// App loading state var
export const loadingVar = makeVar<boolean>(false);

// these are the output field values that haven't been synced to the server yet.
export const unsyncedOutputValuesVar = makeVar<Output[]>([]);

// used to store current task doc, available via readTaskState client-side query.
export const taskStateVar = makeVar<SubscribeToTaskSubscription['subscribeToTask']['task'] | null>(
  null
);

export const taskNotesInternalVar = makeVar<string | null>(null);

// used to store current call state, available via readCallState client-side query.
export const callStateVar = makeVar<SubscribeToCallSubscription['subscribeToCall'] | null>(null);

export const targetCallRoleVar = makeVar(CallRole.CALL_ROLE_CONTROLLER);

export type ChatLogByIntent = { [name: string]: ChatLog };
export const digitalAssistantChatLogs = makeVar<ChatLogByIntent | null>(null);

export const chatLogsVar = makeVar<ChatLogs | null>(null);

// used to store research link state on Call Page, available via readResearchLink client-side query.
type ResearchLink = { openInNewTab: boolean; url: string };
export const researchLinkVar = makeVar<ResearchLink | null>(null);

// TODO: add client-side convenience query
// used to determine whether call output validation are being fetched
export const validatingCallOutputsVar = makeVar<boolean>(false);
// used to store current call output validation errors, warnings, and suggestions
export const callOutputsDataValidationErrorsVar = makeVar<OutputValidation[]>([]);
// used to store multiTask call outputs validation errors, warnings, and suggestions
export const callOutputsDataValidationErrorsMultiTaskVar = makeVar<OutputValidation[]>([]);
// used to determine whether call output validation should happen automatically when call outputs change
export const callOutputsDataValidationAutoRefreshVar = makeVar<boolean>(false);
export type AcknowledgedValidation = {
  [outputName: string]: {
    accepted: Set<OutputValidation>;
    rejected: Set<OutputValidation>;
  };
};
// used to store call output validations the user acknowledged.
export const acknowledgedValidationsVar = makeVar<AcknowledgedValidation>({});

export const convoConfigVersionVar = makeVar<ConversationVersionEnum | null>(null);
export const useConvoConfigVersionVar = () => useReactiveVar(convoConfigVersionVar);
export const disableHighlightVar = makeVar<boolean>(false);
export const useDisableHighlightVar = () => useReactiveVar(disableHighlightVar);
export const conversationCompleteVar = makeVar<boolean>(false);
export const useConversationCompleteVar = () => useReactiveVar(conversationCompleteVar);
export const topSuggestionRelatedOutputsVar = makeVar<string[]>([]);

export const topSuggestionVar = makeVar<
  SubscribeToTopSuggestionSubscription['subscribeToTopSuggestion'] | null
>(null);
export const topSuggestionNameVar = makeVar<string | null>(null);

export const useTopSuggestionVar = () => useReactiveVar(topSuggestionVar);

export function getDigitalAssistantChatLogKey(sourceTaskUuid: string, intent: string) {
  return `${sourceTaskUuid}-${intent}`;
}

export function getValidationOutputsNotAcknowledged(
  fieldName: string,
  outputValidationsForField: OutputValidation[],
  acknowledgedValidations: AcknowledgedValidation
) {
  const acked = acknowledgedValidations[fieldName];

  if (acked) {
    // When user "accepts" or "rejects" a suggestion, hide that specific suggestion for that fieldName.
    const ackedValidateOutputs = [...acked.accepted.values(), ...acked.rejected.values()];
    if (ackedValidateOutputs.length) {
      const validateOutputsNotAcked = outputValidationsForField.filter((v) => {
        let alreadyAcked = false;
        for (const ackV of ackedValidateOutputs) {
          if (JSONStringifyOrder(ackV) === JSONStringifyOrder(v)) {
            alreadyAcked = true;
            break;
          }
        }
        return !alreadyAcked;
      });
      return validateOutputsNotAcked;
    }
  }

  return outputValidationsForField;
}

export function getAllValidationOutputsNotAcknowledged(
  outputValidations: OutputValidation[],
  acknowledgedValidations: AcknowledgedValidation
) {
  const validationOutputsNotAcknowledged: OutputValidation[] = [];

  // Collect all field names from outputValidations
  const fieldNamesSet = new Set<string>();
  outputValidations.forEach((v) => {
    const fieldName = v.output?.name;
    if (fieldName) {
      fieldNamesSet.add(fieldName);
    }
  });

  // Get outstanding validation outputs for each field name
  const fieldNames = [...fieldNamesSet];
  fieldNames.forEach((fieldName) => {
    if (fieldName) {
      const outputValidationsForField = outputValidations.filter(
        (v) => v.output?.name === fieldName
      );
      const validationsNotAcknowledgedForField = getValidationOutputsNotAcknowledged(
        fieldName,
        outputValidationsForField,
        acknowledgedValidations
      );
      validationOutputsNotAcknowledged.push(...validationsNotAcknowledgedForField);
    }
  });

  return validationOutputsNotAcknowledged;
}

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        loading() {
          return loadingVar();
        },
        // readOutputValue query returns the most current value of the output field
        // with field name == variables.fieldName
        readOutputValue: {
          read(_, { readField, variables }) {
            // first check if we have unsynced value
            const found = unsyncedOutputValuesVar().find((v) => v.name === variables?.fieldName);
            if (found) {
              return found;
            }

            // else, check cached value from subscription
            const callOutputs = readField<Reference>('callOutputs');
            const outputValuesFromSubscription = readField<Reference[]>('outputs', callOutputs);
            return outputValuesFromSubscription?.find((ref) => {
              const fieldName = readField<String>('name', ref);
              return fieldName === variables?.fieldName;
            });
          },
        },
        // readOutputValidations query returns the most current outstanding validateOutputs results for specific fieldName
        readOutputValidations: {
          read(_, { readField, variables }) {
            if (!variables || !variables.fieldName) {
              return undefined;
            }
            const outputValidations = callOutputsDataValidationErrorsVar();
            const outputValidationsForField = outputValidations.filter(
              (v) => v.output?.name === variables.fieldName
            );

            return getValidationOutputsNotAcknowledged(
              variables.fieldName,
              outputValidationsForField,
              acknowledgedValidationsVar()
            );
          },
        },
        // readOutputValidations query returns the most current outstanding validateOutputs results for all fieldNames
        readAllOutputValidations: {
          read() {
            return getAllValidationOutputsNotAcknowledged(
              callOutputsDataValidationErrorsVar(),
              acknowledgedValidationsVar()
            );
          },
        },
        readCallState: {
          read() {
            return callStateVar();
          },
        },
        readTaskState: {
          read() {
            return taskStateVar();
          },
        },
        readResearchLink: {
          read() {
            return researchLinkVar();
          },
        },
        queryDigitalAssistantChatLogByIntent: {
          read(_, { variables }) {
            const intent = variables?.intent;
            const sourceTaskUuid = variables?.sourceTaskUuid;
            if (!variables || !intent || !sourceTaskUuid) {
              return null;
            }
            return (
              digitalAssistantChatLogs()?.[getDigitalAssistantChatLogKey(sourceTaskUuid, intent)] ||
              null
            );
          },
        },
        queryDigitalAssistantChatLogsByIntents: {
          read(_, { variables }) {
            const intents = variables?.intents;
            const sourceTaskUuid = variables?.sourceTaskUuid;
            if (!variables || !intents || intents.length === 0 || !sourceTaskUuid) {
              return [];
            }

            const correspondingChatLogs = (intents as string[]).map((intent) => {
              return (
                digitalAssistantChatLogs()?.[
                  getDigitalAssistantChatLogKey(sourceTaskUuid, intent)
                ] || null
              );
            });
            return correspondingChatLogs;
          },
        },
        user: {
          read(_, { variables, toReference }) {
            if (!variables) {
              return null;
            }

            const id = variables.userId || variables.id;

            if (!id) {
              return null;
            }

            return toReference({
              __typename: 'User',
              id,
            });
          },
        },
        ...rulesQueryTypePolicies,
      },
    },
    PrimarySuggestion: {
      // Make primary suggestion fragments query-able.
      keyFields: ['name'],
    },
  },
});

export default cache;
