import { graphql } from '@infinitus/generated/frontend-common';

export const EbvOutputsResponse = graphql(/* GraphQL */ `
  fragment EbvOutputsResponse on EbvResponse {
    benefitsBag {
      annualBenefitCapExists {
        bool
      }
      copayWaivedAfterOop {
        bool
      }
      doesCopayApplyToOop {
        bool
      }
      familyDeductibleExists {
        bool
      }
      familyDeductibleExistsOON {
        bool
      }
      familyDeductibleMetAmount {
        amountInCents
      }
      familyDeductibleMetAmountOON {
        amountInCents
      }
      familyDeductibleRemaining {
        amountInCents
      }
      familyDeductibleRemainingOON {
        amountInCents
      }
      familyOopMaximum {
        amountInCents
      }
      familyOopMaximumExists {
        bool
      }
      familyOopMaximumExistsOON {
        bool
      }
      familyOopMaximumMetAmount {
        amountInCents
      }
      familyOopMaximumMetAmountOON {
        amountInCents
      }
      familyOopMaximumOON {
        amountInCents
      }
      familyOopRemaining {
        amountInCents
      }
      familyOopRemainingOON {
        amountInCents
      }
      familyTotalDeductible {
        amountInCents
      }
      familyTotalDeductibleOON {
        amountInCents
      }
      groupNumber {
        string
      }
      individualDeductibleExists {
        bool
      }
      individualDeductibleExistsOON {
        bool
      }
      individualDeductibleMetAmount {
        amountInCents
      }
      individualDeductibleMetAmountOON {
        amountInCents
      }
      individualDeductibleRemaining {
        amountInCents
      }
      individualDeductibleRemainingOON {
        amountInCents
      }
      individualOopMaximum {
        amountInCents
      }
      individualOopMaximumExists {
        bool
      }
      individualOopMaximumExistsOON {
        bool
      }
      individualOopMaximumMetAmount {
        amountInCents
      }
      individualOopMaximumMetAmountOON {
        amountInCents
      }
      individualOopMaximumOON {
        amountInCents
      }
      individualOopRemaining {
        amountInCents
      }
      individualOopRemainingOON {
        amountInCents
      }
      individualTotalDeductible {
        amountInCents
      }
      individualTotalDeductibleOON {
        amountInCents
      }
      isPlanTerminated {
        bool
      }
      lifetimeMaximumExists {
        bool
      }
      memberId {
        string
      }
      officeCoinsurance {
        int
      }
      officeCoinsuranceOON {
        int
      }
      officeCopay {
        amountInCents
      }
      officeCopayOON {
        amountInCents
      }
      officeHasCoinsurance {
        bool
      }
      officeHasCoinsuranceOON {
        bool
      }
      officeHasCopay {
        bool
      }
      officeHasCopayOON {
        bool
      }
      planEffectiveDate {
        date {
          day
          month
          year
        }
      }
      planName {
        string
      }
      planType {
        enum
      }
      policyType {
        enum
      }
      referralRequirements {
        enum
      }
      terminationDate {
        date {
          day
          month
          year
        }
      }
    }
    success
  }
`);

// Only used in OperatorPortal
export const FetchEbvOutputsDocument = graphql(/* GraphQL */ `
  query FetchEbvOutputs($orgUuid: ID!, $taskId: ID!) {
    availity: ebvOutputs(orgUuid: $orgUuid, taskId: $taskId, ebvType: AVAILITY_270_271) {
      ...EbvOutputsResponse
    }
    chc: ebvOutputs(orgUuid: $orgUuid, taskId: $taskId, ebvType: FROM_270_271) {
      ...EbvOutputsResponse
    }
    payer: ebvOutputs(orgUuid: $orgUuid, taskId: $taskId, ebvType: PAYER_API) {
      ...EbvOutputsResponse
    }
    statsPie: ebvOutputs(orgUuid: $orgUuid, taskId: $taskId, ebvType: STAT_PIE) {
      ...EbvOutputsResponse
    }
  }
`);
