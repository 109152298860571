import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { Component, PropsWithChildren } from 'react';

import { logError, logBufferVar, logErrorBoundarySeenEvent } from '@infinitus/hooks/useLogBuffer';
import dayjs from '@infinitus/utils/dayjs';
import Logo from 'components/Logo';

interface Props {
  onError?: (error: Error | null | undefined, info: React.ErrorInfo | null | undefined) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null | undefined, info: React.ErrorInfo | null | undefined) {
    logError(JSON.stringify(error), info);
    logErrorBoundarySeenEvent();
    if (this.props.onError) this.props.onError(error, info);
  }

  render() {
    const logBuffer = logBufferVar();
    if (this.state.hasError) {
      return (
        <div style={{ display: 'flex' }}>
          <CssBaseline />
          <main
            style={{
              flexGrow: 1,
              minWidth: 960,
              padding: '16px',
            }}
          >
            <Container maxWidth="lg">
              <Box display="flex" justifyContent="center" mt={12}>
                <Link href="/">
                  <Logo
                    style={{
                      width: '8rem',
                      height: 'auto',
                    }}
                    variant="lockup"
                  />
                </Link>
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="h3">Internal Error</Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="body1">
                  Sorry. Something went wrong on our end. Please try again later.
                </Typography>
              </Box>
              {logBuffer.length > 0 && (
                <Box mt={4}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Meta</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {logBuffer.map((item) => (
                          <TableRow key={item.meta?.timestampMillis}>
                            <TableCell>
                              {
                                // eslint-disable-next-line no-restricted-syntax
                                dayjs(item.meta?.timestampMillis).format('YYYY/MM/DD HH:mm:ss z')
                              }
                            </TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.message}</TableCell>
                            <TableCell>{JSON.stringify(item.meta)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Container>
          </main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
