// eslint-disable-next-line no-restricted-imports
import dayjs, { DayjsTimezoneOptionalDate, PluginFunc } from 'dayjs';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const ISO_UTC_REGEX = /^(\d{4}-\d\d-\d\d[Tt]\d\d:\d\d:\d\d(\.\d+)?([Zz]))$/;

/*  
For some reason the `dayjs.tz()` types require a date parameter, despite actually being optional.
The only way to reclare the parameter type definition is with a new plugin, so all this does is update
the function signature, and put the existing timezone plugin, untouched, at `dayjz.tzo`.

Single character PR was approved and merged here: https://github.com/iamkun/dayjs/pull/2222

Can remove this type extension when a new version is released.
*/
declare module 'dayjs' {
  interface DayjsTimezoneOptionalDate extends DayjsTimezone {
    (date?: ConfigType, timezone?: string): dayjs.Dayjs;
  }

  let tzo: DayjsTimezoneOptionalDate;
}

export type InputType = Parameters<DayjsTimezoneOptionalDate>[0];
type FormatOrTimezoneType = Parameters<DayjsTimezoneOptionalDate>[1];
type TimezoneType = Parameters<DayjsTimezoneOptionalDate>[2];

const timezoneOptionalDate: PluginFunc = (_option, _dayjsClass, dayjsFactory) => {
  /*
  The `dayjs.tz` plugin ignores any offset present in a given input string and parses string dates
  as if they were in the default timezone set for the plugin, regardless of any given offset or UTC indicator.

  This override preserves the UTC parsing of a given input string when appropriate
  */
  const tzo = (
    input: InputType,
    formatOrTimezone?: FormatOrTimezoneType,
    timezone?: TimezoneType
  ) => {
    if (!(typeof input === 'string' && ISO_UTC_REGEX.test(input))) {
      return dayjsFactory.tz(input, formatOrTimezone || '', timezone);
    }

    return dayjsFactory.utc(input).tz(DEFAULT_TIMEZONE);
  };

  tzo.guess = dayjsFactory.tz.guess;
  tzo.setDefault = dayjsFactory.tz.setDefault;

  dayjsFactory.tzo = tzo;
};

export default timezoneOptionalDate;
