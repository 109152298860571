/**
 * Wraps a promise in a timeout, allowing the promise to reject if not resolved with a specific period of time.
 *
 * @example
 * wrapPromiseWithTimeout(1000, fetch('https://courseof.life/johndoherty.json'))
 *   .then(function(cvData){
 *     alert(cvData);
 *   })
 *   .catch(function(){
 *     alert('request either failed or timedout');
 *   });
 */
export function wrapPromiseWithTimeout<T>(
  promise: Promise<T>,
  time: number,
  {
    error = () => new Error('promise timeout'),
    onTimeout,
  }: {
    error?(): Error;
    onTimeout?(): void;
  } = {}
) {
  return new Promise<T>((resolve, reject) => {
    const timer = setTimeout(() => {
      if (onTimeout) {
        onTimeout();
      }

      reject(error());
    }, time);

    promise
      .then((res) => {
        clearTimeout(timer);
        resolve(res);
      })
      .catch((err) => {
        clearTimeout(timer);
        reject(err);
      });
  });
}

/**
 * Retries a callback until the number of attempts are exhausted or the callback
 * returns a valid result. The promise will resolve with the result of the
 * callback or undefined if the attempts are exhausted.
 *
 * @param attempts - The number of attempts to make before giving up.
 * @param intervalMs - The interval in milliseconds between each attempt.
 * @param callback - The callback to call on each attempt.
 *
 * @example
 * createRetryPromise(2, 500, () => {
 *  const foundItem = items.find(item => item.id === '123');
 *  return [foundItem, !!foundItem];
 * })
 * .then(function(cvData){
 *   alert(cvData);
 * })
 * .catch(function(){
 *   // never reached
 * });
 */
export function createRetryPromise<T>(
  attempts: number,
  intervalMs: number,
  callback: () => { isValid: true; result: T } | { isValid: false }
): Promise<T | undefined> {
  return new Promise((resolve) => {
    let attempt = 0;
    const retry = () => {
      attempt++;
      const callbackRes = callback();
      if (callbackRes.isValid) {
        resolve(callbackRes.result);
      } else if (attempt < attempts) {
        setTimeout(retry, intervalMs);
      } else {
        resolve(undefined);
      }
    };
    retry();
  });
}
