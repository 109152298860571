import List from '@mui/material/List';
import { forwardRef, useCallback } from 'react';

import { Button } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';
import VisualVariant from '@infinitus/types/visual-variant-types';
import dayjs from '@infinitus/utils/dayjs';
import { castPossibleLongToNumber } from 'utils/protoHelpers';

import BaseNotification, { NotificationProps } from './BaseNotification';
import { NotificationRow } from './NotificationRow';
import { useNotification } from './helpers';

const BegForOperatorNotification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ notification, compact }, ref) => {
    const payload = notification.payload?.begForOperatorNotification ?? {};
    const notificationUuid = notification.uuid;
    const { closeSnackbar } = useSnackbar();
    const { reportClickToBackend } = useNotification(
      infinitusai.be.NotificationType.NOTIFICATION_TYPE_BEG_FOR_OPERATOR,
      notificationUuid,
      notification.orgUuid,
      notification.userEmail
    );

    const handleJoinButtonClicked = useCallback(() => {
      window.open(
        `/${payload.orgName}/operator/tasks/${payload.taskUuid}/calls/${payload.callUuid}`
      );
      reportClickToBackend(infinitusai.be.NotificationAction.NOTIFICATION_ACTION_CLICKED);
      closeSnackbar(notificationUuid);
    }, [
      payload.orgName,
      payload.taskUuid,
      payload.callUuid,
      reportClickToBackend,
      closeSnackbar,
      notificationUuid,
    ]);

    return (
      <BaseNotification
        actions={
          <Button
            onClick={handleJoinButtonClicked}
            text="Join call"
            variant={VisualVariant.FILLED}
          />
        }
        compact={compact}
        notification={notification}
        onBodyClick={handleJoinButtonClicked}
        ref={ref}
        title="Beg request"
        type={infinitusai.be.NotificationType.NOTIFICATION_TYPE_BEG_FOR_OPERATOR}
      >
        <List>
          <NotificationRow
            iconName={IconNames.QUERY_BUILDER}
            title="Time elapsed"
            value={payload.elapsedCallTime}
          />
          <NotificationRow
            iconName={IconNames.HOURGLASS_BOTTOM}
            title="Assistance needed"
            value={payload.timePassed}
          />
          <NotificationRow
            iconName={IconNames.EVENT}
            title="Due date"
            value={dayjs
              .tz(castPossibleLongToNumber(payload.dueDateMillis))
              .format('ddd M/D/YYYY [at] h:mma')}
          />
          <NotificationRow
            iconName={IconNames.PEOPLE}
            title="Organization"
            value={payload.orgDisplayName}
          />
          <NotificationRow
            iconName={IconNames.ATTACH_MONEY}
            title="Payer"
            value={payload.payerName}
          />
          <NotificationRow
            iconName={IconNames.PHONE}
            title="Previous calls"
            value={payload.previousCalls}
          />
        </List>
      </BaseNotification>
    );
  }
);

export default BegForOperatorNotification;
