import { ProviderContext, useSnackbar } from 'notistack';
import { ReactNode, useEffect } from 'react';

import { setSnackbarContext } from '@infinitus/hooks/useCustomSnackbar';

interface Props {
  children?: ReactNode;
}

let snackbarContext: ProviderContext;

function CustomSnackbarProvider({ children }: Props): JSX.Element {
  snackbarContext = useSnackbar();

  if (snackbarContext === undefined) {
    throw new Error('You forgot to wrap CustomSnackbarProvider in a SnackbarProvider');
  }

  const { closeSnackbar, enqueueSnackbar } = snackbarContext;

  useEffect(() => {
    snackbarContext = { closeSnackbar, enqueueSnackbar };
  }, [closeSnackbar, enqueueSnackbar]);

  setSnackbarContext(snackbarContext);

  return <>{children}</>;
}

export default CustomSnackbarProvider;
