import List from '@mui/material/List';
import { forwardRef, useCallback } from 'react';

import { Button } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';
import VisualVariant from '@infinitus/types/visual-variant-types';
import { getRequeueReasonDisplayName } from 'utils/displayNames';

import BaseNotification, { NotificationProps } from './BaseNotification';
import { NotificationRow } from './NotificationRow';
import { useNotification } from './helpers';

const RequeueNotification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ notification, compact }, ref) => {
    const payload = notification.payload?.requeueNotification ?? {};
    const notificationUuid = notification.uuid;
    const { closeSnackbar } = useSnackbar();
    const { reportClickToBackend } = useNotification(
      infinitusai.be.NotificationType.NOTIFICATION_TYPE_REQUEUE_TASK,
      notificationUuid,
      notification.orgUuid,
      notification.userEmail
    );

    const handleJoinButtonClicked = useCallback(() => {
      reportClickToBackend(infinitusai.be.NotificationAction.NOTIFICATION_ACTION_CLICKED);
      window.open(
        `/${payload.orgName}/operator/tasks/${payload.taskUuid}/calls/${payload.callUuid}`
      );
      closeSnackbar(notificationUuid);
    }, [
      closeSnackbar,
      notificationUuid,
      payload.callUuid,
      payload.orgName,
      payload.taskUuid,
      reportClickToBackend,
    ]);

    return (
      <BaseNotification
        actions={
          <Button
            onClick={handleJoinButtonClicked}
            text="View call"
            variant={VisualVariant.FILLED}
          />
        }
        compact={compact}
        notification={notification}
        onBodyClick={handleJoinButtonClicked}
        ref={ref}
        title="Call requeued"
        type={infinitusai.be.NotificationType.NOTIFICATION_TYPE_GENERIC}
      >
        <List>
          <NotificationRow
            iconName={IconNames.PEOPLE}
            title="Organization"
            value={payload.orgName}
          />
          <NotificationRow
            iconName={IconNames.CONTENT_PASTE_SEARCH}
            title="Reviewer"
            value={payload.reviewerEmail}
          />
          <NotificationRow
            iconName={IconNames.INFO}
            title="Reason"
            value={getRequeueReasonDisplayName(
              payload.requeueReason?.type ?? infinitusai.be.RequeueReason.Type.REASON_UNKNOWN
            )}
          />
          <NotificationRow iconName={IconNames.NOTES} title="Notes" value={payload.requeueNotes} />
        </List>
      </BaseNotification>
    );
  }
);

export default RequeueNotification;
