import { useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { OperatorOnlineStatus } from 'generated/gql/graphql';
import useOperatorOnlineStatus from 'hooks/useOperatorOnlineStatus';

export function OfflineBackdrop() {
  const theme = useTheme();
  const { operatorOnlineStatus } = useOperatorOnlineStatus();

  return (
    <Backdrop
      open={operatorOnlineStatus === OperatorOnlineStatus.OFFLINE}
      sx={{ cursor: 'pointer', zIndex: theme.zIndex.snackbar - 1 }}
    />
  );
}
