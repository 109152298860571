import { gql } from '@apollo/client';

export const CREATE_INCIDENT = gql`
  mutation CreateIncident($incidentInput: CreateIncidentInput!) {
    createIncident(incidentInput: $incidentInput) {
      id
      presignedUploadURLs
    }
  }
`;

export enum ChatBubbleNlpEventType {
  NLP_REQUEST = 'NLP_REQUEST',
  NLP_RESPONSE = 'NLP_RESPONSE',
}
