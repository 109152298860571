import { Button, ButtonProps } from '@infinitus/components/Button/Button';
import { Tooltip } from '@infinitus/components/Tooltip';

interface Props extends ButtonProps {
  tooltipText: React.ReactNode | string;
}

export function TooltipButton({ tooltipText, ...props }: Props) {
  return (
    <Tooltip title={tooltipText}>
      <Button {...props} />
    </Tooltip>
  );
}
