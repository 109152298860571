import { AppName, fetchApi } from '@infinitusai/api';
import { useApiParams } from '@infinitusai/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { infinitusai } from '@infinitus/proto/pbjs';

export function useGetOrgs() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgsRequest.fromObject({});

  const getOrgs = useQuery<infinitusai.auth.GetOrgsResponse, Error>([
    orgUuid,
    'auth',
    'getOrgs',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getOrgs.data?.orgs) return [];
    const orgs = getOrgs.data.orgs.map((org) => ({
      ...org,
      availableSeats: org?.availableSeats !== null ? { value: org?.availableSeats } : null,
    }));
    return orgs.map((org) => infinitusai.orgs.Org.fromObject(org));
  }, [getOrgs.data]);
  return { ...getOrgs, data };
}

export function useGetOrg(org?: string) {
  const reqBody = infinitusai.orgs.GetOrgRequest.fromObject({});

  const getOrg = useQuery<infinitusai.orgs.GetOrgResponse, Error>(
    [org, 'auth', 'getOrg', reqBody],
    { enabled: org !== '' }
  );

  const data = React.useMemo(() => {
    if (!getOrg.data?.org) return null;

    const org = {
      ...getOrg.data.org,
      availableSeats:
        getOrg.data.org?.availableSeats !== null
          ? { value: getOrg.data.org?.availableSeats }
          : null,
    };
    return infinitusai.orgs.Org.fromObject(org);
  }, [getOrg.data?.org]);
  return { ...getOrg, data };
}

export function useGetInvite(inviteUuid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetInviteRequest.fromObject({ uuid: inviteUuid });
  const getInvite = useQuery<infinitusai.auth.GetInviteResponse, Error>([
    orgUuid,
    'auth',
    'getInvite',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getInvite.data
        ? {
            invite: infinitusai.auth.Invite.fromObject(getInvite.data.invite || {}),
            orgName: getInvite.data.orgName,
          }
        : null,
    [getInvite.data]
  );

  return { ...getInvite, data };
}

export function useAcceptInvite() {
  const app = AppName.OPERATOR;
  const { user } = useApiParams();
  const acceptInvite = useMutation<
    infinitusai.auth.AcceptInviteResponse,
    Error,
    infinitusai.auth.AcceptInviteRequest
  >((req) => {
    return fetchApi({ appName: app, user, path: '/auth/acceptInvite', body: req });
  });

  return acceptInvite;
}

export function useGetOrgUsers() {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetOrgUsersRequest.fromObject({
    types: [],
  });
  const getOrgUsers = useQuery<infinitusai.auth.GetOrgUsersResponse, Error>([
    orgUuid,
    'auth',
    'getOrgUsers',
    reqBody,
  ]);

  const data = React.useMemo(
    () =>
      getOrgUsers.data
        ? getOrgUsers.data.users.map((user) => infinitusai.auth.User.fromObject(user))
        : [],
    [getOrgUsers.data]
  );

  return { ...getOrgUsers, data };
}

export function useGetUser(uid: string) {
  const { orgUuid } = useApiParams();
  const reqBody = infinitusai.auth.GetUserRequest.fromObject({ uid });

  const getUser = useQuery<infinitusai.auth.GetUserResponse, Error>([
    orgUuid,
    'auth',
    'getUser',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getUser.data?.user) return null;
    return infinitusai.auth.User.fromObject(getUser.data.user);
  }, [getUser.data]);
  return { ...getUser, data };
}

export function useGetUsers(types: infinitusai.auth.UserType[]) {
  const { orgUuid } = useApiParams();

  const reqBody = infinitusai.auth.GetUsersRequest.fromObject({ types });

  const getUsers = useQuery<infinitusai.auth.GetUsersResponse, Error>([
    orgUuid,
    'auth',
    'getUsers',
    reqBody,
  ]);

  const data = React.useMemo(() => {
    if (!getUsers.data?.users) return null;
    return getUsers.data.users.map((user) => infinitusai.auth.User.fromObject(user));
  }, [getUsers.data]);
  return { ...getUsers, data };
}
