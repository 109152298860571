import { Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ForwardedRef, forwardRef } from 'react';

import { IconNames } from '@infinitus/components/Icon';
import { IconColors } from '@infinitus/components/Icon/IconTypes';
import ShirtSizes from '@infinitus/types/shirt-sizes';

import { getStyles } from './IconStyles';

interface IconProps {
  className?: string;
  color?: IconColors;
  name: IconNames;
  outlined?: boolean;
  size?: ShirtSizes;
  style?: React.CSSProperties;
  sx?: SxProps;
}

export const Icon = forwardRef((props: IconProps, ref: ForwardedRef<HTMLSpanElement>) => {
  const theme = useTheme();
  const { className, color, name, outlined = false, size, style, sx, ...etc } = props;
  const styles = getStyles(color as IconColors, size as ShirtSizes, theme);
  const dynamicStyles = {
    ...(className && {
      className,
    }),
    ...(color && {
      color: styles.color,
    }),
    ...(size && {
      fontSize: styles.fontSize,
    }),
  };
  return (
    <Box
      className={`material-icons ${className || ''} ${outlined ? 'material-icons-outlined' : ''}`}
      ref={ref}
      sx={{
        cursor: 'inherit',
        userSelect: 'none',
        ...dynamicStyles,
        ...sx,
      }}
      {...etc}
    >
      {name}
    </Box>
  );
});
