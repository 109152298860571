import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';

const gcpConfig = {
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:
    process.env.NODE_ENV === 'development'
      ? gcpConfig.projectId + '.firebaseapp.com'
      : window.location.hostname,
  databaseURL: 'https://' + gcpConfig.projectId + '.firebaseio.com',
  projectId: gcpConfig.projectId,
  storageBucket: gcpConfig.projectId + '.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export function initFirebase() {
  const app = initializeApp(firebaseConfig);
  return initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  });
}
