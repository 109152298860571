import { SxProps } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiButtonBase from '@mui/material/ButtonBase';
import { FocusEvent, MouseEvent } from 'react';
import innerText from 'react-innertext';

import ShirtSizes from '@infinitus/types/shirt-sizes';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import VisualVariant from '@infinitus/types/visual-variant-types';
import { logFormIdFormatError } from '@infinitus/utils/components';

import { ButtonEvent, logButtonEvent } from '../../ButtonLogs';

export interface UnstyledButtonProps {
  autoFocus?: boolean;
  // The regular buttons don't allow children, just text and icons
  // We want this custom case to be covered, but don't want this used often
  children: React.ReactNode;
  color?: ThemeColorTypes;
  disabled?: boolean;
  // Occasionally consumers of this component do their own logging
  // Use this to avoid duplicates, like with the Modal
  disableLogs?: boolean;
  formId?: string;
  fullWidth?: boolean;
  logFunction?: (logData: ButtonEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  onClick: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  size?: ShirtSizes.SM | ShirtSizes.MD | ShirtSizes.LG;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  // HTML types allowed on button
  type?: 'submit' | 'reset' | 'button';
  variant?: VisualVariant | 'UNSTYLED';
}

export function UnstyledButton({
  autoFocus,
  children,
  color = ThemeColorTypes.PRIMARY,
  onBlur,
  onClick,
  onFocus,
  disabled,
  disableLogs,
  formId,
  fullWidth = false,
  logFunction = logButtonEvent,
  size = ShirtSizes.MD,
  sx,
  variant = 'UNSTYLED',
  ...props
}: UnstyledButtonProps) {
  logFormIdFormatError(formId);

  function log(event: FocusEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) {
    if (disableLogs) return;
    logFunction({
      event,
      formId,
      text: innerText(children),
    });
  }

  function handleBlur(event: FocusEvent<HTMLButtonElement>) {
    if (onBlur) onBlur(event);
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(event);
    log(event);
  }

  function handleFocus(event: FocusEvent<HTMLButtonElement>) {
    if (onFocus) onFocus(event);
  }

  return variant === 'UNSTYLED' ? (
    <MuiButtonBase
      aria-label={innerText(children)}
      autoFocus={autoFocus}
      color={color}
      disabled={disabled}
      form={formId}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleFocus}
      sx={{
        borderRadius: 2,
        whiteSpace: 'nowrap',
        ...sx,
      }}
      type={formId ? 'submit' : 'button'}
      {...props}
    >
      {children}
    </MuiButtonBase>
  ) : (
    <MuiButton
      aria-label={innerText(children)}
      autoFocus={autoFocus}
      color={color}
      disabled={disabled}
      form={formId}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleFocus}
      sx={{
        borderRadius: 2,
        whiteSpace: 'nowrap',
        ...sx,
      }}
      type={formId ? 'submit' : 'button'}
      variant={variant}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
