import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SnackbarContent } from 'notistack';
import { forwardRef, useCallback } from 'react';

import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';
import dayjs from '@infinitus/utils/dayjs';
import { castPossibleLongToNumber } from 'utils';

import { useNotification } from './helpers';

export interface BaseNotificationProps {
  // The actions buttons that appear in the footer of the notification
  actions?: JSX.Element;
  // The main content of the notification
  children: JSX.Element;
  // The compact version of a notification is used under the notification bell in the header
  compact?: boolean;
  notification: infinitusai.be.Notification;
  // Callback when the user clicks on the notification's body
  onBodyClick?: () => void;
  title?: string | null;
  type: infinitusai.be.NotificationType;
}

export type NotificationProps = Omit<BaseNotificationProps, 'children' | 'onBodyClick' | 'type'>;

const BaseNotification = forwardRef<HTMLDivElement, BaseNotificationProps>(
  (
    { notification, title = 'Notification', children, actions, type, onBodyClick, compact },
    ref
  ) => {
    const notificationUuid = notification.uuid;
    const { closeSnackbar } = useSnackbar();
    const { handleDismiss, handleHide } = useNotification(
      type,
      notificationUuid,
      notification.orgUuid,
      notification.userEmail
    );

    const theme = useTheme();

    const handleNotificationClick = useCallback(() => {
      if (onBodyClick) {
        onBodyClick();
        closeSnackbar(notification.uuid);
      }
    }, [closeSnackbar, notification.uuid, onBodyClick]);

    // When re-loading an existing notification from Firestore, the enqueuedAtMillis field
    // is returned as a string (which will cause a moment.js error), so we need to parse it.
    const enqueuedAtMillis = castPossibleLongToNumber(notification.enqueuedAtMillis);
    const timeAgo = dayjs.tzo(Number(enqueuedAtMillis)).fromNow();

    return compact ? (
      <Card sx={{ flexGrow: 1, minWidth: 300, padding: 0 }}>
        <CardActions sx={{ backgroundColor: 'background.default', padding: 0.5 }}>
          <Stack alignItems="center" direction="row" spacing={1} sx={{ width: '100%' }}>
            <Typography sx={{ marginInlineEnd: 'auto', marginInlineStart: 1.5 }} variant="body1">
              {title}
            </Typography>
            <Typography sx={{ lineHeight: 1, marginInlineStart: 1 }} variant="caption">
              {timeAgo}
            </Typography>
            <IconButton iconName={IconNames.CLOSE} onClick={handleDismiss} title="Dismiss" />
          </Stack>
        </CardActions>
        <CardActionArea
          onClick={handleNotificationClick}
          sx={{ padding: 0, whiteSpace: 'pre-line' }}
        >
          {children}
        </CardActionArea>
      </Card>
    ) : (
      <SnackbarContent
        ref={ref}
        style={{
          pointerEvents: 'all',
          minWidth: 300,
        }}
      >
        <Card
          raised
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            overflow: 'visible',
            padding: 0,
          }}
        >
          <CardActions sx={{ justifyContent: 'space-between', padding: 0.5 }}>
            <Typography color="textPrimary" sx={{ marginInlineStart: 1.5 }} variant="h6">
              {title}
            </Typography>
            <IconButton
              hideTooltip={true}
              iconName={IconNames.CLOSE}
              onClick={handleHide}
              title="Close"
            />
          </CardActions>
          <CardActionArea
            onClick={handleNotificationClick}
            sx={{
              background: theme.palette.background.paper,
              padding: 0,
            }}
          >
            {children}
          </CardActionArea>
          {actions && (
            <>
              <Divider sx={{ borderColor: theme.palette.grey[800] }} />
              <CardActions sx={{ backgroundColor: 'background.paper', justifyContent: 'flex-end' }}>
                {actions}
              </CardActions>
            </>
          )}
        </Card>
      </SnackbarContent>
    );
  }
);

export default BaseNotification;
