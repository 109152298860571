import { ReactNode } from 'react';
import innerText from 'react-innertext';

import { logUiEvent } from '@infinitus/hooks/useLogBuffer';

export interface ModalEvent {
  componentName?: string;
  eventName: 'cancel' | 'close' | 'confirm' | 'confirmed' | 'open';
  label: ReactNode;
  reason?: 'backdropClick' | 'cancelClick' | 'escapeKeyDown';
}

export function logModalEvent({ eventName, label, reason, componentName = 'Modal' }: ModalEvent) {
  logUiEvent({
    componentLabel: innerText(label),
    componentName: componentName,
    eventName: eventName,
    eventValue: reason,
  });
}
