import { useMutation, useQuery } from '@apollo/client';
import { useAuth, UserType } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { createFilterOptions } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import useId from '@mui/material/utils/useId';
import RecentCallPicker from 'layout/AppHeader/subcomponents/ReportIncident/RecentCallPicker';
import { matches, omit, pick, uniq } from 'lodash';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import innerText from 'react-innertext';

import { Modal } from '@infinitus/components/Modal';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import { logBufferVar, logGcsFileUpload } from '@infinitus/hooks/useLogBuffer';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import { Choice } from '@infinitus/types/ui-input-types';
import Autocomplete from 'components/Autocomplete';
import FileInput, { FileInputHandle } from 'components/FileInput';
import useCallState from 'hooks/useCallState';
import { INCIDENT_TYPES } from 'pages/admin/incidentConfig/graphql';
import { INCIDENTS } from 'pages/admin/incidents/graphql';
import {
  CreateIncident,
  CreateIncidentInput,
  CreateIncidentVariables,
  CreateIncident_createIncident,
  GetIncidentTypes,
  GetIncidentTypesVariables,
  GetIncidentTypes_incidentTypes as IncidentType,
} from 'types/gqlMapping';
import { getEnvironmentName } from 'utils';
import RecentCallsService, {
  getRecentCallEntryId,
  RecentCallEntry,
} from 'utils/RecentCallsService';
import { getUuidDisplayName } from 'utils/displayNames';

import { CREATE_INCIDENT } from './graphql';
import { incidentTypeToChoice } from './helpers';
import { ReportIncidentModalMode, OnChangeIncidentCallbackType } from './types';

export type ReportIncidentModalProps = {
  incidentData?: Partial<CreateIncidentInput>;
  mode?: ReportIncidentModalMode;
  onChangeIncident?: ({ name, value }: OnChangeIncidentCallbackType) => void;
} & Omit<ComponentProps<typeof Modal>, 'title'>;

interface IncidentFormValue extends Pick<CreateIncidentInput, 'incidentTypeUUID' | 'description'> {
  categoryFilter: string;
  recentCallEntryId?: string;
  selectedFiles: File[];
  subcategoryFilter: string;
}

const DEFAULT_FORM_VALUES: IncidentFormValue = {
  categoryFilter: '',
  incidentTypeUUID: '',
  description: '',
  selectedFiles: [],
  subcategoryFilter: '',
};

const INFINITUS_ORG_UUID = '2fce3704-c971-4a33-a8ac-48364f8508e5';

const INCIDENT_DATA_FORM_FIELDS: Array<keyof IncidentFormValue> = [
  'description',
  'incidentTypeUUID',
];

interface IncidentTypeFilter {
  category?: string;
  onlyVisible?: boolean;
  subcategory?: string;
}

interface ModalConfig {
  descriptionOptional?: boolean;
  hideCallPicker?: boolean;
  hideFiles?: boolean;
  incidentTypeFilter?: IncidentTypeFilter;
  showCategoryFilter?: boolean;
  title?: string;
}

const getModalConfig = (mode: ReportIncidentModalMode, hasAdminAccess: boolean): ModalConfig => {
  switch (mode) {
    case ReportIncidentModalMode.TECHNICAL_ISSUE:
      return {
        descriptionOptional: true,
        hideCallPicker: true,
        hideFiles: false,
        incidentTypeFilter: {
          category: 'Technical Issues',
        },
        title: 'Report issue',
      };
    default:
      return {
        incidentTypeFilter: {
          onlyVisible: !hasAdminAccess,
        },
        showCategoryFilter: true,
      };
  }
};

export default function ReportIncidentModal({
  incidentData,
  isOpen,
  mode = ReportIncidentModalMode.INCIDENT,
  onConfirm,
  isSubmitting = false,
  onChangeIncident,
  ...modalProps
}: ReportIncidentModalProps) {
  const { orgUuid } = useAppState();
  const { user, hasUserType, orgs } = useAuth();
  const { taskUuid: currentTaskUuid, callUuid: currentCallUuid } = useGetIdsFromUrl();
  const formId = useId();
  const { enqueueSnackbar } = useSnackbar();
  const { getCallState } = useCallState();
  const fileInputRef = useRef<FileInputHandle>(null);

  const isAdmin = hasUserType([UserType.ADMIN]);

  const modalConfig = useMemo(() => getModalConfig(mode, isAdmin), [mode, isAdmin]);
  const { incidentTypeFilter, showCategoryFilter } = modalConfig;
  const { data: queryData } = useQuery<GetIncidentTypes, GetIncidentTypesVariables>(
    INCIDENT_TYPES,
    { variables: { onlyVisible: incidentTypeFilter?.onlyVisible }, skip: !isOpen }
  );
  const [createIncidentMutation, { loading: mutationLoading }] = useMutation<
    CreateIncident,
    CreateIncidentVariables
  >(CREATE_INCIDENT, { refetchQueries: [INCIDENTS] });
  const [filesUploading, setFilesUploading] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      ...DEFAULT_FORM_VALUES,
      ...pick(incidentData, INCIDENT_DATA_FORM_FIELDS),
    };
  }, [incidentData]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset: resetForm,
  } = useForm<IncidentFormValue>({
    defaultValues,
  });
  const [categoryFilter, subcategoryFilter] = useWatch({
    control,
    name: ['categoryFilter', 'subcategoryFilter'],
  });

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  const [typeChoices, setTypeChoices] = useState<Choice<string, IncidentType>[]>([]);
  const [categoryChoices, setCategoryChoices] = useState<Choice<string, never>[]>([]);
  const [subcategoryChoices, setSubcategoryChoices] = useState<Choice<string, never>[]>([]);

  useEffect(() => {
    const { category, subcategory } = incidentTypeFilter || {};

    // Set category choices for category filter autocomplete
    const allIncidentTypes = queryData?.incidentTypes || [];

    setCategoryChoices(
      uniq(allIncidentTypes.map((type) => type.category)).map((value) => ({
        label: value,
        value: value,
      }))
    );

    const match: Partial<IncidentType> = {};

    if (category) {
      match.category = category;
    } else if (categoryFilter) {
      match.category = categoryFilter;
    }

    // Set filtered subcategories based on category
    const filteredSubcategories = allIncidentTypes
      .filter(matches(match))
      .filter((type) => type.subcategory)
      .map((type) => type.subcategory);
    setSubcategoryChoices(
      uniq(filteredSubcategories).map((value) => ({
        label: value,
        value: value,
      }))
    );

    // Set filtered incident types based on category + subcategory
    if (subcategory) {
      match.subcategory = subcategory;
    } else if (subcategoryFilter) {
      match.subcategory = subcategoryFilter;
    }

    const filteredIncidentTypes = allIncidentTypes.filter(matches(match));
    setTypeChoices(
      filteredIncidentTypes.map((type) =>
        incidentTypeToChoice(type, {
          includeCategory: !showCategoryFilter || !categoryFilter,
          includeSubcategory: !showCategoryFilter || !subcategoryFilter,
        })
      )
    );
  }, [incidentTypeFilter, showCategoryFilter, queryData, categoryFilter, subcategoryFilter]);

  const resetSubcategory = useCallback(() => {
    setValue('subcategoryFilter', '');
  }, [setValue]);
  const resetIncidentType = useCallback(() => {
    setValue('incidentTypeUUID', '');
  }, [setValue]);

  const updateCategoryAndSubcategory = useCallback(
    (newIncidentTypeUUID: string) => {
      // When showCategoryFilter is not shown, there is no way to reset categoryFilter or subcategoryFilter in the UI
      // so don't update categoryFilter and subcategoryFilter
      if (!modalConfig.showCategoryFilter) {
        return;
      }

      const matchingChoice = typeChoices.find((choice) => choice.value === newIncidentTypeUUID);
      if (!matchingChoice) {
        return;
      }

      setValue('categoryFilter', matchingChoice?.data?.category || '');
      setValue('subcategoryFilter', matchingChoice?.data?.subcategory || '');
    },
    [setValue, typeChoices, modalConfig]
  );

  const filterOptions = createFilterOptions<Choice<string, IncidentType>>({
    stringify: ({ label }: Choice) => innerText(label),
  });

  // Auto-select relevant call/task on open if empty
  useEffect(() => {
    const { recentCallEntryId } = getValues();
    if (isOpen && ((!recentCallEntryId && currentTaskUuid) || currentCallUuid)) {
      setValue(
        'recentCallEntryId',
        getRecentCallEntryId({ taskUuid: currentTaskUuid, callUuid: currentCallUuid })
      );
    } else if (isOpen && !currentTaskUuid) {
      setValue('recentCallEntryId', '');
    }
  }, [getValues, isOpen, setValue, currentTaskUuid, currentCallUuid]);

  async function onSubmit({
    description,
    recentCallEntryId,
    incidentTypeUUID,
    selectedFiles,
  }: IncidentFormValue) {
    try {
      let recentCallEntry: RecentCallEntry | undefined;

      if (recentCallEntryId) {
        recentCallEntry = await RecentCallsService.getEntry(recentCallEntryId);
      }

      const logs = logBufferVar().map((item) => JSON.stringify(item));

      // fallback to Infinitus Systems orgUuid if reporting from /admin
      const orgUUID = !window.location.pathname.includes('/operator')
        ? INFINITUS_ORG_UUID
        : recentCallEntry?.orgUuid || orgUuid;

      const org = Object.values(orgs || {})
        .map((org) => org)
        .find((org) => org.uuid === orgUUID);

      let onCall = false;

      const callState = getCallState();
      if (callState) {
        const { operators, audioEndMillis, id } = callState;
        const didJoinCall =
          operators.find(
            ({ isPresentOnCall, operatorEmail }) => operatorEmail === user?.email && isPresentOnCall
          ) !== undefined;

        onCall = currentCallUuid === id && didJoinCall && audioEndMillis === 0;
      }

      const createdIncident = await createIncident({
        orgUUID: org?.uuid || '',
        callUUID: recentCallEntry?.callUuid,
        taskUUID: recentCallEntry?.taskUuid,
        // Don't store payer ID for dummy tasks with id = ""
        payerID: recentCallEntry?.task?.bvInputs?.payerInfo?.infinitusId || undefined,
        onCall,
        incidentTypeUUID,
        description,
        filenames: selectedFiles?.map((file) => file.name) || [],
        fileExtensions: selectedFiles?.map((file) => file.name.split('.').pop() ?? '') || [],
        environment: getEnvironmentName(),
        pageURL: window.location.href,
        logs,
        ...omit(incidentData, INCIDENT_DATA_FORM_FIELDS),
      });

      if (createdIncident.error || !createdIncident.incident) {
        return;
      }
      const errorOrUndefined = await uploadFiles({
        selectedFiles,
        presignedUploadURLs: createdIncident.incident.presignedUploadURLs || [],
      });

      if (errorOrUndefined !== undefined) {
        return;
      }
      enqueueSnackbar(
        `Issue ${getUuidDisplayName(createdIncident.incident.id)} created successfully.`,
        {
          variant: 'success',
        }
      );

      await onConfirm();

      reset();
    } catch (error) {
      // onConfirm must handle its own error
      console.error(error);
    }
  }

  function reset() {
    resetForm();
    fileInputRef.current?.resetSelectedFiles();
  }

  async function createIncident(
    incidentInput: CreateIncidentInput
  ): Promise<{ error?: any; incident?: CreateIncident_createIncident }> {
    try {
      const response = await createIncidentMutation({ variables: { incidentInput } });
      if (!response.data?.createIncident) {
        return { error: response.errors };
      }

      const {
        data: { createIncident },
      } = response;

      return { incident: createIncident };
    } catch (error) {
      enqueueSnackbar(
        "Couldn't report issue. Please check your connection and try again in a moment.",
        {
          variant: 'error',
        }
      );
      console.error(error);
      return { error };
    }
  }

  async function uploadFiles({
    selectedFiles,
    presignedUploadURLs,
  }: {
    presignedUploadURLs: string[];
    selectedFiles: File[];
  }) {
    if (!selectedFiles?.length) {
      return;
    }

    try {
      setFilesUploading(true);
      await Promise.all(
        selectedFiles.map(async (file, index) => {
          if (!presignedUploadURLs?.[index]) {
            return;
          }

          const response = await fetch(presignedUploadURLs[index], {
            method: 'PUT',
            body: file,
          });

          logGcsFileUpload({
            file,
            presignedUploadUrl: presignedUploadURLs[index],
            statusCode: response.status,
          });

          if (!response.ok) {
            throw new Error(`File ${file.name} failed to upload`);
          }
        })
      );
    } catch (error: any) {
      enqueueSnackbar('There was an issue uploading your files. Please try again later.', {
        variant: 'error',
      });
      console.error(error);
      // Return error so caller knows error occurred
      return error;
    } finally {
      setFilesUploading(false);
    }
  }

  return (
    <Modal
      {...modalProps}
      confirmButtonLabel="Submit"
      formId={formId}
      isOpen={isOpen}
      isSubmitting={mutationLoading || filesUploading || isSubmitting}
      onConfirm={() => null}
      title={modalConfig.title || 'Report issue'}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Stack paddingBottom={2} paddingTop={2} spacing={2}>
          {showCategoryFilter && (
            <Stack direction="row" spacing={2}>
              <Controller
                control={control}
                name="categoryFilter"
                render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                  return (
                    <Autocomplete
                      autoFocus={showCategoryFilter}
                      blurOnSelect
                      choices={categoryChoices}
                      error={invalid}
                      fullWidth
                      label="Filter by category"
                      onChange={(value) => {
                        onChange(value);
                        resetSubcategory();
                        resetIncidentType();
                      }}
                      selectOnFocus
                      size={ShirtSizes.SM}
                      value={value}
                      variant="filled"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="subcategoryFilter"
                render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                  return (
                    <Autocomplete
                      blurOnSelect
                      choices={subcategoryChoices}
                      error={invalid}
                      fullWidth
                      label="Filter by subcategory"
                      noOptionsText="No subcategories"
                      onChange={(value) => {
                        onChange(value);
                        resetIncidentType();
                      }}
                      selectOnFocus
                      size={ShirtSizes.SM}
                      value={value}
                      variant="filled"
                    />
                  );
                }}
              />
            </Stack>
          )}
          <Controller
            control={control}
            name="incidentTypeUUID"
            render={({ field: { onChange, value }, fieldState: { invalid } }) => {
              return (
                <Autocomplete
                  autoFocus={!showCategoryFilter}
                  blurOnSelect
                  choices={typeChoices}
                  error={invalid}
                  filterOptions={filterOptions}
                  fullWidth
                  getOptionLabel={(option) => {
                    const choice =
                      typeof option !== 'string'
                        ? option
                        : typeChoices.find(({ value }) => value === option);

                    return choice?.data?.summary || '';
                  }}
                  label="Issue summary"
                  onChange={(value) => {
                    onChange(value);
                    updateCategoryAndSubcategory(value);
                    if (onChangeIncident) {
                      const allIncidentTypes = queryData?.incidentTypes || [];
                      const incidentItem = allIncidentTypes.find((type) => type.id === value);
                      onChangeIncident({ name: 'incidentItem', value: incidentItem });
                    }
                  }}
                  required
                  selectOnFocus
                  size={ShirtSizes.SM}
                  value={value}
                  variant="filled"
                />
              );
            }}
          />
          {!modalConfig.hideCallPicker && (
            <Controller
              control={control}
              name="recentCallEntryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <RecentCallPicker isVisible={isOpen} onChange={onChange} value={value || ''} />
                );
              }}
            />
          )}
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  label={`Describe the issue${
                    modalConfig.descriptionOptional ? ' (optional)' : ''
                  }`}
                  maxRows={4}
                  minRows={2}
                  multiline
                  onChange={(e) => {
                    onChange(e);
                    if (onChangeIncident) {
                      onChangeIncident({ name: 'incidentDescription', value: e.target.value });
                    }
                  }}
                  required={!modalConfig.descriptionOptional}
                  value={value || ''}
                  variant="filled"
                />
              );
            }}
          />
          {!modalConfig.hideFiles && (
            <Controller
              control={control}
              name="selectedFiles"
              render={({ field: { onChange } }) => {
                return (
                  <FileInput
                    acceptedFileTypes="image/jpeg, image/png"
                    isUploading={filesUploading}
                    multiple
                    onChange={onChange}
                    ref={fileInputRef}
                  />
                );
              }}
            />
          )}
        </Stack>
      </form>
    </Modal>
  );
}
