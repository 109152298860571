import { useCallback, useState } from 'react';

import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';

import ReportIncidentModal from './ReportIncidentModal';

export default function ReportIncident() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);

  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <>
      <IconButton iconName={IconNames.REPORT} onClick={openModal} title="Report issue" />
      <ReportIncidentModal isOpen={isModalOpen} onCancel={closeModal} onConfirm={closeModal} />
    </>
  );
}
