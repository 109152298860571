import { find, groupBy, isEqual, maxBy, toUpper } from 'lodash';

import { assertUnreachable } from '@infinitus/utils';
import { CallOutputsQueryQuery } from 'generated/gql/graphql';
import { FieldSource, OutputFieldValueEffectType } from 'generated/gql/graphql';
import {
  CallOutputsFragment_outputs,
  CallOutputsFragment_outputs_value,
  CallOutputsQuery_callOutputs_outputs,
  Condition,
  Condition_predicate,
  RulesQuery_rules,
  RulesQuery_rules_effects,
  Condition_predicate_EqualPredicate_value,
  Condition_predicate_GTEPredicate,
  Condition_predicate_GTPredicate,
  Condition_predicate_LTEPredicate,
  Condition_predicate_LTPredicate,
} from 'types/gqlMapping';
import { OutputFieldValueEffect, Rule } from 'types/graphql-simple';
import { JSONStringifyOrder } from 'utils';
import { extractOutputValue, readSingleCallOutputValue } from 'utils/callOutputs';

import { ActiveOutputEffectType, activeOutputFieldEffectsVar, activeRulesVar } from './rulesCache';

export type readOutputValueFn = (outputName: string) => CallOutputsFragment_outputs | undefined;

// IMPORTANT: Keep this logic in sync with the backend version in be/internal/pkg/call/decision_tree
// Any changes made here should be reflected in the backend implementation as well.
// This ensures consistency in rule evaluation across the system.

enum RuleComparatorResult {
  UNRELATED,
  RULE1_SUPERSEDES_RULE2,
  RULE2_SUPERSEDES_RULE1,
  SAME,
}

export const OVERRIDE_RULE_SOURCES = [
  FieldSource.PIE_OVERRIDE,
  FieldSource.CUSTOMER_PIE,
  FieldSource.KG_POWERED_PIE,
];

export interface EvaluatedRule {
  conflict: boolean;
  conflicts: string[];
  rule: Rule;
  satisfied: string[];
  unanswered: string[];
}

export type ValueOverrideInfo = {
  overrideValueEffect: OutputFieldValueEffect;
  rule: RulesQuery_rules;
};

// applyRules evaluates all rules on the current outputs and updates
// activeOutputFieldEffectsVar reactive var.
// It does *not* apply override rules, but returns them back to the caller to be applied
// if desired.
export const applyRules = (rules: RulesQuery_rules[], readOutputValue: readOutputValueFn) => {
  // first, filter rules based on output values
  const filtered = rules.filter(
    (r) =>
      r.conditions.length === 0 ||
      r.conditions.some((conj) => conj.every((c) => conditionIsMet(c, readOutputValue)))
  );
  // filter for only non-superceded rules (except Infinitus Override Rules)
  const rulesGrouped = groupBy(filtered, (rule) => rule.category);
  let activeRules: Rule[] = [];
  for (let group in rulesGrouped) {
    if (group === 'Infinitus Rules') {
      // Conversation rules do not supercede each other - more often, they're
      // used on top of each other.
      activeRules = activeRules.concat(rulesGrouped[group]);
    } else {
      activeRules = activeRules.concat(getNonSupercededRules(rulesGrouped[group]));
    }
  }

  const activeRulesChanged =
    JSONStringifyOrder(activeRulesVar()) !== JSONStringifyOrder(activeRules);

  if (activeRulesChanged) {
    activeRulesVar(activeRules);
    const outputEffects = activeOutputEffectsByOutputName(activeRules);
    activeOutputFieldEffectsVar(outputEffects);
  }

  // override rules to be returned to caller; caller is responsible for clearing out
  // past override rules and applying these new ones to unsyncedOutputValuesVar.
  return {
    activeRulesChanged,
    overrides: applicableOverrideRules(activeRules, readOutputValue),
  };
};

export const evaluateRulesAgainstOutputs = (
  activeRules: Rule[],
  outputs: CallOutputsQueryQuery['callOutputs']['outputs']
): EvaluatedRule[] => {
  const evaluatedRules = activeRules
    .map((rule) => ({
      rule: rule,
      ...categorizeEffects(rule, outputs),
    }))
    .map((checked) => ({
      ...checked,
      conflict: !!checked.conflicts.length,
    }));

  return evaluatedRules;
};

const isOverrideValueEffect = (e: RulesQuery_rules_effects): e is OutputFieldValueEffect => {
  return (
    e.__typename === 'OutputFieldValueEffect' &&
    e.effectType === OutputFieldValueEffectType.OVERRIDE
  );
};

export const applicableOverrideRules = (
  activeRules: RulesQuery_rules[],
  readOutputValue: readOutputValueFn
): ValueOverrideInfo[] => {
  // Since visibility rules should not clash with output rules, filter them out.
  const isVisibilityEffect = (e: RulesQuery_rules_effects) =>
    e.__typename === 'FollowupSuggestionVisibilityEffect' ||
    e.__typename === 'OutputFieldVisibilityEffect';

  const activeOutputRules = activeRules.filter(
    (r) => r.effects.filter((e) => !isVisibilityEffect(e)).length > 0
  );

  const activeOverrideEffects: ValueOverrideInfo[] = activeOutputRules.flatMap((r) =>
    r.effects.filter(isOverrideValueEffect).map((e) => ({
      rule: r,
      overrideValueEffect: e,
    }))
  );

  // for each override rule, only apply if there's no value already set, or if
  // it's been previously filled by an OVERRIDE rule
  return activeOverrideEffects.filter((e) => {
    const curr = readOutputValue(e.overrideValueEffect.outputName);
    return !curr?.value || (curr.source && OVERRIDE_RULE_SOURCES.includes(curr.source));
  });
};

export const activeOutputEffectsByOutputName = (activeRules: RulesQuery_rules[]) => {
  // Currently does not account for rule hierarchy. For example, if rules exist for the same outputName,
  // we just take the rule that comes last in the list.
  return activeRules
    .flatMap((r) => r.effects)
    .reduce<{ [outputName: string]: ActiveOutputEffectType }>((acc, e) => {
      if (e.__typename === 'FollowupSuggestionVisibilityEffect') {
        return acc;
      }
      const activeEffect = acc[e.outputName] || {};
      if (e.__typename === 'OutputFieldValueEffect') {
        // TODO: rule hierarchy
        activeEffect.valueEffect = e;
      }
      if (e.__typename === 'OutputFieldVisibilityEffect') {
        activeEffect.visibilityEffect = e;
      }
      acc[e.outputName] = activeEffect;
      return acc;
    }, {});
};

export const conditionIsMet = (condition: Condition, readOutputValue: readOutputValueFn) => {
  const { outputName, conditionIsMet, predicate, pluralPredicate } = condition;
  const outputNames = outputName.split(' ');

  if (conditionIsMet !== null) {
    // already evaluated on the backend
    return conditionIsMet;
  }

  if (outputNames.length === 1) {
    return singleConditionIsMet(outputName, predicate, readOutputValue);
  }

  let totalMatching = 0;

  for (let i = 0; i < outputNames.length; i++) {
    if (singleConditionIsMet(outputNames[i], predicate, readOutputValue)) {
      totalMatching += 1;
    }
  }

  if (pluralPredicate === null || pluralPredicate === undefined) {
    return false;
  }

  switch (pluralPredicate.__typename) {
    case 'CountGTPredicate':
      return totalMatching > pluralPredicate.num;
  }
};

export const singleConditionIsMet = (
  outputName: string,
  predicate: Condition_predicate,
  readOutputValue: readOutputValueFn
) => {
  // read current value of the field in question
  const current = readOutputValue(outputName);
  const currentValue = current?.isRelevant ? current.value : null;

  switch (predicate.__typename) {
    case 'EqualPredicate':
      return isEqualWithStringStandardization(currentValue, predicate.value, readOutputValue);
    case 'NotEqualPredicate':
      return !isEqualWithStringStandardization(currentValue, predicate.value, readOutputValue);
    case 'InPredicate':
      return predicate.values.some((val) =>
        isEqualWithStringStandardization(currentValue, val, readOutputValue)
      );
    case 'NotInPredicate':
      return !predicate.values.some((val) =>
        isEqualWithStringStandardization(currentValue, val, readOutputValue)
      );
    case 'HasPrefixPredicate':
      if (currentValue?.__typename === 'StringType') {
        return toUpper(currentValue.string).startsWith(toUpper(predicate.value.string));
      }
      return false;
    case 'HasSubstringPredicate':
      if (currentValue?.__typename === 'StringType') {
        return toUpper(currentValue.string).includes(toUpper(predicate.value.string));
      }
      return false;
    case 'GTPredicate':
    case 'GTEPredicate':
    case 'LTPredicate':
    case 'LTEPredicate':
      try {
        return isInequalitySatisfied(currentValue, predicate, readOutputValue);
      } catch (e) {
        console.error(
          `Error when comparing ${outputName} as ${JSON.stringify(
            current
          )} to predicate ${JSON.stringify(predicate)}: ${e}`
        );
        return false;
      }
  }
};

export const isEqualWithStringStandardization = (
  a: CallOutputsFragment_outputs_value | null | undefined,
  b: Condition_predicate_EqualPredicate_value,
  readOutputValue: readOutputValueFn
) => {
  if (b.__typename === 'OutputReferenceType') {
    const bOutputRef = readOutputValue(b.outputReference)?.value;
    if (a && bOutputRef && a.__typename === bOutputRef.__typename) {
      return isEqual(extractOutputValue(a), extractOutputValue(bOutputRef));
    }
  } else if (a?.__typename === 'StringType' && b.__typename === 'StringType') {
    return isEqual(toUpper(a.string.replace(/^0+/, '')), toUpper(b.string.replace(/^0+/, '')));
  } else if (a?.__typename === 'EnumType' && b.__typename === 'EnumType') {
    return isEqual(toUpper(a.enum), toUpper(b.enum));
  }
  return isEqual(a, b);
};

export type InequalityPredicate =
  | Condition_predicate_GTEPredicate
  | Condition_predicate_GTPredicate
  | Condition_predicate_LTEPredicate
  | Condition_predicate_LTPredicate;

export function isInequalitySatisfied(
  currentValue: CallOutputsFragment_outputs_value | null | undefined,
  predicate: InequalityPredicate,
  readOutputValue: readOutputValueFn
) {
  // There's nothing to compare when the current value is not set
  if (currentValue === null || currentValue === undefined) return false;

  const __predicateTypename = predicate.__typename;
  let compare: (lhs: any, rhs: any) => void = (lhs, rhs) => {
    return lhs === rhs;
  };
  switch (__predicateTypename) {
    case 'GTPredicate':
      compare = (lhs, rhs) => lhs > rhs;
      break;
    case 'GTEPredicate':
      compare = (lhs, rhs) => lhs >= rhs;
      break;
    case 'LTPredicate':
      compare = (lhs, rhs) => lhs < rhs;
      break;
    case 'LTEPredicate':
      compare = (lhs, rhs) => lhs <= rhs;
      break;
    default:
      try {
        assertUnreachable(__predicateTypename);
      } catch {}

      throw new Error(`${__predicateTypename} is not an inequality`);
  }

  if (
    predicate.value.__typename !== 'OutputReferenceType' &&
    currentValue?.__typename !== predicate.value.__typename
  ) {
    throw new Error(
      `cannot check inequality. ${currentValue?.__typename} type does not match ${predicate.value.__typename} type`
    );
  }

  const __predicateValueTypename = predicate.value.__typename;
  switch (__predicateValueTypename) {
    case 'StringType':
    case 'IntType':
    case 'MoneyType':
      return compare(extractOutputValue(currentValue), extractOutputValue(predicate.value));
    case 'DateType':
      if (currentValue.__typename !== predicate.value.__typename) {
        throw new Error(`should never hit this error, its a type hack`);
      }
      const lhsDate = extractOutputValue(currentValue, { dateFormat: 'YYYY/MM/DD' });
      const rhsDate = extractOutputValue(predicate.value, { dateFormat: 'YYYY/MM/DD' });
      return compare(lhsDate, rhsDate);
    case 'OutputReferenceType':
      const outputRef = readOutputValue(predicate.value.outputReference)?.value;
      if (currentValue.__typename !== outputRef?.__typename) {
        throw new Error(`should never hit this error, its a type hack`);
      }
      const lhs = extractOutputValue(currentValue);
      const rhs = outputRef ? extractOutputValue(outputRef) : undefined;
      return compare(lhs, rhs);
    default:
      try {
        assertUnreachable(__predicateValueTypename);
      } catch {}

      throw new Error(`comparing ${__predicateValueTypename} types are not supported`);
  }
}

// Returns all rules that are not superceded by another rule in the list. Eg.
// if we have:
// rule1: payer_id=1
// rule2: payer_id=1, plan_type=HMO
// rule3: payer_id=1, policy_type=COMMERCIAL
// this function should return [rule2, rule3].
export const getNonSupercededRules = (rules: Rule[]) => {
  const resultRuleSet: Rule[] = [];
  for (const rule of rules) {
    const comp = rules.map((r) => compareRules(r, rule));

    // if there are no rules that superceded rule2, add to result array
    if (!comp.includes(RuleComparatorResult.RULE1_SUPERSEDES_RULE2)) {
      resultRuleSet.push(rule);
    }
  }

  const maxWeight = maxBy(resultRuleSet, (rule) => rule.weight)?.weight || 0;
  return resultRuleSet.filter((rule) => rule.weight === maxWeight);
};

const compareRules = (rule1: Rule, rule2: Rule): RuleComparatorResult => {
  const rule1Cs = rule1.conditions.map((conj) => conj.map((c) => JSON.stringify(c)));
  const rule2Cs = rule2.conditions.map((conj) => conj.map((c) => JSON.stringify(c)));

  if (conditionExprIsSubset(rule1Cs, rule2Cs)) {
    if (conditionExprIsSubset(rule2Cs, rule1Cs)) return RuleComparatorResult.SAME;
    return RuleComparatorResult.RULE2_SUPERSEDES_RULE1;
  }
  if (conditionExprIsSubset(rule2Cs, rule1Cs)) return RuleComparatorResult.RULE1_SUPERSEDES_RULE2;
  return RuleComparatorResult.UNRELATED;
};

const conditionExprIsSubset = (subset: string[][], superset: string[][]): boolean => {
  for (const subsetConj of subset) {
    if (
      !superset.some((supersetConj) => {
        return subsetConj.every((c) => supersetConj.includes(c));
      })
    ) {
      return false;
    }
  }

  return true;
};

const categorizeEffects = (rule: Rule, outputs: CallOutputsQuery_callOutputs_outputs[]) => {
  const result: {
    conflicts: string[];
    satisfied: string[];
    unanswered: string[];
  } = {
    unanswered: [],
    conflicts: [],
    satisfied: [],
  };
  if (outputs) {
    const effects = rule.effects;
    result.unanswered = effects
      .filter((e) => e.__typename === 'OutputFieldValueEffect')
      .map((e) => e as OutputFieldValueEffect)
      .filter((e) => {
        const fieldPath = e?.fieldPath;
        const value = find(outputs, (output) => output.name === fieldPath)?.value;
        // This should really be checking against the null value defined by the field component.
        return !value;
      })
      .map((e) => e.fieldPath!);

    effects
      .filter((e) => e.__typename === 'OutputFieldValueEffect')
      .map((e) => e as OutputFieldValueEffect)
      .filter((e) => !result.unanswered.includes(e.fieldPath!))
      .forEach((e) => {
        if (effectHasConflict(e, outputs)) {
          result.conflicts.push(e.fieldPath!);
        } else {
          result.satisfied.push(e.fieldPath!);
        }
      });
  }
  return result;
};

export const effectHasConflict = (
  effect: OutputFieldValueEffect,
  outputs: CallOutputsQuery_callOutputs_outputs[]
): boolean => {
  if (!outputs) {
    return false;
  }
  const fieldPath = effect.fieldPath;
  const fieldValue = effect.fieldValue;

  // TODO(Diana): This is hacky. Maybe instead we can use the formdefinition -> convert
  // the fieldValue to proto form to compare.
  // CheckboxEnabledAutocomplete FieldValues are of shape {checked: boolean, value: string}
  const actualValue = readSingleCallOutputValue(outputs, fieldPath);
  const actualValueStr = actualValue?.toString();

  // Special case for checking specialtyPharmacyName. Want to check if the value from outputs bag is NOT IN comma delimited list specified from payer intelligence
  // We will remove this hardcode checking in the next iteration of Payer Intelligence
  if (doesEffectSatisfyNotInComparison(fieldPath) && actualValueStr) {
    let expectedVals = fieldValue.split(',');
    return !expectedVals.includes(actualValueStr);
  }

  return fieldValue !== actualValueStr;
};

export const doesEffectSatisfyNotInComparison = (outputName: string) => {
  if (
    outputName === 'specialtyPharmacy1Name' ||
    outputName === 'specialtyPharmacy2Name' ||
    outputName === 'specialtyPharmacy3Name'
  ) {
    return true;
  }
  return false;
};
