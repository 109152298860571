import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { forwardRef, useCallback } from 'react';

import { Button } from '@infinitus/components/Button';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';
import VisualVariant from '@infinitus/types/visual-variant-types';

import BaseNotification, { NotificationProps } from './BaseNotification';
import { useNotification } from './helpers';

const GenericNotification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ notification, compact }, ref) => {
    const payload = notification.payload?.genericNotification ?? {};
    const notificationUuid = notification.uuid;
    const { closeSnackbar } = useSnackbar();
    const { reportClickToBackend } = useNotification(
      infinitusai.be.NotificationType.NOTIFICATION_TYPE_GENERIC,
      notificationUuid,
      notification.orgUuid,
      notification.userEmail
    );

    const handleJoinButtonClicked = useCallback(() => {
      reportClickToBackend(infinitusai.be.NotificationAction.NOTIFICATION_ACTION_CLICKED);
      if (payload.action?.href) {
        window.open(payload.action.href);
      }
      closeSnackbar(notificationUuid);
    }, [closeSnackbar, notificationUuid, payload.action?.href, reportClickToBackend]);

    return (
      <BaseNotification
        actions={
          payload.action ? (
            <Button
              onClick={handleJoinButtonClicked}
              text={payload.action.label || ''}
              variant={VisualVariant.FILLED}
            />
          ) : undefined
        }
        compact={compact}
        notification={notification}
        onBodyClick={handleJoinButtonClicked}
        ref={ref}
        title={payload.title}
        type={infinitusai.be.NotificationType.NOTIFICATION_TYPE_GENERIC}
      >
        <Box p={2}>
          <Typography variant={compact ? 'body2' : 'body1'}>{payload.description}</Typography>
        </Box>
      </BaseNotification>
    );
  }
);

export default GenericNotification;
