import { useAuth } from '@infinitusai/auth';
import { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

import {
  logConnection,
  logNavigation,
  logNavLoggerReady,
  logTabVisibilityEvent,
} from '@infinitus/hooks/useLogBuffer';

const isTabVisible = () => document.visibilityState === 'visible';

function NavLogger() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Log setup event
  const [isReady, setReady] = useState(isAuthenticated);
  useEffect(() => {
    if (!isReady && isAuthenticated) {
      setReady(true);
      logNavLoggerReady(location);
    }
  }, [isAuthenticated, isReady, location]);

  // Log location changes
  const [prevLocation, setPrevLocation] = useState<Location | null>(null);
  useEffect(() => {
    if (!isReady) return;
    // Avoid logging when search params change
    if (location.pathname === prevLocation?.pathname) return;
    logNavigation({
      fromLocation: prevLocation || null,
      toLocation: location,
    });
    setPrevLocation(location);
    // Log connection info with each navigation
    // @ts-ignore
    logConnection(window.navigator.connection);
  }, [isReady, location, prevLocation]);

  // Log tab focus changes
  useEffect(() => {
    if (!isReady) return;
    const handleVisibilityChange = (evt: Event) => {
      logTabVisibilityEvent({
        isVisible: isTabVisible(),
        location: location,
      });
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup on unmount
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isReady, location]);

  return null;
}

export default NavLogger;
