import { CircularProgress, SxProps } from '@mui/material';

import { Button } from '@infinitus/components/Button';
import { Icon, IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import VisualVariant from '@infinitus/types/visual-variant-types';

interface LoaderButtonProps {
  color?: ThemeColorTypes;
  disableLogs?: boolean;
  formId?: string;
  isAutofocused?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  labelText: string;
  labelTextLoading?: string;
  onClick: () => void;
  size?: ShirtSizes.SM | ShirtSizes.MD | ShirtSizes.LG;
  spinnerColor?: string;
  startIconName?: IconNames | null;
  sx?: SxProps;
  type?: 'submit' | 'reset' | 'button';
  variant?: VisualVariant;
}

export function LoaderButton({
  color = ThemeColorTypes.PRIMARY,
  disableLogs,
  formId,
  isAutofocused,
  isDisabled,
  isLoading,
  labelText,
  labelTextLoading,
  onClick,
  startIconName = null,
  size = ShirtSizes.MD,
  sx,
  spinnerColor = 'white',
  variant = VisualVariant.FILLED,
  ...props
}: LoaderButtonProps) {
  function getIcon() {
    if (isLoading) return <CircularProgress size={16} sx={{ color: spinnerColor }} />;
    if (startIconName) return <Icon name={startIconName} />;
  }

  function getLabelText() {
    if (isLoading && labelTextLoading) return labelText;
    return labelText;
  }

  return (
    <Button
      autoFocus={isAutofocused}
      color={color}
      disabled={isLoading || isDisabled}
      formId={formId}
      onClick={onClick}
      size={size}
      startIcon={getIcon()}
      sx={sx}
      text={getLabelText()}
      variant={variant}
      {...props}
    />
  );
}
