import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

import { infinitusai } from '@infinitus/proto/pbjs';

import { HeartbeatMessageType } from './types';

// The heartbeatMessageType state var tracks the type of page we're currently on, which
// changes the type of heartbeat we send to the server.
const heartbeatMessageType = makeVar<HeartbeatMessageType>(HeartbeatMessageType.OTHER_PAGE);
const heartbeatMessage = makeVar<infinitusai.be.IHeartbeatMessageFromClient>({});
export const lastKnownActivityMillis = makeVar<number>(Date.now());
export const presenceLastUpdatedAtMillis = makeVar<number>(Date.now());

export default function usePresenceHeartbeat() {
  const setHeartbeatMessageType = useCallback((type: HeartbeatMessageType) => {
    heartbeatMessageType(type);
    presenceLastUpdatedAtMillis(Date.now());
  }, []);

  const getHeartbeatMessageType = useCallback(() => {
    return heartbeatMessageType();
  }, []);

  const useHeartbeatMessageType = () => {
    return useReactiveVar(heartbeatMessageType);
  };

  const setHeartbeatMessage = useCallback((message: infinitusai.be.IHeartbeatMessageFromClient) => {
    heartbeatMessage(message);
    presenceLastUpdatedAtMillis(Date.now());
  }, []);

  const getHeartbeatMessage = useCallback(() => {
    return heartbeatMessage();
  }, []);

  const useHeartbeatMessage = () => {
    return useReactiveVar(heartbeatMessage);
  };

  return {
    setHeartbeatMessageType,
    getHeartbeatMessageType,
    useHeartbeatMessageType,
    setHeartbeatMessage,
    getHeartbeatMessage,
    useHeartbeatMessage,
  };
}
