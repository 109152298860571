import { debounce } from 'lodash';

interface Config<T> {
  debounceTime: number;
  flushOnUnload?: boolean;
  maxLength: number;
  onFlush: (buffer: T[]) => unknown;
}

class DebouncedBuffer<T> {
  private _buffer: T[] = [];
  private flushDebounced: () => unknown;
  private hasAccessToWindow = false;
  private maxLength: Config<T>['maxLength'];
  private onFlush: Config<T>['onFlush'];
  private boundHandleBeforeUnload = this.handleBeforeUnload.bind(this);

  constructor({ debounceTime, maxLength, onFlush, flushOnUnload }: Config<T>) {
    this.maxLength = maxLength;

    this.flushDebounced = debounce(() => this.flush(), debounceTime);
    this.onFlush = onFlush;

    if (flushOnUnload && process.env.NODE_ENV !== 'test') {
      try {
        this.hasAccessToWindow = !!window.document;
      } catch (error) {
        this.hasAccessToWindow = false;
      }

      if (this.hasAccessToWindow) {
        window?.addEventListener('beforeunload', this.boundHandleBeforeUnload);
      } else {
        console.warn(
          'DebouncedBuffer was created with option `flushOnUnload` enabled but is being used in a non-DOM environment'
        );
      }
    }
  }

  buffer(toBuffer: T[], skipFlush?: boolean) {
    this._buffer.push(...toBuffer);

    if (skipFlush) {
      return;
    }

    if (this._buffer.length >= this.maxLength) {
      this.flush();
    } else {
      this.flushDebounced();
    }
  }

  destroy() {
    if (this.hasAccessToWindow) {
      window?.removeEventListener('beforeunload', this.boundHandleBeforeUnload);
    }
  }

  flush() {
    if (!this.buffer.length) {
      return;
    }

    this.onFlush([...this._buffer]);
    this._buffer.length = 0;
  }

  private handleBeforeUnload(event: BeforeUnloadEvent) {
    if (this._buffer.length) {
      this.flush();
      return (event.returnValue = '');
    }
  }
}

export default DebouncedBuffer;
