import { Button as MuiButton, SxProps } from '@mui/material';
import { FocusEvent, MouseEvent } from 'react';

import { Icon, IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import VisualVariant from '@infinitus/types/visual-variant-types';
import { logFormIdFormatError } from '@infinitus/utils/components';

import { ButtonEvent, logButtonEvent } from './ButtonLogs';

export interface ButtonProps {
  autoFocus?: boolean;
  buttonName?: string;
  className?: string;
  color?: ThemeColorTypes;
  disabled?: boolean;
  // Occasionally consumers of this component do their own logging
  // Use this to avoid duplicates, like with the Modal
  disableLogs?: boolean;
  endIconName?: IconNames;
  formId?: string;
  fullWidth?: boolean;
  logFunction?: (logData: ButtonEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  onClick: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  size?: ShirtSizes.SM | ShirtSizes.MD | ShirtSizes.LG;
  startIcon?: React.ReactNode;
  startIconName?: IconNames;
  sx?: SxProps;
  text: string;
  // HTML types allowed on button
  type?: 'submit' | 'reset' | 'button';
  variant?: VisualVariant;
}

function IconFromName(name: IconNames | undefined) {
  if (!name) return null;
  if (name) return <Icon name={name} />;
}

export function Button({
  autoFocus,
  buttonName = 'Button',
  className,
  color = ThemeColorTypes.PRIMARY,
  endIconName,
  onBlur,
  onClick,
  onFocus,
  disabled,
  disableLogs,
  formId,
  fullWidth = false,
  logFunction = logButtonEvent,
  size = ShirtSizes.MD,
  startIcon,
  startIconName,
  sx,
  text,
  variant,
  ...props
}: ButtonProps) {
  logFormIdFormatError(formId);

  function log(event: FocusEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) {
    if (disableLogs) return;
    logFunction({
      componentName: buttonName,
      event,
      formId,
      text,
    });
  }

  function handleBlur(event: FocusEvent<HTMLButtonElement>) {
    if (onBlur) onBlur(event);
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(event);
    log(event);
  }

  function handleFocus(event: FocusEvent<HTMLButtonElement>) {
    if (onFocus) onFocus(event);
  }

  return (
    <MuiButton
      aria-label={text}
      autoFocus={autoFocus}
      className={className}
      color={color}
      disabled={disabled}
      endIcon={IconFromName(endIconName)}
      form={formId}
      fullWidth={fullWidth}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleFocus}
      size={size}
      startIcon={startIcon || IconFromName(startIconName)}
      sx={{
        whiteSpace: 'nowrap',
        ...sx,
      }}
      type={formId ? 'submit' : 'button'}
      variant={variant}
      {...props}
    >
      {text}
    </MuiButton>
  );
}
