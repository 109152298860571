import { useTheme } from '@mui/material/styles';

const useStyles = () => {
  const theme = useTheme();
  return {
    breadcrumb: {
      alignItems: 'center',
      cursor: 'default',
      display: 'flex',
    },
    title: {
      cursor: 'inherit',
    },
    link: {
      alignItems: 'center',
      color: theme.palette.common.white,
      cursor: 'pointer',
      display: 'flex',
    },
    icon: {
      marginInlineEnd: theme.spacing(1.5),
    },
  };
};

export default useStyles;
