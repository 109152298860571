import { useAppState } from '@infinitusai/shared';

import OrgLivenessFlag from '../OrgLivenessFlag';

interface Props {
  orgName?: string;
}

export default function OrgLivenessFlags({ orgName }: Props) {
  const { orgUuid, org } = useAppState();

  if (!orgName) return null;

  // User is not a member of org
  if (!org && orgUuid) return null;

  return (
    <>
      {!org?.isInternal && (
        <OrgLivenessFlag
          label="Customer can view this org via the Customer Portal"
          title="Customer-facing"
        />
      )}
      {org?.isLive && (
        <OrgLivenessFlag label="This org services production tasks" title="Production" />
      )}
    </>
  );
}
