import {
  useSnackbar,
  SnackbarMessage,
  OptionsWithExtraProps,
  EnqueueSnackbar,
  ProviderContext,
} from 'notistack';
import * as React from 'react';

// Time to wait before calling closeSnackbar due to unmount
const IMPLICIT_CLOSE_TIMEOUT = 200;

// Transition durations for the snackbar (https://notistack.com/api-reference)
const DEFAULT_ENTER_DURATION = 225;
const DEFAULT_EXIT_DURATION = 195;

// Time we must wait after calling closeSnackbar() before we can call enqueueSnackbar() again
const CLOSED_LOCKED_TIMEOUT = DEFAULT_EXIT_DURATION;

export type DeclarativeSnackbarProps = {
  /**  Same message you would pass to enqueueSnackbar */
  message: SnackbarMessage;

  /**  Same options you would pass to enqueueSnackbar */
  options?: OptionsWithExtraProps<'default' | 'error' | 'info' | 'success' | 'warn'>;

  show: boolean;

  /** Provide the same key in subsequent rerenders so it persists */
  snackKey: string;
};

let enableDebugLogging = false;

const logDebug = (desc: string, state: MessageState) => {
  if (enableDebugLogging) {
    console.debug(`[Snackbar] action=${desc} state=${state.state}`, state.timeout);
  }
};

// The DeclarativeSnackbarMessage may mount/unmount repeatedly for the same key.
// If we see props.show change to true, then we want to immediately show. If we
// see it change to false, then we want to immediately close. Usually when the
// component unmounts we want to close, however we don't know if a re-mount will
// occur shortly after so we don't want to immediately close in that case.
//
// Terminology:
//   Implicit close - a close that occurs because the component unmounts
//   Explicit close - a close that occurs because props.show is false
//
// Note: When persist is true, enqueueSnackbar will do nothing while the
// snackbar is already open including while it is transitioning out. In this
// case we must allow enough time to pass for the transition to complete before
// attempting to enqueue a new snackbar.

/* State Diagram (Update this using: https://asciiflow.com/#/)
                          ┌────────┐                            
      [Start]────────────►│ Closed │◄──────────────────────────┐
                          └───┬────┘                           │
                              │                                │
                          [show()]                             │
                         (& enqueueSB)                         │
                              │                                │
                              ▼                                │
                          ┌───────┐                            │
        ┌────────────────►│ Shown │◄────────────────┐          │
        │                 └───┬───┘                 │          │
        │                     │             [showingCallBack]  │
        │                     │               (& enqueueSB)    │
        │                     │                     │          │
        │                     │                ┌────┴────┐     │
        │                     │  ┌─────────────┤ Showing │     │
        │                     │  │             └─────────┘     │
        │                     ▼  ▼                  ▲          │
        │              ┌────────────┐               │          │
        │              │            │               │          │
        │      [closeImplicit()]    │               │          │
        │   (& setTimeoutImplicit)  │               │          │
        │              │            │               │          │
     [show()]          │     [closeExplicit()]      │          │
(& cancelTimeout)      │      (& closeSB)           │          │
 (& enqueueSB)         │   (& setTimeoutLocked)     │          │
        │              ▼            │               │          │
        │       ┌─────────┐         │               │          │
        └───────┤ Closing │         │               │          │
                └────┬────┘         │            [show()]      │
                     │              │        (& clearTimeout)  │
           [implicitCallBack]       │    (& setTimeoutShowing) │
                    or              │      (w/ remaining time) │
             [closeExplicit()]      │               │          │
               (& closeSB)          │               │          │
           (& setTimeoutLocked)     ▼               │          │
                     │       ┌───────────────┐      │          │
                     └──────►│ Closed-Locked ├──────┘          │
                             └──────┬────────┘                 │
                                    │                          │
                             [lockedCallBack]                  │
                            (&delete from map)                 │
                                    └──────────────────────────┘

Legend:
Box: State
[trigger]: function call that triggers the transition
(& action): Action/Side effect
*/

type TimeoutInfo = {
  closeSnackbar?: ProviderContext['closeSnackbar'];
  durationMs: number;
  enqueueSnackbar?: ProviderContext['enqueueSnackbar'];
  id: NodeJS.Timeout;
  props: DeclarativeSnackbarProps;
  startTime: number;
};

type MessageState = {
  state: 'shown' | 'showing' | 'closing' | 'closed-locked' | 'closed';
  timeout?: TimeoutInfo;
};

const messageStates = new Map<string, MessageState>();

const getOrSetDefault = <T, U>(map: Map<T, U>, key: T, defaultValue: U): U => {
  let value = map.get(key);
  if (!value) {
    value = defaultValue;
    map.set(key, value);
  }
  return value;
};

const getState = (snackKey: string) => {
  return getOrSetDefault(messageStates, snackKey, {
    state: 'closed',
  });
};

const enqueueSB = (props: DeclarativeSnackbarProps, enqueueSnackbar: EnqueueSnackbar) => {
  enqueueSnackbar(props.message, {
    ...(props.options ?? {}),
    preventDuplicate: true,
    persist: true,
    key: props.snackKey,
    transitionDuration: { enter: DEFAULT_ENTER_DURATION, exit: DEFAULT_EXIT_DURATION }, // fixed to match the default
  });
};

const cancelTimeout = (msgState: MessageState): TimeoutInfo | undefined => {
  if (msgState.timeout) {
    clearTimeout(msgState.timeout.id);
    const result = msgState.timeout;
    msgState.timeout = undefined;
    return result;
  }
};

const setTimeoutImplicit = (
  props: DeclarativeSnackbarProps,
  closeSnackbar: ProviderContext['closeSnackbar'],
  state: MessageState
) => {
  const durationMs = IMPLICIT_CLOSE_TIMEOUT;
  const startTime = Date.now();

  const id = setTimeout(() => {
    logDebug('[Snackbar] implicitCallBack', state);
    state.timeout = undefined;
    // Delegate next timeout to closeExplicit()
    closeExplicit(props, closeSnackbar);
  }, durationMs);

  state.timeout = {
    durationMs,
    id,
    props,
    closeSnackbar,
    startTime,
  };
};

const setTimeoutShowing = (
  props: DeclarativeSnackbarProps,
  enqueueSnackbar: EnqueueSnackbar,
  state: MessageState,
  prevTimeoutInfo: TimeoutInfo | undefined
) => {
  if (!prevTimeoutInfo || !prevTimeoutInfo?.enqueueSnackbar || !prevTimeoutInfo.props) {
    console.error('[Snackbar] prevTimeoutInfo is undefined or missing props', prevTimeoutInfo);
    return;
  }
  const remainingTime = Math.max(
    0,
    prevTimeoutInfo.durationMs - (Date.now() - prevTimeoutInfo.startTime)
  );
  const id = setTimeout(() => {
    logDebug('showingCallBack', state);
    state.state = 'shown';
    if (!prevTimeoutInfo.enqueueSnackbar || !prevTimeoutInfo.props) {
      console.error('[Snackbar] closeSnackbar or props is undefined');
      return;
    }
    enqueueSB(prevTimeoutInfo.props, prevTimeoutInfo.enqueueSnackbar);
    state.timeout = undefined;
  }, remainingTime);

  state.timeout = {
    durationMs: remainingTime,
    id,
    props,
    enqueueSnackbar,
    startTime: Date.now(),
  };
};

const setTimeoutLocked = (
  props: DeclarativeSnackbarProps,
  closeSnackbar: ProviderContext['closeSnackbar'],
  state: MessageState
) => {
  const durationMs = CLOSED_LOCKED_TIMEOUT;
  const startTime = Date.now();

  const id = setTimeout(() => {
    logDebug('lockedCallBack', state);
    state.state = 'closed';
    state.timeout = undefined;
    closeSnackbar(props.snackKey);
    // A non-existent key is treated as the closed state
    messageStates.delete(props.snackKey);
  }, durationMs);

  state.timeout = {
    durationMs,
    id,
    props,
    closeSnackbar,
    startTime,
  };
};

const show = (props: DeclarativeSnackbarProps, enqueueSnackbar: EnqueueSnackbar) => {
  const msgState = getState(props.snackKey);

  logDebug('show()', msgState);

  switch (msgState.state) {
    case 'closed':
    case 'closing':
      msgState.state = 'shown';
      cancelTimeout(msgState);
      enqueueSB(props, enqueueSnackbar);
      break;
    case 'closed-locked':
      msgState.state = 'showing';
      const prevTimeOut = cancelTimeout(msgState);
      setTimeoutShowing(props, enqueueSnackbar, msgState, prevTimeOut);
      break;
    case 'showing':
    case 'shown':
      // Do nothing
      break;
  }
};

const closeImplicit = (
  props: DeclarativeSnackbarProps,
  closeSnackbar: ProviderContext['closeSnackbar']
) => {
  const msgState = getState(props.snackKey);

  logDebug('closeImplicit()', msgState);

  switch (msgState.state) {
    case 'shown':
    case 'showing':
      msgState.state = 'closing';
      cancelTimeout(msgState);
      setTimeoutImplicit(props, closeSnackbar, msgState);
      break;
    case 'closed-locked':
    case 'closing':
    case 'closed':
      // Do nothing
      break;
  }
};

const closeExplicit = (
  props: DeclarativeSnackbarProps,
  closeSnackbar: ProviderContext['closeSnackbar']
) => {
  const msgState = getState(props.snackKey);

  logDebug('closeExplicit()', msgState);

  switch (msgState.state) {
    case 'shown':
    case 'showing':
    case 'closing':
      msgState.state = 'closed-locked';
      cancelTimeout(msgState);
      setTimeoutLocked(props, closeSnackbar, msgState);
      break;
    case 'closed-locked':
    case 'closed':
      // Do nothing
      break;
  }
};

/**
 * When you want to show a snackbar message declaratively with a show (boolean)
 * prop, you can use this component. Snackbars are inherently imperative, so
 * this manages the enqueueing and closing of the snackbar for you.
 * @param props
 * @returns
 */
export const DeclarativeSnackbarMessage = (props: DeclarativeSnackbarProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (props.show) {
      show(props, enqueueSnackbar);
    } else {
      closeExplicit(props, closeSnackbar);
    }

    return () => {
      closeImplicit(props, closeSnackbar);
    };
  }, [closeSnackbar, enqueueSnackbar, props.show, props.snackKey, props]);
  return <></>;
};
