// Error types
export enum ErrorType {
  ERROR = 'Error',
  FIREBASE = 'FirebaseError',
  BAD_REQUEST = 'BadRequestError',
  UNAUTHORIZED = 'UnauthorizedError',
  FORBIDDEN = 'ForbiddenError',
  NOT_FOUND = 'NotFoundError',
  INTERNAL_SERVER = 'InternalServerError',
}

// Parse an exception
export function parseException(e: unknown) {
  if (e instanceof Error) return e;
  return new Error(String(e));
}
