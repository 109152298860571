import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import ShirtSizes from '@infinitus/types/shirt-sizes';
import Breadcrumbs from 'components/Breadcrumbs';
import { BreadcrumbsArray } from 'components/Breadcrumbs/BreadcrumbsTypes';
import MuiTheme from 'styles/MuiTheme';

interface PageProps {
  breadcrumbs?: BreadcrumbsArray;
  breadcrumbSize?: ShirtSizes;
  children?: ReactNode;
  headerContent?: ReactNode;
  // If you want something other than the first breadcrumb string
  metaTitle?: string;
}

export function Page({
  breadcrumbs,
  breadcrumbSize,
  children,
  headerContent,
  metaTitle,
}: PageProps) {
  function getpageTitle() {
    if (metaTitle) return metaTitle;
    if (breadcrumbs) return breadcrumbs[0].title;
    return '';
  }

  return (
    <>
      <Helmet title={`${getpageTitle()} | Infinitus Portal`} />
      <ThemeProvider theme={MuiTheme}>
        <Container
          disableGutters={true}
          maxWidth={false}
          // Doing this way since number isn't supported by maxWidth prop
          sx={{ maxWidth: 2000, paddingBottom: 6.5, scrollbarGutter: 'stable both-edges' }}
        >
          <Stack
            direction={{
              sm: 'column',
              md: 'row',
            }}
          >
            {breadcrumbs && (
              <Breadcrumbs crumbs={breadcrumbs} size={breadcrumbSize} sx={{ paddingY: 2 }} />
            )}
            {headerContent}
          </Stack>
          {children}
        </Container>
      </ThemeProvider>
    </>
  );
}
