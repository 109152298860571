import { useApolloClient } from '@apollo/client';
import { AppName } from '@infinitusai/api';
import { PropsWithChildren } from 'react';

import EventLoggingService from '@infinitus/services/EventLoggingService';

import AppConfigContext from './AppConfigContext';

interface Props {
  appName: AppName;
}

const AppConfigContextProvider = ({ appName, children }: PropsWithChildren<Props>) => {
  const apolloClient = useApolloClient();

  EventLoggingService.setAppName(appName);
  EventLoggingService.setApolloClient(apolloClient);

  return <AppConfigContext.Provider value={{ appName }}>{children}</AppConfigContext.Provider>;
};

export default AppConfigContextProvider;
