import { logUiEvent } from '@infinitus/hooks/useLogBuffer';

export interface SwitchEvent {
  eventValue: 'on' | 'off';
  label: string;
}

export function logSwitchEvent({ eventValue, label }: SwitchEvent) {
  logUiEvent({
    componentName: 'Switch',
    componentLabel: label,
    eventName: 'change',
    eventValue,
  });
}
