import * as React from 'react';

import { VoiceContext } from './VoiceContext';
import { VoiceContextInterface } from './types';

/**
 * The hook to use the voice context.
 */
export const useVoice = (): VoiceContextInterface =>
  React.useContext(VoiceContext) as VoiceContextInterface;
