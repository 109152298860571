import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface Props {
  label?: string;
  title: string;
}

function OrgLivenessFlag({ label, title }: Props) {
  const theme = useTheme();

  return (
    <Tooltip title={!!label ? label : ''}>
      <Typography
        sx={{
          background: theme.palette.primary.dark,
          borderRadius: theme.spacing(3),
          color: theme.palette.common.white,
          cursor: 'default',
          fontSize: 12,
          fontWeight: theme.typography.fontWeightMedium,
          lineHeight: 1,
          marginLeft: theme.spacing(1),
          paddingBlock: theme.spacing(0.5),
          paddingInline: theme.spacing(1.5),
          textTransform: 'uppercase',
        }}
        variant="caption"
      >
        {title}
      </Typography>
    </Tooltip>
  );
}

export default OrgLivenessFlag;
