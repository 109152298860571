import { alpha, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';

import logFileEvent, { FileEvent } from './FileInputLogs';

interface Props {
  acceptedFileTypes: string;
  isUploading: boolean;
  logFunction?: (logData: FileEvent) => void;
  // When multiple is not true, files will be managed in an array of size 1
  multiple?: boolean;
  onChange: (files: File[]) => unknown;
  text?: string;
}

interface Ref {
  resetSelectedFiles: () => unknown;
}

export type FileInputHandle = React.ElementRef<typeof FileInput>;

const DEFAULT_TEXT = 'Upload files';

const FileInput = forwardRef<Ref, Props>(
  (
    {
      acceptedFileTypes,
      isUploading,
      logFunction = logFileEvent,
      onChange,
      text = DEFAULT_TEXT,
      multiple = false,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      resetSelectedFiles() {
        setSelectedFiles([]);
        onChange([]);
      },
    }));

    const theme = useTheme();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

    useEffect(() => {
      logFunction({
        eventName: 'rejected',
        label: text,
        rejectedFiles: rejectedFiles,
      });
    }, [logFunction, rejectedFiles, text]);

    function onFilesAdded(addedFiles: File[]) {
      const newSelectedFiles = multiple
        ? Array.from(new Set([...selectedFiles, ...addedFiles]))
        : [...addedFiles];
      setSelectedFiles(newSelectedFiles);
      onChange(newSelectedFiles);
      setRejectedFiles([]);
      logFunction({
        eventName: 'add',
        files: addedFiles,
        label: text,
      });
    }

    function onFileRemoveClick(removedFile: File) {
      const newSelectedFiles = selectedFiles.filter((file) => file !== removedFile);
      setSelectedFiles(newSelectedFiles);
      onChange(newSelectedFiles);
      logFunction({
        eventName: 'remove',
        files: [removedFile],
        label: text,
      });
    }

    return (
      <Dropzone
        accept={acceptedFileTypes}
        maxFiles={!multiple ? 1 : undefined}
        onDrop={(acceptedFiles) => onFilesAdded(acceptedFiles)}
        onDropRejected={(rejectedFiles) => setRejectedFiles(rejectedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            sx={{
              backgroundColor: theme.palette.grey[800],
              borderRadius: theme.spacing(1),
              borderColor: theme.palette.grey[400],
              borderStyle: 'dashed',
              borderWidth: 2,
              padding: theme.spacing(2),
              position: 'relative',
            }}
          >
            {rejectedFiles?.length > 0 && (
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                <Stack spacing={1}>
                  {rejectedFiles.map((file) => (
                    <Alert severity="error">
                      <AlertTitle>Could not add {file.file.name}</AlertTitle>
                      {file.errors.map((err) => err.message).join(', ')}
                    </Alert>
                  ))}
                </Stack>
              </Box>
            )}
            <Box sx={{ cursor: 'pointer', textAlign: 'center' }}>
              <Typography>{text}</Typography>
              <Typography sx={{ fontSize: 12, opacity: '0.9' }}>{acceptedFileTypes}</Typography>
              <input {...getInputProps()} />
            </Box>
            {selectedFiles?.length > 0 && (
              <Box sx={{ overflowX: 'auto', marginTop: theme.spacing(2) }}>
                <Stack direction="row" spacing={2} sx={{ paddingTop: theme.spacing(1) }}>
                  {selectedFiles.map((file) => (
                    <Box
                      key={`${file.name}-${file.lastModified}`}
                      sx={{
                        alignItems: 'center',
                        background: theme.palette.grey[700],
                        display: 'flex',
                        flexShrink: 0,
                        flexBasis: '10rem',
                        minHeight: '6rem',
                        justifyContent: 'center',
                        overflow: 'visible',
                        padding: theme.spacing(1),
                        paddingRight: theme.spacing(4),
                        position: 'relative',
                      }}
                    >
                      {/* Prevent button click events from bubbling to Dropzone */}
                      <div onClick={(event) => event.stopPropagation()}>
                        <Typography sx={{ marginRight: theme.spacing(1) }} variant="subtitle2">
                          {file.name}
                        </Typography>
                        <IconButton
                          iconName={IconNames.DELETE}
                          onClick={() => onFileRemoveClick(file)}
                          size={ShirtSizes.SM}
                          sx={{
                            position: 'absolute',
                            top: theme.spacing(1),
                            right: theme.spacing(1),
                          }}
                          title="Remove file"
                        />
                      </div>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
            {/* Prevent all click events from bubbling to Dropzone while uploading */}
            {isUploading && (
              <div
                onClick={(event) => event.stopPropagation()}
                style={{
                  background: alpha(theme.palette.grey[900], 0.5),
                  cursor: 'wait',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <CircularProgress />
              </div>
            )}
          </Box>
        )}
      </Dropzone>
    );
  }
);

export default FileInput;
