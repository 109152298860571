// These are lowercase to match what MUI uses
// MUI doesn't have XS & XL
enum ShirtSizes {
  XS = 'extra_small',
  SM = 'small',
  MD = 'medium',
  LG = 'large',
  XL = 'extra_large',
}

export default ShirtSizes;
