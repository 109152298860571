import { useCallback, useEffect, useRef } from 'react';

// This is a subset of react-idle-timer types
// but we've removed the dependency
const ACTIVITY_EVENTS = [
  'blur',
  'click',
  'contextmenu',
  'dblclick',
  'DOMMouseScroll',
  'drag',
  'dragend',
  'dragenter',
  'dragleave',
  'dragover',
  'dragstart',
  'drop',
  'focus',
  'focusin',
  'focusout',
  'gotpointercapture',
  'input',
  'keydown',
  'keypress',
  'keyup',
  'lostpointercapture',
  'mousedown',
  'mousemove',
  'mouseover',
  'mouseout',
  'mouseup',
  'mousewheel',
  'MSPointerDown',
  'MSPointerMove',
  'open',
  'paste',
  'pointercancel',
  'pointerdown',
  'pointerenter',
  'pointerleave',
  'pointermove',
  'pointerout',
  'pointerover',
  'pointerup',
  'scroll',
  'select',
  'submit',
  'touchcancel',
  'touchend',
  'touchmove',
  'touchstart',
  'wheel',
];

interface Props {
  element?: Document | HTMLElement;
  eventHandler: EventListener;
  events?: string[];
}

/**
 * useEventListener will set up listeners for every provided event. If the list of events change,
 * event listeners will be removed and then added. The provided event handler will fire every time
 * an event from the provided list of events is fired.
 *
 * Note:
 * - provide a constant list of events to avoid rebinding events
 * - this hook should not cause any re-renders because its state is managed with refs, but we should
 *   be careful with passing in changing parameters to avoid rebinding event listeners.
 */
function useEventListeners({ events = ACTIVITY_EVENTS, element = document, eventHandler }: Props) {
  const eventsRef = useRef<string[]>([...events]);
  const elementRef = useRef<Node>(element);
  const eventsBound = useRef<boolean>(false);
  const eventHandlerRef = useRef<EventListener>(eventHandler);

  eventHandlerRef.current = eventHandler;
  const handleEvent: EventListener = useCallback((evt: Event) => {
    eventHandlerRef.current(evt);
  }, []);

  const bindEvents = useCallback(() => {
    if (!eventsBound.current) {
      eventsRef.current.forEach((e) => {
        elementRef.current.addEventListener(e, handleEvent, {
          capture: true,
          passive: true,
        });
      });
      eventsBound.current = true;
    }
  }, [handleEvent]);

  const unbindEvents = useCallback(() => {
    if (eventsBound.current) {
      eventsRef.current.forEach((e) => {
        elementRef.current.removeEventListener(e, handleEvent, {
          capture: true,
        });
      });
      eventsBound.current = false;
    }
  }, [handleEvent]);

  const jsonEvents = JSON.stringify(events);
  useEffect(() => {
    const newEvents = [...JSON.parse(jsonEvents)];
    eventsRef.current = newEvents;
    elementRef.current = element;
    bindEvents();

    return () => {
      unbindEvents();
    };
  }, [bindEvents, element, jsonEvents, unbindEvents]);
}

export default useEventListeners;
