import { IconButton as MuiIconButton, SxProps } from '@mui/material';
import { FocusEvent, MouseEvent, MouseEventHandler } from 'react';

import { ButtonEvent, logButtonEvent } from '@infinitus/components/Button';
import { Icon, IconColors, IconNames } from '@infinitus/components/Icon';
import { Tooltip } from '@infinitus/components/Tooltip';
import ShirtSizes from '@infinitus/types/shirt-sizes';

interface IconButtonProps {
  children?: React.ReactNode;
  color?: IconColors;
  disabled?: boolean;
  hideTooltip?: boolean;
  iconName?: IconNames;
  iconSize?: ShirtSizes;
  logFunction?: (logData: ButtonEvent) => void;
  onBlur?: (evt: FocusEvent) => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onFocus?: (evt: FocusEvent) => void;
  onMouseEnter?: (evt: MouseEvent) => void;
  onMouseLeave?: (evt: MouseEvent) => void;
  onRightClick?: (evt: MouseEvent) => void;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
  tabIndex?: number;
  title: string;
  tooltipDelay?: number;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
}

export function IconButton({
  color,
  disabled,
  children,
  hideTooltip = false,
  iconName,
  iconSize,
  logFunction = logButtonEvent,
  onBlur,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onRightClick,
  size = 'medium',
  sx,
  tabIndex,
  title,
  tooltipDelay = 300,
  tooltipPlacement,
}: IconButtonProps) {
  if (!!children && !!iconName) {
    console.error('IconButton must have EITHER `children` OR `iconName` but not both.');
  }

  function log(event: FocusEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) {
    logFunction({
      componentName: 'IconButton',
      event,
      meta: {
        icon: iconName,
      },
      text: title,
    });
  }

  function handleClick(evt: MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(evt);
    log(evt);
  }

  function handleFocus(evt: FocusEvent<HTMLButtonElement>) {
    if (onBlur && evt.type === 'blur') onBlur(evt);
    if (onFocus && evt.type === 'focus') onFocus(evt);
  }

  function handleRightClick(evt: MouseEvent<HTMLButtonElement>) {
    if (onRightClick) onRightClick(evt);
    log(evt);
  }

  function renderButton() {
    return (
      // Neither mouseEnter nor mouseLeave are
      // in MUI's IconButtonProps, but they're generically
      // available on HTMLButtonElement
      <MuiIconButton
        aria-label={title}
        disabled={disabled}
        onBlur={handleFocus}
        onClick={handleClick}
        onContextMenu={handleRightClick}
        onFocus={handleFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        sx={sx}
        tabIndex={tabIndex}
      >
        {!!iconName && <Icon color={color} name={iconName} size={iconSize} />}
        {!!children && children}
      </MuiIconButton>
    );
  }

  if (hideTooltip) return renderButton();

  return (
    <Tooltip enterDelay={tooltipDelay} placement={tooltipPlacement} title={title}>
      {/* Wrapper protects button from layouts applied by parents of Tooltip */}
      <div>{renderButton()}</div>
    </Tooltip>
  );
}
