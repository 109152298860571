import { AxiosResponse } from 'axios';

import { infinitusai } from '@infinitus/proto/pbjs';
import { OperatorPortalApi } from '@infinitus/utils/api';

export const createMegabyteBlob = (megabytes: number) => {
  const sizeInBytes = megabytes * 1_000_000;
  const uint8Array = new Uint8Array(sizeInBytes);
  return new Blob([uint8Array]);
};

export function calculateAverage(measurements: number[]) {
  const sumOfMeasurements = measurements.reduce((acc, m) => acc + m, 0);
  const averageMeasurement = sumOfMeasurements / measurements.length;
  return averageMeasurement;
}

function calculateStandardDeviation(measurements: number[]) {
  const n = measurements.length;
  const mean = measurements.reduce((acc, m) => acc + m, 0) / n;
  const variance = measurements.reduce((acc, m) => acc + Math.pow(m - mean, 2), 0) / n;
  return Math.sqrt(variance);
}

function getLatestResourceTimingByName(name: string) {
  const measureEntry = performance.getEntriesByName(name).at(-1);
  if (measureEntry === undefined) {
    // TODO: we should probably retry the request if we lost the measurement
    // n times before giving up. Skipped for now because this should be unlikely
    console.error(`failed to find performance entry for ${name}`);
    return;
  }
  if (!(measureEntry instanceof PerformanceResourceTiming)) {
    throw new Error('expected PerformanceResourceTiming entry');
  }

  // Extract the TTFB from the performance entry
  const ttfb = measureEntry.responseStart - measureEntry.requestStart;

  // Calculate the download time by subtracting TTFB from the total request duration
  const downloadTime = measureEntry.responseEnd - measureEntry.responseStart;

  return {
    ttfbInMs: ttfb,
    downloadTimeInMs: downloadTime,
    totalTimeInMs: measureEntry.duration, // Total time for the request
  };
}

export function infGetServerTime(res: AxiosResponse<any, any>) {
  // extract server-timing from headers: server-timing: infRequestDuration;dur=15.999794
  const serverTiming: string = res.headers['server-timing'];
  if (serverTiming) {
    const re = serverTiming.match(/infRequestDuration;dur=([0-9.]+)/);
    if (re) return +re[1];
  }
}

interface SingleSpeedTestResult {
  average: number;
  max: number;
  min: number;
  std: number;
  totalMeasurements: number;
}

export interface SpeedTestRunResults {
  downloadSpeedResults?: SingleSpeedTestResult;
  roundTripTimeResults?: SingleSpeedTestResult;
  uploadSpeedResults?: SingleSpeedTestResult;
}

export default class SpeedTestService {
  _api = new OperatorPortalApi();
  #isRunning = false;

  async runTest(): Promise<SpeedTestRunResults> {
    if (this.#isRunning) {
      throw new Error('speed test is already running');
    }

    let roundTripTimeResults: SingleSpeedTestResult | undefined;
    let downloadSpeedResults: SingleSpeedTestResult | undefined;
    let uploadSpeedResults: SingleSpeedTestResult | undefined;

    try {
      this.#isRunning = true;
      roundTripTimeResults = await this.measureRoundTripTime();
      downloadSpeedResults = await this.measureDownloadSpeed();
      uploadSpeedResults = await this.measureUploadSpeed(createMegabyteBlob(1));
    } catch (e: any) {
      console.error(e);
      throw new Error('failed to run Test');
    } finally {
      this.#isRunning = false;
    }

    return {
      roundTripTimeResults,
      downloadSpeedResults,
      uploadSpeedResults,
    };
  }

  private async measureRoundTripTime(): Promise<SingleSpeedTestResult> {
    const runs = 5;
    const measurements: number[] = [];
    for (let i = 0; i < runs; i++) {
      const performanceId = `run-${i}`;
      const response = await this._api.speedTestLatency(null, performanceId);

      // the request field on the axios response is of type XMLHttpRequest (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#instance_properties)
      // according to the axios docs: https://axios-http.com/docs/res_schema
      const responseUrl = (response.request as XMLHttpRequest).responseURL;
      const resourceTiming = getLatestResourceTimingByName(responseUrl);
      if (resourceTiming === undefined) {
        continue;
      }
      const { totalTimeInMs } = resourceTiming;

      measurements.push(totalTimeInMs);
    }

    return {
      totalMeasurements: measurements.length,
      average: calculateAverage(measurements),
      std: calculateStandardDeviation(measurements),
      min: Math.min(...measurements),
      max: Math.max(...measurements),
    };
  }

  private async measureDownloadSpeed(): Promise<SingleSpeedTestResult> {
    const req = new infinitusai.be.SpeedTestDownloadRequest({
      unit: infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit.MEGABYTES,
      value: 10,
    });

    const runs = 8;
    const measurements: number[] = [];
    for (let i = 0; i < runs; i++) {
      // We are using the performanceId as a unique identifier for the corresponding
      // performance entry for this request
      const performanceId = `run-${i}-value-${req.value}-unit-${req.unit}`;
      const response = await this._api.speedTestDownload(null, req, performanceId);

      // the request field on the axios response is of type XMLHttpRequest (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#instance_properties)
      // according to the axios docs: https://axios-http.com/docs/res_schema
      const responseUrl = (response.request as XMLHttpRequest).responseURL;
      const resourceTiming = getLatestResourceTimingByName(responseUrl);
      if (resourceTiming === undefined) {
        continue;
      }
      const { downloadTimeInMs } = resourceTiming;

      const blob = response.data;
      const bytes = blob.size;
      const bytesPerSec = bytes / (downloadTimeInMs / 1000);
      measurements.push(bytesPerSec);
    }

    return {
      totalMeasurements: measurements.length,
      average: calculateAverage(measurements),
      std: calculateStandardDeviation(measurements),
      min: Math.min(...measurements),
      max: Math.max(...measurements),
    };
  }

  private async measureUploadSpeed(data: Blob): Promise<SingleSpeedTestResult> {
    const runs = 5;
    const measurements: number[] = [];
    for (let i = 0; i < runs; i++) {
      // We are using the performanceId as a unique identifier for the corresponding
      // performance entry for this request
      const performanceId = `run-${i}`;
      const response = await this._api.speedTestUpload(null, data, performanceId);

      // the request field on the axios response is of type XMLHttpRequest (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#instance_properties)
      // according to the axios docs: https://axios-http.com/docs/res_schema
      const responseUrl = (response.request as XMLHttpRequest).responseURL;
      const resourceTiming = getLatestResourceTimingByName(responseUrl);
      if (resourceTiming === undefined) {
        continue;
      }
      const { totalTimeInMs } = resourceTiming;

      const bytes = data.size;
      // using total time to get true e2e experience measurement
      const bytesPerSec = bytes / (totalTimeInMs / 1000);
      measurements.push(bytesPerSec);
    }
    return {
      totalMeasurements: measurements.length,
      average: calculateAverage(measurements),
      std: calculateStandardDeviation(measurements),
      min: Math.min(...measurements),
      max: Math.max(...measurements),
    };
  }
}
