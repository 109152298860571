import { writeStorage } from '@rehooks/local-storage';

export const LOCAL_STORAGE_MUTE_NOTIFICATIONS_KEY = 'muteNotifications';

export const LOCAL_STORAGE_MANAGED_ORGS = 'myManagedOrgs';

export const LOCAL_STORAGE_SUGGESTIONS_VIEW_MODE_KEY = 'suggestionsMode';
export enum SuggestionsViewMode {
  CALL = 'call',
  REVIEW = 'review',
}
export function updateSuggestionMode(mode: SuggestionsViewMode) {
  writeStorage(LOCAL_STORAGE_SUGGESTIONS_VIEW_MODE_KEY, mode);
}

export enum LeftPanelViewMode {
  NORMAL = 'NORMAL',
  STICKY = 'STICKY',
}
export const LOCAL_STORAGE_LEFT_PANEL_CALL_MODE_KEY = 'leftPanelCallMode';
export function updateLeftPanelCallMode(mode: LeftPanelViewMode) {
  writeStorage(LOCAL_STORAGE_LEFT_PANEL_CALL_MODE_KEY, mode);
}
export const LOCAL_STORAGE_LEFT_PANEL_REVIEW_MODE_KEY = 'leftPanelReviewMode';
export function updateLeftPanelReviewMode(mode: LeftPanelViewMode) {
  writeStorage(LOCAL_STORAGE_LEFT_PANEL_REVIEW_MODE_KEY, mode);
}

export const LOCAL_STORAGE_NAV_STATE_KEY = 'adminNavState';

export function buildUnifiedQueueManagerTabParamsKey(queueUuid: string, tab: string) {
  return `unifiedQueueManager:params:${queueUuid}:${tab}`;
}
