import { gql } from '@apollo/client';

export const INCIDENT_TYPES = gql`
  query GetIncidentTypes($onlyVisible: Boolean = false) {
    incidentTypes(onlyVisible: $onlyVisible) {
      id
      category
      subcategory
      summary
      visible
      priority
    }
  }
`;

export const UPSERT_INCIDENT_TYPE = gql`
  mutation UpsertIncidentType($incidentTypeUUID: ID, $incidentTypeInput: IncidentTypeInput!) {
    upsertIncidentType(incidentTypeUUID: $incidentTypeUUID, incidentTypeInput: $incidentTypeInput) {
      id
      category
      subcategory
      summary
      visible
      priority
    }
  }
`;
