import { useAuth, UserOrg, UserType } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import sortBy from 'lodash/sortBy';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import Autocomplete from 'components/Autocomplete';
import { getOrgReadyPageUrl } from 'utils';

function orgGroupingRank(orgMembership: UserOrg) {
  const inProduction = orgMembership.live;
  const customerFacing = !orgMembership.internal;

  if (inProduction) {
    if (customerFacing) {
      return { rank: 1, label: 'Prod and Customer-facing' };
    }
    return { rank: 2, label: 'Prod but not Customer-facing' };
  }

  if (customerFacing) {
    return { rank: 3, label: 'Customer-facing but not Prod' };
  }
  return { rank: 4, label: 'Neither Prod nor Customer-facing' };
}

export function OrgSwitcher() {
  const { orgs, hasUserType } = useAuth();
  const { orgUuid, orgName } = useAppState();
  var navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const isAdmin = hasUserType([UserType.ADMIN]);

  const availableOrgs = orgs.map((key) => key.name);
  const sortedAvailableOrgs = sortBy(availableOrgs, [
    (availableOrg) => orgGroupingRank(orgs.filter((org) => org.name === availableOrg)[0]).rank,
    (availableOrg) => orgs.filter((org) => org.name === availableOrg)[0].displayName || '',
  ]);

  const choices = sortedAvailableOrgs.map((sortedOrg) => {
    return {
      label: (
        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
            }}
            variant="subtitle1"
          >
            {orgs.filter((org) => org.name === sortedOrg)[0].displayName}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: theme.spacing(1.5),
              marginBottom: '.25em',
              marginTop: '-.125em',
            }}
            variant="body2"
          >
            {sortedOrg}
          </Typography>
        </div>
      ),
      value: sortedOrg,
    };
  });

  function handleChange(selectedOrgName: string | null) {
    if (!selectedOrgName) {
      return;
    }

    if (selectedOrgName !== orgName) {
      const url = getOrgReadyPageUrl(selectedOrgName);
      navigate(url);
    }
  }

  const MyAutocomplete = (
    <Autocomplete
      blurOnSelect={true}
      choices={choices}
      disableClearable={true}
      getOptionLabel={(option) => {
        const orgName = typeof option === 'string' ? option : option.value;
        return orgs.filter((org) => org.name === orgName)[0]?.displayName || orgName;
      }}
      groupBy={(choice) =>
        orgGroupingRank(orgs.filter((org) => org.name === choice.value)[0]).label
      }
      onChange={handleChange}
      size={ShirtSizes.SM}
      sx={{ minWidth: 256 }}
      value={orgName}
      variant="outlined"
    />
  );

  if (!isAdmin) return MyAutocomplete;

  // Render copy to clipboard button for admins
  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      {MyAutocomplete}
      <IconButton
        iconName={IconNames.CONTENT_COPY}
        iconSize={ShirtSizes.XS}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          void navigator.clipboard.writeText(orgUuid);
          enqueueSnackbar('Copied org UUID', {
            variant: 'success',
          });
          event.stopPropagation();
        }}
        size="small"
        title="Copy org UUID to clipboard"
      />
    </Stack>
  );
}
