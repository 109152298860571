export const nexmoSdkVersions = ['nexmo-client', '@vonage/client-sdk'] as const;

// The "0" is to force anyone who set the previous 'nexmo-sdk-version' setting
// to use the legacy sdk.
export const NEXMO_SDK_VERSION_KEY = 'nexmo-sdk-version-0';

export type NexmoSdkVersion = (typeof nexmoSdkVersions)[number];

// Type guard to check if a string is a NexmoSdkVersion
function isNexmoSdkVersion(version: string): version is NexmoSdkVersion {
  return nexmoSdkVersions.includes(version as NexmoSdkVersion);
}

export function getNexmoSdkVersionDisplayName(version: NexmoSdkVersion) {
  switch (version) {
    case 'nexmo-client':
      return 'Legacy';
    case '@vonage/client-sdk':
      return 'New';
  }
}

// Get sdk version from local storage
export function getNexmoSdkVersion(): NexmoSdkVersion {
  const version = localStorage.getItem(NEXMO_SDK_VERSION_KEY);

  if (version !== null && isNexmoSdkVersion(version)) {
    return version as NexmoSdkVersion;
  }

  return '@vonage/client-sdk';
}

// Set sdk version in local storage
export function setNexmoSdkVersion(version: NexmoSdkVersion) {
  localStorage.setItem(NEXMO_SDK_VERSION_KEY, version);
}
