import Typography from '@mui/material/Typography';

import { Choice } from '@infinitus/types/ui-input-types';
import { GetIncidentTypes_incidentTypes as IncidentType } from 'types/gqlMapping';

export function incidentTypeToChoice(
  incidentType: IncidentType,
  {
    includeCategory,
    includeSubcategory,
  }: { includeCategory?: boolean; includeSubcategory?: boolean } = {}
): Choice<string, IncidentType> {
  const { category, subcategory, summary, id } = incidentType;
  const topTextParts = [];

  if (includeCategory) {
    topTextParts.push(category);
  }

  if (includeSubcategory && subcategory) {
    topTextParts.push(subcategory);
  }

  const topText = topTextParts.join(' - ');

  return {
    label: (
      <div>
        {topText && (
          <Typography
            sx={{
              fontSize: 12,
              marginTop: '.25em',
              marginBottom: '-.125em',
              opacity: 0.9,
            }}
            variant="body2"
          >
            {topText}
          </Typography>
        )}
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
          }}
          variant="subtitle1"
        >
          {summary}
        </Typography>
      </div>
    ),
    value: id,
    data: incidentType,
  };
}
