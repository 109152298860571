import * as React from 'react';

import { initialState, VoiceContextInterface } from './types';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <VoiceProvider>.');
};

const initialContext = {
  ...initialState,
  loadMediaDevices: stub,
  checkMicrophonePermission: stub,
  requestMicrophonePermission: stub,
  setMicrophoneDevice: stub,
  setSpeakerDevice: stub,
  playTone: stub,
  setAutoGainControl: stub,
  setEchoCancellation: stub,
  setNoiseSuppression: stub,
};

/**
 * The Voice Context
 */
export const VoiceContext = React.createContext<VoiceContextInterface>(initialContext);
