interface ConvertContentFunction<T> {
  (result: FileReader['result']): {
    convertedResult: T;
    isValidResult: boolean;
  };
}

export const convertToBase64: ConvertContentFunction<string> = (result: FileReader['result']) => {
  let base64Data = result?.toString() ?? '';
  // strip Data-URL declaration prefix
  base64Data = base64Data.substr(base64Data.indexOf(',') + 1);
  return {
    convertedResult: base64Data,
    isValidResult: !!base64Data,
  };
};

export async function readAsDataURL<T>(blob: Blob, convertResult: ConvertContentFunction<T>) {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = () => {
      reject(`Failed to read blob: ${reader.error}`);
    };

    reader.onload = async () => {
      // Base64 encode the raw wave blob for transmission
      const { convertedResult, isValidResult } = convertResult(reader.result);
      if (!isValidResult) {
        reject(`Failed to encode blob`);
      }
      resolve(convertedResult);
    };

    reader.readAsDataURL(blob);
  });
}
