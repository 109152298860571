import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon, IconNames } from '@infinitus/components/Icon';

interface NotificationRowProps {
  iconName: IconNames;
  title: string;
  value?: string | null;
}

export const NotificationRow = ({ iconName, title, value }: NotificationRowProps) => {
  if (!value) return null;
  return (
    <ListItem
      divider
      sx={{
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Icon name={iconName} />
        <Box>
          <Typography
            color="textSecondary"
            sx={{ lineHeight: 1, marginBlockEnd: 4, textTransform: 'none' }}
            variant="overline"
          >
            {title}
          </Typography>
          <Typography variant="body2">{value}</Typography>
        </Box>
      </Stack>
    </ListItem>
  );
};
