import { CohortInput, ExperimentInput } from '@infinitus/generated/frontend-common';

export const OPEN_REPLAY_ENABLED_COHORT_KEY = 'treatment';
export const OPEN_REPLAY_DISABLED_COHORT_KEY = 'control';

const DISABLED_BY_DEFAULT_DISTRIBUTION: CohortInput[] = [
  { cohortName: OPEN_REPLAY_ENABLED_COHORT_KEY, weight: 0 },
  { cohortName: OPEN_REPLAY_DISABLED_COHORT_KEY, weight: 1 },
];

export function openReplayParticipant(operatorEmail: string): ExperimentInput {
  const experimentName = 'open_replay';
  return {
    cohortDistribution: DISABLED_BY_DEFAULT_DISTRIBUTION,
    participantID: operatorEmail,
    experimentName,
  };
}
