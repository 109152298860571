import {
  createFilterOptions,
  FilterOptionsState,
  InputProps,
  SxProps,
  AutocompleteRenderOptionState,
  InputLabelProps,
} from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { MutableRefObject, useCallback, useMemo, HTMLAttributes, ReactNode } from 'react';
import innerText from 'react-innertext';

import ShirtSizes from '@infinitus/types/shirt-sizes';
import { Choice } from '@infinitus/types/ui-input-types';

import logAutocompleteEvent, { AutocompleteEvent } from './AutocompleteLogs';

type MaybeMultipleValue<TValue, TMultiple extends boolean | undefined> = TMultiple extends true
  ? Array<TValue> | null
  : TValue | '';

type ValueCallback<TInputValue extends string, TMultiple extends boolean | undefined> = (
  value: MaybeMultipleValue<TInputValue, TMultiple>
) => unknown;

interface Props<
  TInputValue extends string,
  TMultiple extends boolean | undefined,
  TChoiceData = unknown,
  TChoice = Choice<TInputValue, TChoiceData>
> {
  // Place cursor in text field
  autoFocus?: boolean;
  // Highlights first matched
  autoHighlight?: boolean;
  blurOnSelect?: boolean;
  choices: Array<TChoice>;
  customRenderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    choice: Choice<TInputValue, TChoiceData>,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  error?: boolean;
  filterOptions?: (options: TChoice[], state: FilterOptionsState<TChoice>) => TChoice[];
  freeSolo?: boolean;
  fullWidth?: boolean;
  getOptionLabel?: (option: string | TChoice) => string;
  groupBy?: (option: TChoice) => string;
  helperText?: string;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  InputProps?: InputProps;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  label?: string;
  loading?: boolean;
  loadingText?: string;
  logFunction?: (logData: AutocompleteEvent) => void;
  multiple?: TMultiple;
  name?: string;
  noOptionsText?: string;
  onBlur?: () => unknown;
  onChange: ValueCallback<TInputValue, TMultiple>;
  onFocus?: () => unknown;
  onInputChange?: (value: string) => unknown;
  placeholder?: string;
  required?: boolean;
  selectOnFocus?: boolean;
  showLabel?: boolean;
  size?: ShirtSizes.SM | ShirtSizes.MD;
  sx?: SxProps;
  testId?: string;
  useChoiceLabelForOptionLabel?: boolean;
  value: MaybeMultipleValue<TInputValue | string, TMultiple> | null;
  variant?: 'standard' | 'filled' | 'outlined';
}

function Autocomplete<
  TInputValue extends string,
  TMultiple extends boolean | undefined = false,
  TChoiceData = unknown
>({
  autoFocus = false,
  autoHighlight = true,
  blurOnSelect = false,
  choices,
  customRenderOption,
  disabled = false,
  disableClearable = false,
  disableCloseOnSelect = false,
  error,
  filterOptions,
  freeSolo,
  getOptionLabel,
  groupBy,
  helperText,
  id,
  InputLabelProps,
  InputProps,
  inputRef,
  label,
  loading = false,
  loadingText = 'Loading…',
  logFunction = logAutocompleteEvent,
  multiple = false,
  name,
  noOptionsText = 'No options',
  onBlur,
  onInputChange,
  onChange,
  onFocus,
  placeholder,
  required = false,
  showLabel = true,
  size,
  sx,
  useChoiceLabelForOptionLabel,
  value,
  testId,
  variant = 'outlined',
  ...props
}: Props<TInputValue, TMultiple, TChoiceData>) {
  const filter = filterOptions || createFilterOptions<Choice<TInputValue, TChoiceData>>();

  function handleBlur(optVal: TInputValue | '') {
    if (onBlur) {
      onBlur();
    }
    // Don't set the value when blurring
    if (!freeSolo) return;
    if (blurOnSelect) return; // when blurOnSelect is used, don't set value b/c e.target.value is not up to date

    if (!multiple) {
      // We need this handeChange to fire onBlur for some components with single select with freeSol (e.g. OutputAutocomplete)
      // This allows user to add "free_text" without having to click on `Add "free_text"`
      // TODO: Figure out how to automatically type narrow callbacks based on `multiple`
      (handleChange as ValueCallback<TInputValue, false>)(optVal);
    }
  }

  function handleChange(optVal: MaybeMultipleValue<TInputValue, TMultiple>) {
    onChange(optVal);
    logFunction({
      eventName: 'change',
      eventValue: optVal,
      label: label || '',
    });
  }

  function handleFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  function handleInputChange(_: unknown, value: string) {
    onInputChange?.(value);
  }

  const choiceOptionLabelFn = useCallback(
    (option: string | Choice<TInputValue, TChoiceData>) => {
      if (!choices.length) {
        return '';
      }

      const choice =
        typeof option !== 'string' ? option : choices.find(({ value }) => value === option);

      const label = choice
        ? choice.label ?? choice.value
        : typeof option === 'string'
        ? `${option}`
        : '';

      return innerText(label);
    },
    [choices]
  );

  const getOptionLabelFn = useChoiceLabelForOptionLabel ? choiceOptionLabelFn : getOptionLabel;

  const visibleOptions = useMemo(() => (loading ? [] : choices), [choices, loading]);

  return (
    <MuiAutocomplete
      {...props}
      autoHighlight={autoHighlight}
      blurOnSelect={blurOnSelect}
      // This feels like a hack, but drilling disableClearable
      // doesn't make the x show up
      componentsProps={
        !disableClearable
          ? {
              clearIndicator: {
                sx: {
                  visibility: 'visible',
                },
              },
            }
          : undefined
      }
      data-cy={testId}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (freeSolo) {
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              label: `Add "${inputValue}"`,
              value: inputValue as TInputValue,
            });
          }
        }
        return filtered;
      }}
      freeSolo={freeSolo}
      getOptionDisabled={(option) => option.disabled || false}
      getOptionLabel={getOptionLabelFn}
      groupBy={groupBy}
      isOptionEqualToValue={(o, v) => {
        // @ts-ignore - typescript no longer thinks we can do this comparison after upgrade to react 18
        if (o.label === v) {
          return true;
        }
        return typeof o.value === 'string' && (o.value as string) === v.toString();
      }}
      multiple={multiple}
      noOptionsText={loading ? loadingText : noOptionsText}
      onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
        handleBlur(target.value as TInputValue | '');
      }}
      onChange={(_, option) => {
        // Handle multiple
        if (Array.isArray(option)) {
          const optionValues = option
            .map((o) => {
              if (typeof o === 'object' && o?.value) {
                return o.value;
              } else if (typeof o === 'string') {
                return o;
              }
              return null;
            })
            .filter((value): value is TInputValue => value !== null);
          (handleChange as ValueCallback<TInputValue, true>)(optionValues);
          return;
        }

        // Handle single
        if (typeof option === 'object' && option?.value) {
          (handleChange as ValueCallback<TInputValue, false>)(option.value);
        } else if (!disableClearable && !option) {
          (handleChange as ValueCallback<TInputValue, false>)('');
        } else {
          console.error(
            `Autocomplete option '${JSON.stringify(option)}' must have the 'value' prop`
          );
        }
      }}
      onFocus={({ target }: React.FocusEvent<HTMLInputElement>) => {
        handleFocus();
      }}
      onInputChange={handleInputChange}
      options={visibleOptions}
      renderInput={(params) => {
        const { InputProps: paramsInputParams, ...restParams } = params;
        return (
          <TextField
            {...restParams}
            aria-label={label}
            autoFocus={autoFocus}
            data-cy={`${testId}_TextField`}
            disabled={disabled}
            error={error}
            helperText={helperText}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...paramsInputParams,
              ...InputProps,
            }}
            inputRef={inputRef}
            label={showLabel && label}
            name={name}
            placeholder={placeholder}
            required={required}
            size={size}
            variant={variant}
          />
        );
      }}
      renderOption={(props, option, state) => {
        // Handle customRenderOption
        if (customRenderOption) {
          return (
            <MenuItem
              {...props}
              disabled={option.disabled}
              key={`AutocompleteOption-${option.label}-${option.value}-${props.id}`}
            >
              {customRenderOption(props, option, state)}
            </MenuItem>
          );
        }

        return (
          <MenuItem
            {...props}
            disabled={option.disabled}
            key={`AutocompleteOption-${option.label}-${option.value}-${props.id}`}
          >
            {option.label}
          </MenuItem>
        );
      }}
      size={size}
      sx={{ ...sx }}
      value={Array.isArray(value) ? value : value?.toString() || ''}
    />
  );
}

export default Autocomplete;
