import { ApolloProvider, ApolloClient } from '@apollo/client';
import { AppName } from '@infinitusai/api';
import React, { PropsWithChildren } from 'react';

import { buildTransportSplitLink } from '@infinitus/apollo/links';

import operatorPortalCache from './cache';

const apolloClient = new ApolloClient({
  cache: operatorPortalCache,
  link: buildTransportSplitLink(AppName.OPERATOR),
  connectToDevTools: process.env.NODE_ENV === 'development',
});

export function InternalApolloProvider({ children }: PropsWithChildren<{}>) {
  if (process.env.NODE_ENV === 'test') {
    return <React.Fragment children={children} />;
  }
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
