import db from 'db/db';

import { SubscribeToTaskSubscription, GetTaskQuery } from 'generated/gql/graphql';
import { UUID } from 'types';

type TaskDoc = SubscribeToTaskSubscription['subscribeToTask']['task'] | GetTaskQuery['task'];

export interface RecentCallEntry {
  callUuid?: UUID;
  // ID is taskUuid + callUuid
  id: string;
  orgName: string;
  orgUuid: UUID;
  task?: TaskDoc | null;
  taskUuid: UUID;
  timestampMillis: number;
  uid: string | null;
}

const LENGTH_LIMIT = 20;

export function getRecentCallEntryId({
  taskUuid,
  callUuid,
}: Pick<RecentCallEntry, 'callUuid' | 'taskUuid'>): string {
  return `${taskUuid}${callUuid || ''}`;
}

export const buildRecentCallsQueryFunction = (uid: string) => () =>
  db.recentCalls
    .where('uid')
    .equals(uid)
    .limit(LENGTH_LIMIT)
    .reverse()
    .sortBy('timestampMillis')
    .catch((error) => {
      console.error('Failed to query IndexedDB.portal.recentCalls', error);
      return [];
    });

class RecentCallsService {
  // Manually cache entries logged from the current tab as a fallback for the ongoing IndexedDB glitch
  private _entriesCache = new Map<string, RecentCallEntry>();

  public async log(newEntry: Omit<RecentCallEntry, 'id' | 'timestampMillis'>): Promise<void> {
    const entry = {
      ...newEntry,
      id: getRecentCallEntryId(newEntry),
      timestampMillis: Date.now(),
    };

    this._entriesCache.set(entry.id, entry);
    await db.recentCalls.put(entry);
  }

  public getEntry(entryId: string): Promise<RecentCallEntry | undefined> {
    if (this._entriesCache.has(entryId)) {
      return Promise.resolve(this._entriesCache.get(entryId));
    }

    return db.recentCalls.get(entryId);
  }
}

const RecentCallsServiceInstance = new RecentCallsService();

export default RecentCallsServiceInstance as RecentCallsService;
