import '@infinitus/utils/whyDidYouRender';
import 'material-icons/iconfont/material-icons.css';
import { createRoot } from 'react-dom/client';

import App from './app';
import AppProviders from './app/Providers';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
