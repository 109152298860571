enum ThemeColorTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
  INHERIT = 'inherit',
}

export default ThemeColorTypes;
