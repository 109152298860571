import { SxProps, Theme } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

type SxConditional<T extends object> = [condition: boolean | any, sx: SxProps<T>];

// Recursive merge only nested objects, not arrays
function merge<T extends object>(target: T, source: T): T {
  const output = cloneDeep(target);
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      const targetValue = output[key];
      const sourceValue = source[key];
      // Only recursive merge if both exist and both are objects
      if (
        targetValue &&
        typeof targetValue === 'object' &&
        sourceValue &&
        typeof sourceValue === 'object'
      ) {
        output[key] = merge(targetValue, sourceValue);
      } else {
        output[key] = sourceValue;
      }
    }
  }

  return output;
}

export default function useConditionalSx<T extends object = Theme>(
  baseSx: SxProps<T>,
  ...conditionals: [SxConditional<T>, ...Array<SxConditional<T>>]
) {
  const _conditions = conditionals.map(([condition]) => !!condition).join();

  return useMemo(() => {
    return conditionals.reduce(
      (mergedSx, [condition, conditionSx]) =>
        !!condition ? merge(mergedSx ?? {}, conditionSx ?? {}) : mergedSx,
      baseSx
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_conditions]);
}
