import { infinitusai } from '@infinitus/proto/pbjs';

export enum HeartbeatMessageType {
  CALL_PAGE = 'CALL_PAGE',
  OTHER_PAGE = 'OTHER_PAGE',
  READY_PAGE = 'READY_PAGE',
  TASK_LIST_PAGE = 'TASK_LIST_PAGE',
  ADMIN_PORTAL = 'ADMIN_PORTAL',
  TASK_PAGE = 'TASK_PAGE',
  FASTTRACK_SOFT_PHONE = 'FASTTRACK_SOFT_PHONE',
}

export function messageTypeToPageTypeProto(messageType: HeartbeatMessageType) {
  switch (messageType) {
    case HeartbeatMessageType.CALL_PAGE:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.CALL_PAGE;
    case HeartbeatMessageType.TASK_PAGE:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.TASK_PAGE;
    case HeartbeatMessageType.READY_PAGE:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.READY_PAGE;
    case HeartbeatMessageType.TASK_LIST_PAGE:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.TASK_LIST_PAGE;
    case HeartbeatMessageType.ADMIN_PORTAL:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.ADMIN_PORTAL;
    case HeartbeatMessageType.FASTTRACK_SOFT_PHONE:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.FASTTRACK_SOFTPHONE_PAGE;
    case HeartbeatMessageType.OTHER_PAGE:
    default:
      return infinitusai.be.HeartbeatMessageFromClient.PageType.UNKNOWN_PAGE;
  }
}
