import { createContext } from 'react';

import { OperatorOfflineReason, OperatorOnlineStatus } from 'generated/gql/graphql';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <OperatorOnlineStatusProvider>.');
};

interface OperatorStatus {
  markOperatorOffline: (reason: OperatorOfflineReason) => void;
  markOperatorOnline: () => void;
  operatorOfflineReason: OperatorOfflineReason;
  operatorOnlineStatus: OperatorOnlineStatus | undefined;
}

const initialOperatorStatus = {
  operatorOfflineReason: OperatorOfflineReason.UNKNOWN,
  operatorOnlineStatus: OperatorOnlineStatus.UNKNOWN,
  markOperatorOnline: stub,
  markOperatorOffline: stub,
};

const OperatorOnlineStatusContext = createContext<OperatorStatus>(initialOperatorStatus);

export default OperatorOnlineStatusContext;
