/**
 * This file shows an example of how an experiment participant is defined on the frontend.
 * The experiment participant must define a unique participantID and the experimentName.
 * The participantID must be unique for each participant in the experiment.
 * The experimentName must be the same for all participants in the experiment.
 * You may also define an initial cohort distribution for the experiment. This can
 * be altered through the admin portal after the fact. The cohort distribution defined here
 * is only used for the initial creation of the experiment
 */

import { memoize } from 'lodash';

import { ExperimentInput } from '@infinitus/generated/frontend-common';
import { UUID } from '@infinitus/types';

interface ExampleParticipantParticipantArgs {
  callUuid: UUID;
  orgUuid: UUID;
  taskUuid: UUID;
}
function exampleParticipantParticipant({
  orgUuid,
  taskUuid,
  callUuid,
}: ExampleParticipantParticipantArgs): ExperimentInput {
  const experimentName = 'example_participant';
  const participantID = `orgUuid:${orgUuid}:taskUuid:${taskUuid}:callUuid:${callUuid}`;
  return {
    participantID,
    experimentName,
  };
}

export const buildExampleParticipantParticipant = memoize(
  exampleParticipantParticipant,
  ({ orgUuid, taskUuid, callUuid }) => {
    return `orgUuid:${orgUuid}:taskUuid:${taskUuid}:callUuid:${callUuid}`;
  }
);
