import { SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonEvent } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import VisualVariant from '@infinitus/types/visual-variant-types';

// This extends ButtonProps with a few changes:
// + Mandatory "to" prop
// + Optional "onClick" prop
// + Optional "openInNewTab" prop
interface LinkButtonProps {
  autoFocus?: boolean;
  color?: ThemeColorTypes;
  disabled?: boolean;
  endIconName?: IconNames;
  formId?: string;
  fullWidth?: boolean;
  logFunction?: (logData: ButtonEvent) => void;
  onClick?: Function;
  openInNewTab?: boolean;
  size?: ShirtSizes.SM | ShirtSizes.MD | ShirtSizes.LG;
  startIcon?: React.ReactNode;
  startIconName?: IconNames;
  sx?: SxProps;
  text: string;
  to: string;
  // HTML types allowed on button
  type?: 'submit' | 'reset' | 'button';
  variant?: VisualVariant;
}

export function LinkButton({
  openInNewTab,
  startIconName,
  text,
  to,
  variant,
  ...etc
}: LinkButtonProps) {
  const navigate = useNavigate();

  function handleClick() {
    if (openInNewTab) {
      window.open(to);
    } else {
      navigate(to);
    }
  }

  return (
    <Button
      {...etc}
      onClick={handleClick}
      startIconName={startIconName}
      text={text}
      variant={variant}
    />
  );
}
