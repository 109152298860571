import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

import ConfirmContext from './ConfirmContext';
import { ConfirmOptions, initialConfirmState } from './ConfirmTypes';

interface Props {
  children?: React.ReactNode;
}

/**
 * ```jsx
 * <ConfirmProvider>
 *   <MyApp />
 * </ConfirmProvider>
 * ```
 *
 * Provides the ConfirmContext to its child components.
 */
function ConfirmProvider({ children }: Props): JSX.Element {
  // Initialize confirm state
  const [confirmState, setConfirmState] = React.useState(initialConfirmState);

  const handleClose = () => {
    if (confirmState.onCancel) confirmState.onCancel();
    setConfirmState(initialConfirmState);
  };

  const handleConfirm = () => {
    if (confirmState.onConfirm) confirmState.onConfirm();
    setConfirmState(initialConfirmState);
  };

  const confirm = React.useCallback((options: ConfirmOptions): void => {
    setConfirmState({ open: true, ...options });
  }, []);

  return (
    <ConfirmContext.Provider value={confirm}>
      <React.Fragment>
        {children}
        <Dialog
          aria-describedby="confirm-dialog-description"
          aria-labelledby="confirm-dialog-title"
          fullWidth
          onClose={handleClose}
          open={confirmState.open}
        >
          <DialogTitle id="confirm-dialog-title">{confirmState.title}</DialogTitle>
          {confirmState.description ? (
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                {confirmState.description}
              </DialogContentText>
              {confirmState.footnote ? (
                <DialogContentText id="confirm-dialog-description" sx={{ mt: 1 }}>
                  {confirmState.footnote}
                </DialogContentText>
              ) : null}
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button onClick={handleClose} size="large">
              {confirmState.cancelText || 'Cancel'}
            </Button>
            <Button
              autoFocus
              disabled={confirmState.isLoading}
              onClick={handleConfirm}
              size="large"
              variant="contained"
            >
              {confirmState.confirmText || 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </ConfirmContext.Provider>
  );
}

export default ConfirmProvider;
