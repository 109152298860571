import { SxProps } from '@mui/material';
import MuiBreadCrumbs from '@mui/material/Breadcrumbs';

import { Icon, IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import { BreadcrumbsArray } from 'components/Breadcrumbs/BreadcrumbsTypes';

import useStyles from './BreadcrumbsStyles';
import Breadcrumb from './subcomponents/Breadcrumb';

interface BreadcrumbProps {
  crumbs: BreadcrumbsArray;
  size?: ShirtSizes;
  sx?: SxProps;
}

function Breadcrumbs({ crumbs, size, sx }: BreadcrumbProps) {
  const classes = useStyles();
  return (
    <MuiBreadCrumbs
      aria-label="breadcrumb"
      separator={<Icon name={IconNames.NAVIGATE_NEXT} />}
      sx={{ ...classes.breadcrumbs, ...sx }}
    >
      {crumbs.map((crumb, idx) => (
        <Breadcrumb {...crumb} index={idx} key={`crumb_${crumb.title}`} size={size} />
      ))}
    </MuiBreadCrumbs>
  );
}

export default Breadcrumbs;
