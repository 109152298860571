import { useAppState } from '@infinitusai/shared';

import useCallParams from '@infinitus/hooks/useCallParams';

function useGetIdsFromUrl() {
  const { org, orgName, orgUuid } = useAppState();
  const { taskUuid, callUuid } = useCallParams();
  if (orgName && org && Object.keys(org).length === 0) {
    console.error(
      `useGetIdsFromUrl attempted to retrieve orgInfo for org '${orgName}' but no data was available`
    );
  }

  return {
    callUuid,
    displayName: org?.displayName,
    orgName,
    orgUuid: orgUuid || '',
    taskUuid,
  };
}

export default useGetIdsFromUrl;
