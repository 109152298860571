import { useCallback } from 'react';

import { callStateVar } from 'apollo/cache';
import { SubscribeToCallSubscription } from 'generated/gql/graphql';

export default function useCallState() {
  const updateCallState = useCallback(
    (callState: SubscribeToCallSubscription['subscribeToCall'] | null) => {
      callStateVar(callState);
    },
    []
  );

  const getCallState = useCallback(() => {
    return callStateVar();
  }, []);

  return {
    updateCallState,
    getCallState,
  };
}
