import { useContext } from 'react';

import AppConfigContext from './AppConfigContext';

export { default as AppConfigContextProvider } from './AppConfigContextProvider';

const useAppConfig = () => {
  const context = useContext(AppConfigContext);
  if (!context) {
    throw new Error('useAppConfig must be used within AppConfigProvider');
  }
  return context;
};

export default useAppConfig;
