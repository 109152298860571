import { gql } from '@apollo/client';

export const OutputFieldFragment = gql`
  fragment OutputFieldFragment on OutputField {
    name
    label
    hideByDefault
    reviewOptional
    requiredForCallCompletion
    type {
      ... on Scalar {
        type
      }
      ... on Enum {
        values
      }
      ... on Enums {
        values
      }
      ... on MaybeAutocompleteString {
        hints
      }
    }
  }
`;

export const PrimarySuggestionFragment = gql`
  fragment PrimarySuggestionFragment on PrimarySuggestion {
    name
    utterance
    attributes {
      isCallSpecific
      stopRecording
      unpromptable
    }
    outputFields {
      ...OutputFieldFragment
    }
    followupSuggestions {
      name
      utterance
      attributes {
        isCallSpecific
        stopRecording
        unpromptable
      }
    }
  }
  ${OutputFieldFragment}
`;

export const SuggestionsFragment = gql`
  fragment SuggestionsFragment on FlatCategory {
    suggestions {
      ...PrimarySuggestionFragment
    }
  }
  ${PrimarySuggestionFragment}
`;
