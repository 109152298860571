import * as React from 'react';

import ConfirmContext from './ConfirmContext';

/**
 * ```js
 * const confirm = useConfirm();
 * ```
 *
 * Use the `useConfirm` hook in components to render a confirmation dialog.
 */
const useConfirm = () => React.useContext(ConfirmContext);

export default useConfirm;
