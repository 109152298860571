import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

// All search params supported by the call page should be defined here
// To extend, update this type and the toCallSearchParams and toURLSearchParams functions
export type CallSearchParams = {
  routedByJoinCallMessage: boolean;
};

export type SetCallSearchParams = (updater: (prev: CallSearchParams) => CallSearchParams) => void;

function toCallSearchParams(urlSearchParams: URLSearchParams): CallSearchParams {
  // Initialize with default values
  const results: CallSearchParams = {
    routedByJoinCallMessage: false,
  };
  for (const [key, value] of urlSearchParams.entries()) {
    switch (key) {
      case 'routedByJoinCallMessage':
        results.routedByJoinCallMessage = value === 'true';
        break;
      default:
        console.warn(
          `Unknown call search param ${key} with value ${value}. Update useCallParams.tsx to support this param.`
        );
    }
  }
  return results;
}

export function toURLSearchParams(params: CallSearchParams): URLSearchParams {
  // Update this object to perform any necessary type conversions
  const results = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    switch (key) {
      case 'routedByJoinCallMessage':
        if (value) {
          results.set(key, value.toString());
        }
        break;
      default:
        console.warn(
          `Unknown call search param ${key} with value ${value}. Update useCallParams.tsx to support this param.`
        );
    }
  }
  return results;
}

/**
 * a hook to get well typed CallSearchParams from the url and a way to update them
 * @returns [CallSearchParams, SetCallSearchParams] where SetCallSearchParams is a function
 * that expects a param update function (prev: CallSearchParams) => CallSearchParams
 */
export function useCallSearchParams(): [CallSearchParams, SetCallSearchParams] {
  const [searchParam, setURLSearchParams] = useSearchParams();
  const searchParams = toCallSearchParams(searchParam);

  const setSearchParams = useCallback(
    (paramUpdater: (prev: CallSearchParams) => CallSearchParams) => {
      setURLSearchParams((prevRaw) => {
        const prev = toCallSearchParams(prevRaw);
        const updated = paramUpdater(prev);
        return toURLSearchParams(updated);
      });
    },
    [setURLSearchParams]
  );

  return [searchParams, setSearchParams];
}

export default useCallSearchParams;
