// Utility to applying typing to Object.keys (they are strings by default)
export const TypedObjectKeys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
export const TypedObjectValues = Object.values as <T>(o: T) => Extract<T[keyof T], string>[];
export const TypedObjectEntries = Object.entries as <T>(
  o: T
) => [Extract<keyof T, string>, T[keyof T]][];

// General purpose Enum type
export interface Enum {
  // Value name -> integral value. The string indexer must be more permissive than the number
  // indexer, but in an actual enum, this will always return a number.
  [id: string]: number | string;
  // Integral value -> value name.
  [id: number]: string;
}

// Merge two interfaces, removing any members on T that exists in R and then adding R to the resulting type.
// ref: https://www.bergqvist.it/blog/2020/6/26/extending-theme-material-ui-with-typescript
export type Modify<T, R> = Omit<T, keyof R> & R;

// Get nested keys of an object. the paths will be dot separated
// ref : https://stackoverflow.com/questions/65332597/typescript-is-there-a-recursive-keyof/65333050#65333050
export type RecursiveKeyOf<TObj extends object> =
  // Create an object type from `TObj`, where all the individual
  // properties are mapped to a string type if the value is not an object
  // or union of string types containing the current and descendant
  // possibilities when it's an object type.
  {
    // Does this for every property in `TObj` that is a string or number
    [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>;
  }[keyof TObj & (string | number)]; // for every string or number property name // Now flatten the object's property types to a final union type

// This type does the same as `RecursiveKeyOf`, but since
// we're handling nested properties at this point, it creates
// the strings for property access and index access
// ref : https://stackoverflow.com/questions/65332597/typescript-is-there-a-recursive-keyof/65333050#65333050
type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >;
}[keyof TObj & (string | number)];

// ref : https://stackoverflow.com/questions/65332597/typescript-is-there-a-recursive-keyof/65333050#65333050
type RecursiveKeyOfHandleValue<TValue, Text extends string> =
  // If the value is an array then ignore it, providing back
  // only the passed in text
  TValue extends any[]
    ? Text
    : // If the value is an object...
    TValue extends object
    ? // Then...
      // 1. Return the current property name as a string
      | Text
        // 2. Return any nested property text concatenated to this text
        | `${Text}${RecursiveKeyOfInner<TValue>}`
    : // Else, only return the current text as a string
      Text;

// Apply NonNullable to all fields of a type
export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
