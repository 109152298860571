import { useContext } from 'react';

import NexmoContext, { NexmoClientWrapper } from './NexmoContext';

function useNexmoClient(): NexmoClientWrapper {
  const nexmoClient = useContext(NexmoContext);
  if (!nexmoClient) {
    throw new Error('Missing <NexmoClientProvider>');
  }
  return nexmoClient;
}

export default useNexmoClient;
