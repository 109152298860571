import { AppName } from '@infinitusai/api';

import { ClientEvent } from '@infinitus/generated/frontend-common';

export enum WorkerMessageType {
  FEATURES = 'features',
  LOG = 'log',
}

export interface LogEventMessage {
  appName: AppName;
  event: ClientEvent;
  type: WorkerMessageType.LOG;
}

export interface FeaturesMessage {
  type: WorkerMessageType.FEATURES;
}

export type WorkerMessage = LogEventMessage | FeaturesMessage;

export enum WorkerFeatures {
  LOGGING = 'LOGGING',
}

export enum LoggingTransport {
  POST_MESSAGE = 'postMessage',
  INDEXED_DB = 'indexedDd',
}

interface LoggingFeatures {
  transport: LoggingTransport;
}

interface FeaturesConfig {
  [WorkerFeatures.LOGGING]: LoggingFeatures;
}

export interface FeaturesResponse {
  features: WorkerFeatures[];
  featuresConfig: Partial<FeaturesConfig>;
  type: WorkerMessageType.FEATURES;
}

export type WorkerResponse = FeaturesResponse;
