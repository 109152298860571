import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useId from '@mui/material/utils/useId';
import { NavItemData, NavItemList } from 'layout/AppNav/types';
import { Fragment, MouseEventHandler, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Icon, IconNames } from '@infinitus/components/Icon';
import { Tooltip } from '@infinitus/components/Tooltip';
import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import ShirtSizes from '@infinitus/types/shirt-sizes';

import {
  activeLabelSx,
  activeLinkSx,
  baseLabelSx,
  baseLinkSx,
  childLinkSx,
  expandedChildLinkSx,
  expandedLabelSx,
  parentCategorySx,
} from './NavItemStyles';

interface BaseProps {
  isExpanded: boolean;
  item: NavItemList[number];
  splat: string | undefined;
}

interface ParentItemProps extends BaseProps {
  isChild?: false;
  isOpen?: boolean;
  onOpenClick: (id: string, isOpen: boolean) => unknown;
}

interface ChildItemProps extends BaseProps {
  isChild: true;
  item: NavItemData;
}

type Props = ParentItemProps | ChildItemProps;

function NavItem({ isChild, isExpanded, item, splat, ...props }: Props) {
  const isOpen = !isChild && 'isOpen' in props ? props.isOpen : false;
  const onOpenClick = !isChild && 'onOpenClick' in props ? props.onOpenClick : undefined;
  const { iconName, iconOutlined, title } = item;
  const href = 'href' in item ? item.href : item.children[0].href;
  const children = !isChild && 'children' in item ? item.children : undefined;
  const childIds = children?.map(({ id }) => id);
  const keyId = useId();

  const isActive = childIds
    ? !isOpen && childIds.some((id) => splat?.includes(id))
    : splat === item.id;

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      if (children) {
        event.stopPropagation();
        event.preventDefault();
        onOpenClick?.(item.id, !isOpen);
      }
    },
    [children, isOpen, item.id, onOpenClick]
  );

  const linkSx = useConditionalSx(
    baseLinkSx,
    [isActive, activeLinkSx],
    [isChild, childLinkSx],
    [isExpanded && isChild, expandedChildLinkSx],
    [children, parentCategorySx]
  );

  const labelSx = useConditionalSx(
    baseLabelSx,
    [isActive, activeLabelSx],
    [isExpanded, expandedLabelSx]
  );

  const iconStyle = {
    marginBlockStart: 0.75,
    marginInlineStart: isChild ? 0.25 : undefined,
  };

  return (
    <Fragment key={keyId}>
      <MuiLink component={RouterLink} onClick={handleClick} sx={linkSx} to={href} underline="none">
        <div>
          {isExpanded ? (
            <Icon
              name={iconName}
              outlined={iconOutlined}
              size={isChild ? ShirtSizes.SM : ShirtSizes.MD}
              sx={iconStyle}
            />
          ) : (
            <Tooltip placement="right" title={title}>
              <Icon
                name={iconName}
                outlined={iconOutlined}
                size={isChild ? ShirtSizes.SM : ShirtSizes.MD}
                sx={iconStyle}
              />
            </Tooltip>
          )}
        </div>
        <Typography sx={labelSx} variant={isChild ? 'body2' : 'body1'}>
          {title}
        </Typography>
        {children && (
          <Icon
            name={isOpen ? IconNames.EXPAND_LESS : IconNames.EXPAND_MORE}
            size={ShirtSizes.SM}
            sx={{ marginLeft: 'auto' }}
          />
        )}
      </MuiLink>
      {children && (
        <Collapse in={isOpen}>
          {children.map((item, idx) => (
            <NavItem
              isChild
              isExpanded={isExpanded}
              item={item}
              key={`${item.title}-${idx}`}
              splat={splat}
            />
          ))}
        </Collapse>
      )}
    </Fragment>
  );
}

export default NavItem;
