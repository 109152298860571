import { makeVar } from '@apollo/client';
import { useCallback } from 'react';

import useApi from '@infinitus/hooks/useApi';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { infinitusai } from '@infinitus/proto/pbjs';
import { OperatorPortalApi } from '@infinitus/utils/api';
import { ClientEventType } from 'generated/gql/graphql';
import { UUID } from 'types';

export const getNotificationType = (
  notification: infinitusai.be.Notification
): infinitusai.be.NotificationType => {
  const { begForOperatorNotification, requeueNotification, genericNotification } =
    notification.payload ?? {};
  if (begForOperatorNotification) {
    return infinitusai.be.NotificationType.NOTIFICATION_TYPE_BEG_FOR_OPERATOR;
  } else if (requeueNotification) {
    return infinitusai.be.NotificationType.NOTIFICATION_TYPE_REQUEUE_TASK;
  } else if (genericNotification) {
    return infinitusai.be.NotificationType.NOTIFICATION_TYPE_GENERIC;
  } else {
    return infinitusai.be.NotificationType.NOTIFICATION_TYPE_UNKNOWN;
  }
};

// Hits the backend API to report a click, and logs the breadcrumb (with human-readable metadata)
const reportClickToBackendInternal = async (
  api: OperatorPortalApi,
  orgUuid: UUID,
  body: infinitusai.be.NotificationReportClickRequest
) => {
  // Uses human readable enum strings
  const metadata = {
    notificationType:
      infinitusai.be.NotificationType[
        body.notificationType ?? infinitusai.be.NotificationType.NOTIFICATION_TYPE_UNKNOWN
      ],
    action:
      infinitusai.be.NotificationAction[
        body.action ?? infinitusai.be.NotificationAction.NOTIFICATION_ACTION_UNKNOWN
      ],
  };
  void logEventToBigQuery({
    message: `${metadata.notificationType} notification action ${metadata.action} taken`,
    clientEventType: ClientEventType.NOTIFICATION,
    meta: {
      ...body,
      ...metadata,
    },
  });
  try {
    await api.notificationReportClick(null, orgUuid, body);
  } catch (e: any) {
    console.error(`Failed to report notification user action: ${e?.response?.data || e.message}`);
  }
};

// This function needs to be available outside of the hook as it's called by the Notifications menu
// itself, and therefore doesn't have the context of an individual notification.
export const dismissAllNotifications = (
  api: OperatorPortalApi,
  notifications: infinitusai.be.Notification[]
) => {
  notifications.forEach((notification) => {
    const { uuid: notificationUuid, userEmail, orgUuid } = notification;
    const notificationType = getNotificationType(notification);
    const body = infinitusai.be.NotificationReportClickRequest.fromObject({
      notificationUuid,
      userEmail,
      notificationType,
      action: infinitusai.be.NotificationAction.NOTIFICATION_ACTION_DISMISSED,
    });
    void reportClickToBackendInternal(api, orgUuid, body);
  });
};

export const useNotification = (
  notificationType: infinitusai.be.NotificationType,
  notificationUuid: UUID,
  orgUuid: UUID,
  userEmail: string
) => {
  const { api } = useApi();
  const { closeSnackbar } = useSnackbar();
  const { removeNotificationsFromBell } = useNotificationBell();

  const reportClickToBackend = useCallback(
    (action: infinitusai.be.NotificationAction) => {
      const body = infinitusai.be.NotificationReportClickRequest.fromObject({
        notificationUuid,
        userEmail,
        notificationType,
        action,
      });
      void reportClickToBackendInternal(api, orgUuid, body);
    },
    [api, notificationType, notificationUuid, orgUuid, userEmail]
  );

  const handleDismiss = useCallback(() => {
    reportClickToBackend(infinitusai.be.NotificationAction.NOTIFICATION_ACTION_DISMISSED);
    closeSnackbar(notificationUuid);
    removeNotificationsFromBell([notificationUuid]);
  }, [reportClickToBackend, closeSnackbar, notificationUuid, removeNotificationsFromBell]);

  const handleHide = useCallback(() => {
    reportClickToBackend(infinitusai.be.NotificationAction.NOTIFICATION_ACTION_HIDDEN);
    closeSnackbar(notificationUuid);
  }, [closeSnackbar, notificationUuid, reportClickToBackend]);

  return {
    reportClickToBackend,
    handleDismiss,
    handleHide,
  };
};

// these are the received notifications pending actions.
export const notificationsVar = makeVar<infinitusai.be.Notification[]>([]);

export function useNotificationBell() {
  const getNotificationFromBell = useCallback((notificationUuid: string) => {
    const notifications = notificationsVar();
    return notifications.find((n) => n.uuid === notificationUuid);
  }, []);

  const addNotificationsToBell = useCallback((notifications: infinitusai.be.Notification[]) => {
    const bellNotifications = notificationsVar();
    bellNotifications.unshift(...notifications);
    bellNotifications.sort((a, b) => Number(b.enqueuedAtMillis) - Number(a.enqueuedAtMillis));
    notificationsVar([...bellNotifications]);
  }, []);

  const removeNotificationsFromBell = useCallback((notificationUuids: string[]) => {
    const prevBellNotifications = notificationsVar();
    const newBellNotifications = prevBellNotifications.filter(
      (bellNotif) => !notificationUuids.some((uuid) => uuid === bellNotif.uuid)
    );
    notificationsVar([...newBellNotifications]);
  }, []);

  return {
    getNotificationFromBell,
    addNotificationsToBell,
    removeNotificationsFromBell,
  };
}
