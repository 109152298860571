import { useEffect, useRef } from 'react';

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  // Runs when `value` changes
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return when `ref` changes;
  return ref.current;
}
export default usePrevious;
