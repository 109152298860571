import { useAuth, UserType } from '@infinitusai/auth';
import { useAppState } from '@infinitusai/shared';
import { useOperatorPortalFeatureEnabled, OperatorPortalFeature } from '@infinitusai/shared';

export const useCanShowTaskList = () => {
  const { hasUserType } = useAuth();
  const { org, orgName, orgUuid } = useAppState();
  const showTasksForNonAdmins = useOperatorPortalFeatureEnabled(
    OperatorPortalFeature.SHOW_TASKS_FOR_NON_ADMINS
  );

  if (!orgName || !orgUuid) {
    return false;
  }

  const isAdmin = hasUserType([UserType.ADMIN]);

  // Technically orgs badged as "Test" should be subset of pilot orgs but are labelled
  // as their own category in the customer portal UI.
  // https://infinitusai.atlassian.net/browse/IST-651?focusedCommentId=17709
  const isPilotOrg = !org?.isInternal && !org?.isLive;

  return isAdmin || isPilotOrg || showTasksForNonAdmins;
};
