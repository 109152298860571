import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import throttle from 'lodash/throttle';
import * as React from 'react';

import { useVoice } from '../provider/useVoice';

const Keyframes = styled('div')({
  '@keyframes wave': {
    '0%': {
      height: '4px',
    },
    '100%': {
      height: '15px',
    },
  },
  display: 'flex',
  width: '40px',
  height: '40px',
  padding: '3px',
  borderRadius: '100px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#4285f4',
});

interface Props {
  enabled: boolean;
  onAudioDetected?: () => void;
}

function AudioDetection({ enabled, onAudioDetected }: Props) {
  const [error, setError] = React.useState(false);
  const [audioDetected, setAudioDetected] = React.useState(false);
  const { microphoneDeviceId, autoGainControl, noiseSuppression, echoCancellation } = useVoice();
  const audioContextRef = React.useRef<AudioContext | null>(null);
  const currentStreamRef = React.useRef<MediaStream | null>(null);
  const intervalCallbackRef = React.useRef<ReturnType<typeof setInterval> | null>(null);

  // Throttle the callback to avoid calling it too often
  const throttledOnAudioDetected = React.useMemo(
    () =>
      throttle(() => {
        if (onAudioDetected) onAudioDetected();
      }, 3 * 1000),
    [onAudioDetected]
  );

  React.useEffect(() => {
    if (audioDetected) {
      throttledOnAudioDetected();
    }
  }, [audioDetected, throttledOnAudioDetected]);

  // Detect audio
  React.useEffect(() => {
    // Check if audio is detected
    function checkAudio(analyser: AnalyserNode) {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteFrequencyData(dataArray);

      // Compute volume as the average of frequency values
      let volume = dataArray.reduce((a, b) => a + b) / bufferLength;

      // Only detect audio if the volume is above a threshold
      const isAudioDetected = volume > 10;
      setAudioDetected(isAudioDetected);
    }

    // Stop the audio stream
    function stopAudioStream() {
      void audioContextRef.current?.close();
      audioContextRef.current = null;
      currentStreamRef.current?.getTracks().forEach((track) => track.stop());
      currentStreamRef.current = null;
      if (intervalCallbackRef.current) {
        clearInterval(intervalCallbackRef.current);
        intervalCallbackRef.current = null;
      }
    }

    // Start the audio stream
    function startAudioStream() {
      if (navigator.mediaDevices && enabled) {
        stopAudioStream();
        navigator.mediaDevices
          .getUserMedia({
            audio: {
              deviceId: microphoneDeviceId ? { exact: microphoneDeviceId } : undefined,
              autoGainControl,
              echoCancellation,
              noiseSuppression,
            },
          })
          .then((newStream) => {
            audioContextRef.current = new AudioContext();
            currentStreamRef.current = newStream;
            const source = audioContextRef.current.createMediaStreamSource(newStream);
            const analyser = audioContextRef.current.createAnalyser();
            source.connect(analyser);
            intervalCallbackRef.current = setInterval(() => checkAudio(analyser), 150);
            setError(false);
          })
          .catch(() => {
            // If the user doesn't give permission to access the microphone, we can't detect audio
            setError(true);
          });
      }
    }

    // Get the audio stream when the microphone device changes
    startAudioStream();
    if (navigator.mediaDevices?.ondevicechange !== undefined) {
      navigator.mediaDevices.addEventListener('devicechange', startAudioStream);
    }

    return () => {
      stopAudioStream();
      if (navigator.mediaDevices?.ondevicechange !== undefined) {
        navigator.mediaDevices.removeEventListener('devicechange', startAudioStream);
      }
    };
  }, [microphoneDeviceId, enabled, autoGainControl, noiseSuppression, echoCancellation]);

  const barStyles = {
    width: '4px',
    height: '4px',
    margin: '0 1.5px',
    borderRadius: '20px',
    backgroundColor: '#f0f0f0',
    animation: audioDetected ? 'wave 0.3s infinite alternate' : 'none',
  };

  if (!enabled || error) return null;

  return (
    <Keyframes>
      <Box sx={{ ...barStyles, animationDelay: '0.2s' }} />
      <Box sx={{ ...barStyles, animationDelay: '0.4s' }} />
      <Box sx={{ ...barStyles, animationDelay: '0.6s' }} />
    </Keyframes>
  );
}

export default AudioDetection;
