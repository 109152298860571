import Dexie, { Table } from 'dexie';

import dayjs from '@infinitus/utils/dayjs';
import { RecentCallEntry } from 'utils/RecentCallsService';

const CURRENT_VERSION = 3;

class PortalDexie extends Dexie {
  recentCalls!: Table<RecentCallEntry, string | number>;

  constructor() {
    super('portal');
    this.version(CURRENT_VERSION).stores({
      recentCalls: '&id, timestampMillis, uid',
      callFeedback: null,
    });

    this.on('ready', () => {
      void this.cleanupRecentCalls();
    });
  }

  async cleanup() {
    await this.cleanupRecentCalls();
  }

  async cleanupRecentCalls() {
    try {
      // eslint-disable-next-line no-restricted-syntax
      const threeDaysAgo = dayjs().subtract(72, 'hours').valueOf();
      const entriesToDelete = await this.recentCalls
        .where('timestampMillis')
        .below(threeDaysAgo)
        .toArray();

      if (entriesToDelete.length === 0) {
        return;
      }

      await this.recentCalls.bulkDelete(entriesToDelete.map(({ id }) => id));
    } catch (err) {
      console.error('Failed to clean up IndexedDB.portal.recentCalls');
      console.error(err);
    }
  }
}

const db = new PortalDexie();
export default db;
