import { styled } from '@mui/material';
import { MaterialDesignContent, CustomContentProps } from 'notistack';
import React from 'react';

// To add data-cy attribute to the snackbar, we need to override VariantOverrides
// for each variant we want to add the attribute to.
// https://notistack.com/features/customization#custom-variant-(typescript)
declare module 'notistack' {
  interface VariantOverrides {
    default: {
      'data-cy'?: string;
    };
    error: {
      'data-cy'?: string;
    };
    info: {
      'data-cy'?: string;
    };
    success: {
      'data-cy'?: string;
    };
    warn: {
      'data-cy'?: string;
    };
  }
}

// Override the default snackbar message background color
// https://notistack.com/features/customization#override-styles
export const StyledContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: 'rgb(250, 250, 250)',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  // Change icon color to match the text color
  '&.notistack-MuiContent-default .material-icons': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

interface CustomSnackbarMessageProps extends CustomContentProps {
  'data-cy'?: string;
}

/**
 * Our custom snackbar message component. We try to keep as much of the
 * notistack styling as possible. We only override the colors for the default
 * variant to have a light mode style that contrasts with our dark mode ui.
 * This also adds a data-cy attribute to the snackbar message for cypress
 * testing.
 *
 * This allows you to provide a "data-cy" prop like so:
 *   enqueueSnackbar(message, { "data-cy": string })
 */
export const CustomSnackbarMessage = React.forwardRef<HTMLDivElement, CustomSnackbarMessageProps>(
  (props, ref) => {
    const {
      // custom prop
      'data-cy': dataCy,
      ...other
    } = props;

    return (
      <div data-cy={dataCy}>
        <StyledContent {...other} ref={ref} />
      </div>
    );
  }
);
