/**
 * This class serves as an adapter between our React app and the vonage client sdk.
 * It handles authentication and manages the media stream (e.g. to ensure we only
 * have a single connection at any one time).
 */

import { AppName } from '@infinitusai/api';
import {
  VonageClient,
  ClientInitConfigObject,
  VonageError,
  createVonageLogger,
  Topics,
  VonageEvent,
} from '@vonage/client-sdk';
import { EventEmitter } from 'events';

import { ClientEventType } from '@infinitus/generated/frontend-common';
import { infinitusai } from '@infinitus/proto/pbjs';
import { TypedObjectEntries } from '@infinitus/types/utils';
import { FastTrackApi, OperatorPortalApi } from '@infinitus/utils/api';
import { PerformanceMarks, PerformanceMeasures } from '@infinitus/utils/constants';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';
import { wrapPromiseWithTimeout } from '@infinitus/utils/promiseHelpers';

import {
  EarMuffState,
  Events,
  MuteState,
  NEXMO_LOGIN_RETRIES,
  NEXMO_LOGIN_TIMEOUT,
  NEXMO_LOGIN_TIME_BETWEEN_RETRIES,
  NEXMO_MUTE_CHECK_POLLING_INTERVAL,
  NexmoClientServiceOptions,
  REFRESH_NEXMO_JWT_POLL_INTERVAL,
  asyncWaitForDuration,
  defaultNexmoClientServiceOptions,
} from './NexmoClientService';
import { NexmoClientAttachProps } from './NexmoContext';

export type VonageClientFactory = () => VonageClient;

const LOGGER_PREFIX = '[VonageClientService]';

// This logger is used to help prefix all logs with the same tag to make searching
// for logs from this service easier
const logger: {
  error: typeof console.error;
  info: typeof console.info;
  log: typeof console.log;
  warn: typeof console.warn;
} = {
  log: (...args) => console.log(LOGGER_PREFIX, ...args),
  warn: (...args) => console.warn(LOGGER_PREFIX, ...args),
  error: (...args) => console.error(LOGGER_PREFIX, ...args),
  info: (...args) => console.info(LOGGER_PREFIX, ...args),
};

const VONAGE_CLIENT_CONFIG: ClientInitConfigObject = {
  autoReconnectMedia: true,
  customLoggers: [
    createVonageLogger(
      'VonageSdkErrorLogger',
      (level, topic, message) => {
        const logMessage = `[VonageSdkErrorLogger] level=${level} topic=${topic.name} tag=${topic.tag} message=${message}`;
        if (level === 'Warn') {
          logger.warn(logMessage);
        } else if (level === 'Error') {
          logger.error(logMessage);
        } else {
          logger.info(logMessage);
        }
      },
      'Warn',
      Topics.values()
    ),
  ],
};

// This is a hack because react's types strip the setSinkId method from HTMLAudioElement
interface VonageHTMLAudioElement extends HTMLAudioElement {
  setSinkId(sinkId: string): Promise<void>;
}

class VonageClientService extends EventEmitter {
  private _VonageClientFactory: VonageClientFactory;
  private _options: NexmoClientServiceOptions;
  private _api: OperatorPortalApi | FastTrackApi;

  private _vonageClientInstance: VonageClient | null = null;
  private _listenerSymbols: Record<keyof VonageEvent, symbol | null> | undefined;
  private _refreshExpiredJwtTokenListenerSymbol: symbol | undefined;
  private _jwt: string = '';
  private _sessionId: string = '';
  // Stores the promise for a login attempt
  // This allows us to await any login in progress (e.g. during an attach() call)
  private _loginPromise: Promise<void> = Promise.resolve();
  private _isLoggingIn = false;
  private _isLoggedIn = false;
  private _enablePollingJwt = false;
  private _pollNewVonageJwtTimerId: NodeJS.Timeout | undefined = undefined;

  private _conversationId: string | undefined;
  private _callId: string | undefined;
  private _isAttaching = false;

  private _callShouldBeMuted = true;
  private _isMuteOperationInProgress = false;
  private _previousMuteState = MuteState.UNKNOWN;
  private _previousEarmuffState = EarMuffState.UNKNOWN;
  private _enablePollingMuteState = false;
  private _pollMuteStateTimerId: NodeJS.Timeout | undefined = undefined;

  constructor(
    VonageClientFactory: VonageClientFactory = () => new VonageClient(VONAGE_CLIENT_CONFIG),
    serviceOptions?: Partial<NexmoClientServiceOptions>
  ) {
    super();

    const serviceOptionsWithDefaults: NexmoClientServiceOptions = {
      ...defaultNexmoClientServiceOptions,
      ...serviceOptions,
    };

    switch (serviceOptionsWithDefaults.appName) {
      case AppName.OPERATOR:
        this._api = new OperatorPortalApi();
        break;
      case AppName.FASTTRACK:
        this._api = new FastTrackApi();
        break;
      default:
        throw new Error('Invalid app name provided to VonageClientService');
    }

    this._VonageClientFactory = VonageClientFactory;
    this._options = serviceOptionsWithDefaults;

    // bind all methods
    this.initialize = this.initialize.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.fetchNexmoJWT = this.fetchNexmoJWT.bind(this);
    this.refreshVonageJWT = this.refreshVonageJWT.bind(this);
    this.login = this.login.bind(this);
    this._login = this._login.bind(this);
    this.logout = this.logout.bind(this);
    this._checkMuteState = this._checkMuteState.bind(this);
    this.startPollingMuteState = this.startPollingMuteState.bind(this);
    this.stopPollingMuteState = this.stopPollingMuteState.bind(this);
    this.startPollingNewVonageJWT = this.startPollingNewVonageJWT.bind(this);
    this.stopPollingNewVonageJWT = this.stopPollingNewVonageJWT.bind(this);
    this.startNewSession = this.startNewSession.bind(this);
    this._joinConversationLeg = this._joinConversationLeg.bind(this);
    this._findOrCreateConversationLeg = this._findOrCreateConversationLeg.bind(this);
    this.attach = this.attach.bind(this);
    this._maybeLeaveConversation = this._maybeLeaveConversation.bind(this);
    this.attachEventListeners = this.attachEventListeners.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.maybeLeaveConversation = this.maybeLeaveConversation.bind(this);
    this.setEarmuffed = this.setEarmuffed.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.updateAudioConstraints = this.updateAudioConstraints.bind(this);
    this._updateInputDevice = this._updateInputDevice.bind(this);
    this.updateOutputDevice = this.updateOutputDevice.bind(this);
  }

  async initialize() {
    await this.login();
  }

  get client() {
    return this._vonageClientInstance;
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  /**
   * Fetch a new JWT from the backend to login to Vonage
   */
  private async fetchNexmoJWT(): Promise<void> {
    const { requestId, correlatedLog, correlatedError } = startCorrelatingLogs({
      logPrefix: LOGGER_PREFIX,
    });
    try {
      let msg = 'Fetching Vonage JWT...';
      correlatedLog(msg);
      logger.log(msg);
      const jwtResponse = await this._api.issueNexmoJwt(requestId);
      if (!jwtResponse.data.nexmoJwt) {
        throw new Error(
          `Didn't receive a Vonage JWT from the backend. Response was ${JSON.stringify(
            jwtResponse
          )}`
        );
      }
      this._jwt = jwtResponse.data.nexmoJwt;
      msg = `Received Vonage JWT ending in ${this._jwt.slice(-6)}`;
      correlatedLog(msg);
      logger.log(msg);
    } catch (e: any) {
      const msg = `Failed to retrieve Vonage JWT: ${e}`;
      correlatedError(msg);
      throw e;
    }
  }

  /**
   * Refresh the Vonage JWT and continue using existing session or create a new
   * one if none exists.
   */
  private async refreshVonageJWT() {
    if (!this.isLoggedIn()) return;
    if (!this._vonageClientInstance) return;

    try {
      logger.log('Refreshing Vonage JWT...');
      await this.fetchNexmoJWT();
      const currentSessionId = this._sessionId || undefined;
      this._sessionId = await this._vonageClientInstance.createSession(this._jwt, currentSessionId);
    } catch (e: any) {
      logger.error('Failed to refresh Vonage JWT', JSON.stringify(e));
    }
  }

  /**
   * Login to vonage.
   *
   * @note We're using a wrapper here to capture the promise for login attempts
   * in progress
   */
  login(): Promise<void> {
    if (this._isLoggingIn) {
      return this._loginPromise;
    }
    this._loginPromise = this._login();
    return this._loginPromise;
  }

  /**
   * Log into the Vonage client. Multiple attempts will be made if login fails.
   */
  private async _login(): Promise<void> {
    if (this.isLoggedIn()) return;
    if (this._isLoggingIn) return this._loginPromise;
    this._vonageClientInstance = this._VonageClientFactory();
    this.attachEventListeners();
    this._isLoggingIn = true;
    logger.log('Logging into VonageClientService...');

    // Allow multiple attempts to sign into Vonage with timeouts
    let vonageLoginRetriesLeft = NEXMO_LOGIN_RETRIES;
    const signIntoVonage = async () => {
      logger.log(
        `Attempting to login into Vonage client (${vonageLoginRetriesLeft} attempts left)...`
      );
      vonageLoginRetriesLeft--;
      try {
        const sessionId = await wrapPromiseWithTimeout<string>(
          this._vonageClientInstance!.createSession(this._jwt),
          NEXMO_LOGIN_TIMEOUT,
          {
            error: () => new Error('Timed out when trying to sign into Vonage'),
            onTimeout: () => {
              logger.warn('Timeout when trying to sign into Vonage');
            },
          }
        );
        logger.log('Successfully signed into the VonageClientService.');
        this._isLoggedIn = true;
        this._sessionId = sessionId;
        this.emit(Events.LOGIN_SUCCEEDED);
        this.startPollingNewVonageJWT();

        if (this._refreshExpiredJwtTokenListenerSymbol !== undefined) {
          this._vonageClientInstance?.off(
            'sessionError',
            this._refreshExpiredJwtTokenListenerSymbol
          );
        }
        this._refreshExpiredJwtTokenListenerSymbol = this._vonageClientInstance?.on(
          'sessionError',
          (reason) => {
            if (reason.name === 'EXPIRED_TOKEN') {
              logger.log('Vonage JWT Token Expired');
              this.fetchNexmoJWT()
                .then(() => {
                  logger.log('JWT successfully refreshed');
                  // login only happens when the user is not logged in, so there is no active session to rejoin
                  void this._vonageClientInstance?.createSession(this._jwt);
                })
                .catch((e: any) => {
                  logger.error(`Failed to refresh Vonage JWT Token: ${e.message || e}
                    ${e.stack}`);
                });
            }
          }
        );
      } catch (e: any) {
        this._sessionId = '';
        logger.error(`Failed Vonage sign-in attempt: ${e.message}
            ${e.stack}`);
        if (vonageLoginRetriesLeft <= 0) {
          this.emit(Events.LOGIN_FAILED);
          const message = `Failed signing into Vonage after ${NEXMO_LOGIN_RETRIES} attempts.`;
          void this._options.logEvent({
            message,
            clientEventType: ClientEventType.NEXMO,
            meta: {
              conversationId: this._conversationId,
              error: 'failed-sign-in',
            },
          });
          throw new Error(message);
        }
        await asyncWaitForDuration(NEXMO_LOGIN_TIME_BETWEEN_RETRIES);
        await signIntoVonage();
      } finally {
        this._isLoggingIn = false;
      }
    };

    try {
      await this.fetchNexmoJWT();
      logger.log('JWT successfully retrieved, now logging into Vonage client...');
      await signIntoVonage();
    } catch (e: any) {
      logger.error(`Failed to sign into Vonage: ${e.message}
          ${e.stack}`);
      this._isLoggingIn = false;
      this._isLoggedIn = false;
      this._sessionId = '';
      throw e;
    }
  }

  /**
   * Log out of the Vonage client by deleting the current session
   */
  async logout(): Promise<void> {
    if (!this._isLoggedIn || !this._vonageClientInstance) return;
    logger.log('Logging out of VonageClientService...');
    try {
      await this._vonageClientInstance.deleteSession();
      this._sessionId = '';
      this._jwt = '';
      this._isLoggedIn = false;
      this._conversationId = undefined;
      this.stopPollingNewVonageJWT();
      this.removeEventListeners();
      if (this._refreshExpiredJwtTokenListenerSymbol !== undefined) {
        this._vonageClientInstance?.off('sessionError', this._refreshExpiredJwtTokenListenerSymbol);
      }
      logger.log('Logged out.');
    } catch (e: any) {
      logger.error(`Failed to logout of Vonage: ${e.message}`);
      throw e;
    }
  }

  /**
   * Check the actual mute state for the users leg. If not in the expected
   * state, correct it.
   */
  private async _checkMuteState() {
    if (!this._callId) {
      logger.error(`Unable to determine current call ID.`);
      return;
    }
    const myLeg = await this._vonageClientInstance?.getLeg(this._callId);
    if (!myLeg) {
      logger.error("Unable to get current user's leg object.");
      return;
    }
    const muted = myLeg.mediaState?.mute;
    const earmuffed = myLeg.mediaState?.earmuff;

    if (this._callShouldBeMuted !== muted) {
      logger.warn(
        `Call should ${
          this._callShouldBeMuted ? '' : 'not '
        }be muted, but Vonage audio_settings.muted is ${muted}.`
      );
      if (this._isMuteOperationInProgress) {
        logger.log('Skipping mute state check because mute operation is still in progress.');
      } else {
        void this.setMuted(this._callShouldBeMuted).catch((e: any) => {
          if (e instanceof VonageError) {
            logger.error(`Failed to correct mute state: ${e.message}`);
          } else {
            logger.error(`Failed to correct mute state: ${e}`);
          }
        });
      }
    }

    const newMuteState =
      muted === true ? MuteState.MUTED : muted === false ? MuteState.UNMUTED : MuteState.UNKNOWN;
    const newEarMuffState =
      earmuffed === true
        ? EarMuffState.EARMUFFED
        : earmuffed === false
        ? EarMuffState.UNEARMUFFED
        : EarMuffState.UNKNOWN;

    if (newMuteState !== this._previousMuteState) {
      logger.log(
        `Vonage mute state changed from '${this._previousMuteState}' to '${newMuteState}'`
      );
      this.emit(Events.MUTE_STATE_CHANGE, newMuteState);
    }
    if (newEarMuffState !== this._previousEarmuffState) {
      logger.log(
        `Vonage earmuff state changed from '${this._previousEarmuffState}' to '${newEarMuffState}'`
      );
      this.emit(Events.EARMUFF_STATE_CHANGE, newEarMuffState);
    }

    this._previousMuteState = newMuteState;
    this._previousEarmuffState = newEarMuffState;
  }

  /**
   * It's important that we maintain muting, and we have had trouble in the past
   * with calls unmuting themselves using the nexmo-client sdk. We will maintain
   * this logic in the @vonage/client-sdk implementation to be safe. So we'll
   * continue polling and checking the mute state throughout the life of the
   * call. There are 2 ways that we can detect whether a call is muted:
   *  1) By listening for the audio:mute:(on|off) events.
   *  2) By refreshing the Conversation object from Vonage and inspecting the
   *     audio_settings. This was our advised approach from Vonage support (ticket 1563124).
   * We use #2 to poll for the latest mute state.
   */
  private startPollingMuteState() {
    logger.log(
      `Starting polling Vonage for mute state every ${NEXMO_MUTE_CHECK_POLLING_INTERVAL}ms.`
    );

    this._enablePollingMuteState = true;

    const checkMuteState = async () => {
      if (this._pollMuteStateTimerId) clearTimeout(this._pollMuteStateTimerId);
      if (!this._enablePollingMuteState) return;

      try {
        await this._checkMuteState();
      } catch (e: any) {
        logger.error(`Failed to check mute state: ${e.message}`);
      }

      this._pollMuteStateTimerId = setTimeout(checkMuteState, NEXMO_MUTE_CHECK_POLLING_INTERVAL);
    };

    void checkMuteState();
  }

  /**
   * Stop polling for actual mute state
   */
  private stopPollingMuteState() {
    logger.log('Stopping polling Vonage for mute state.');
    this._enablePollingMuteState = false;
    if (this._pollMuteStateTimerId) clearTimeout(this._pollMuteStateTimerId);
  }

  /**
   * We want to maintain auth to avoid the time needed to re-login to vonage
   * when starting a new session
   */
  private startPollingNewVonageJWT() {
    logger.log(`Starting polling new Vonage jwt every ${REFRESH_NEXMO_JWT_POLL_INTERVAL}ms.`);
    this._enablePollingJwt = true;

    const maybeRefreshVonageJWT = async () => {
      if (this._pollNewVonageJwtTimerId) clearTimeout(this._pollNewVonageJwtTimerId);
      if (!this._enablePollingJwt) return;
      if (!this.isLoggedIn()) return;
      if (!this._vonageClientInstance) return;

      void this.refreshVonageJWT();

      this._pollNewVonageJwtTimerId = setTimeout(
        maybeRefreshVonageJWT,
        REFRESH_NEXMO_JWT_POLL_INTERVAL
      );
    };

    // Schedule the first invocation to happen in the future so that we don't
    // immediately refresh the JWT when the user logs in.
    this._pollNewVonageJwtTimerId = setTimeout(
      maybeRefreshVonageJWT,
      REFRESH_NEXMO_JWT_POLL_INTERVAL
    );
  }

  /**
   * Stop polling for a new vonage client jwt
   */
  private stopPollingNewVonageJWT() {
    logger.log('Stopping polling new Vonage JWT.');
    this._enablePollingJwt = false;

    if (this._pollNewVonageJwtTimerId) clearTimeout(this._pollNewVonageJwtTimerId);
  }

  /**
   * Fetch a new JWT and establish a new session every time
   */
  async startNewSession(): Promise<void> {
    await this.logout();
    await this.login();
  }

  /**
   * Join a conversation leg
   * @param leg
   */
  private async _joinConversationLeg(leg: infinitusai.be.GetMyLegInNexmoConversationResponse) {
    if (!this._vonageClientInstance) {
      throw new Error('Vonage client instance not found');
    }

    switch (leg.status) {
      case infinitusai.be.GetMyLegInNexmoConversationResponse.Status.STATUS_RINGING:
        logger.log(`Answering call leg... ${leg.legId}`);
        const maxRetries = 3;
        for (let attempt = 1; attempt <= maxRetries; attempt++) {
          try {
            await this._vonageClientInstance.answer(leg.legId);
            break; // Success, exit loop
          } catch (error) {
            // We will only re-attempt answering if an invite is not found yet
            // because the client did not receive the invite yet
            if (!(error instanceof VonageError)) {
              throw error; // Throw if not a Vonage error
            }
            if (error.code !== 'voice:error:no_invite_found') {
              throw error; // Throw for any error except missing invite
            }
            if (attempt === maxRetries) {
              throw error; // Throw on last attempt
            }
            logger.warn(
              `Answer attempt failed. ${maxRetries - attempt} remaining attempts. Retrying...`
            );
          }
          // wait before next attempt
          await asyncWaitForDuration(150);
        }
        break;
      case infinitusai.be.GetMyLegInNexmoConversationResponse.Status.STATUS_ANSWERED:
        logger.log(`Reconnecting call leg... ${leg.legId}`);
        await this._vonageClientInstance.reconnectCall(leg.legId);
        break;
      default:
        const msg = `Failed to join conversation because of unknown leg status ${leg.status}`;
        logger.error(msg, { leg });
        throw new Error(msg);
    }
  }

  /**
   * Join conversation audio via webRTC using the vonage conversation_id by
   *   1. finding or creating a conversation leg for the user
   *   2. using the leg_id to answer or reconnect to the call leg
   * a more detailed flow is available in the sequence diagram below.
   *
   * @mermaid
   *   sequenceDiagram
   *     participant FE as Frontend
   *     participant BE as Backend
   *     participant Nexmo
   *     Note right of BE: A call can be started from the frontend through the backend or from the autodialer
   *     FE->>BE: request call info for active call
   *     BE-->>FE: return nexmo conversation_id
   *     FE->>BE: /getMyLegInNexmoConversation
   *     BE->>Nexmo: invite user with POST /v1//conversations/<conversation_id>/members
   *     Nexmo-->>BE: success
   *     BE->>Nexmo: GET /v1/conversations/<conversation_id>/legs
   *     Nexmo-->>BE: return paginated legs for conversation
   *     BE-->>FE: return leg_id for user to join
   *     FE->>Nexmo: answer() leg_id to establish webrtc connection
   *     Nexmo<<->>FE: setup webrtc connection for leg
   *
   * @note This flow is not officially provided by the vonage sdk documentation.
   * The flow was was validated in meeting with vonage/client-sdk experts
   * (mark.berkeland@vonage.com and tony.chan@vonage.com) as well as constructed
   * with guidance from tim.dentry@vonage.com in an email thread and a couple
   * meetings. They all supported proceeding with this flow and at the time of
   * writing this were looking into ways to optimize the flow.
   */
  private async _findOrCreateConversationLeg(
    requestId: string,
    orgUuid: string,
    conversationUuid: string
  ) {
    if (!this._vonageClientInstance) {
      throw new Error('Vonage client instance not found');
    }

    const { correlatedLog } = startCorrelatingLogs({
      logPrefix: LOGGER_PREFIX,
      requestId,
    });

    // Ask backend to create or find our call leg to join with
    logger.log(
      `Finding or creating Vonage conversation leg for conversation ${conversationUuid}...`
    );
    let resp = await this._api.getMyLegInNexmoConversation(requestId, orgUuid, conversationUuid);
    let respData = infinitusai.be.GetMyLegInNexmoConversationResponse.fromObject(resp.data);
    const msg = `Fetched ${
      infinitusai.be.GetMyLegInNexmoConversationResponse.Status[respData.status]
    } leg ${respData.legId} in conversation ${conversationUuid} `;
    correlatedLog(msg);
    logger.log(msg);

    // Sometimes the call legs seem to get in a bad state where:
    //   - you have a completed leg but are a joined member
    //   - your leg and corresponding legId cannot be found (ie. empty legId)
    //   - your leg status is unknown
    // so we might need to first check whether to leave the conversation if
    // joined. This does increase the time it takes to join the call audio,
    // however, the resilience to join was necessary.
    if (
      respData.status ===
        infinitusai.be.GetMyLegInNexmoConversationResponse.Status.STATUS_COMPLETED ||
      respData.status ===
        infinitusai.be.GetMyLegInNexmoConversationResponse.Status.STATUS_UNKNOWN ||
      respData.legId === ''
    ) {
      logger.log(
        `Leaving conversation ${conversationUuid} because of bad leg status ${
          infinitusai.be.GetMyLegInNexmoConversationResponse.Status[respData.status]
        } for leg ${respData.legId}`
      );
      await this._maybeLeaveConversation(conversationUuid);
      logger.log('Creating new Vonage conversation leg...');
      resp = await this._api.getMyLegInNexmoConversation(requestId, orgUuid, conversationUuid);
      respData = infinitusai.be.GetMyLegInNexmoConversationResponse.fromObject(resp.data);
      const msg = `Fetched ${
        infinitusai.be.GetMyLegInNexmoConversationResponse.Status[respData.status]
      } leg ${respData.legId} in conversation ${conversationUuid} after leaving conversation`;
      correlatedLog(msg);
      logger.log(msg);
    }

    return respData;
  }

  /**
   * Attach to a conversation audio via webRTC using the vonage conversation_id
   */
  async attach({
    orgUuid,
    conversationUuid,
    muteMicByDefault,
    source,
    deviceId = '',
    outputDeviceId = '',
    autoGainControl = true,
    echoCancellation = true,
    noiseSuppression = true,
  }: NexmoClientAttachProps): Promise<void> {
    if (!this._vonageClientInstance) return;

    logger.log(
      `Vonage client requested to attach to conversation '${conversationUuid}' with mic '${
        muteMicByDefault ? 'disabled' : 'enabled'
      }' ...`
    );
    // Since we login while the application is mounting, if the user attempts to attach to a conversation
    // before the login has completed, we should wait for the login to complete before continuing with
    // the attach process (e.g. when a user refreshes an active call page)
    if (this._isLoggingIn) {
      logger.log(`Awaiting login in progress before attaching to conversation...`);
      await this._loginPromise;
      logger.log(`Existing login completed.`);
    }
    if (this._conversationId === conversationUuid) {
      logger.log(
        `Already attached to conversation '${conversationUuid}', ignoring attach request.`
      );
      return;
    }
    if (this._isAttaching) {
      logger.log(`Already attaching to conversation, ignoring attach request.`);
      return;
    }
    logger.log(
      `Vonage client service attaching to conversation '${conversationUuid}' as requested by '${source}'...`
    );
    this._previousMuteState = MuteState.UNKNOWN;
    this._previousEarmuffState = EarMuffState.UNKNOWN;
    this._isAttaching = true;
    this.emit(Events.JOINING_CONVERSATION, conversationUuid);
    window.performance.mark(PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING);
    try {
      const { requestId, correlatedLog, correlatedError } = startCorrelatingLogs({
        logPrefix: LOGGER_PREFIX,
      });
      await this.login();

      logger.log('Creating Vonage conversation leg...');

      try {
        const foundLeg = await this._findOrCreateConversationLeg(
          requestId,
          orgUuid,
          conversationUuid
        );
        await this._joinConversationLeg(foundLeg);
        this._callId = foundLeg.legId;
        this._conversationId = conversationUuid;
      } catch (e: any) {
        const errorMsg =
          e instanceof VonageError
            ? `[VonageError]cause=${e.cause} code=${e.code} message=${e.message} name=${e.name} stack=${e.stack}  kmpCauseCause=${e.kmpCause?.cause} kmpCauseName=${e.kmpCause?.name}  kmpCauseMessage=${e.kmpCause?.message} kmpCauseStack=${e.kmpCause?.stack}`
            : e?.response?.data || e.message;
        const msg = `Failed to join conversation ${conversationUuid}: ${errorMsg}`;
        correlatedError(msg);
        logger.error(msg);
        throw e;
      }

      const msg = `updating input device for conversation leg ${this._callId}`;
      correlatedLog(msg);
      logger.log(msg);
      await this._updateInputDevice({
        autoGainControl: autoGainControl ?? this._options.autoGainControl ?? true,
        echoCancellation: echoCancellation ?? this._options.echoCancellation ?? true,
        noiseSuppression: noiseSuppression ?? this._options.noiseSuppression ?? true,
        deviceId: deviceId ?? this._options.deviceId ?? undefined,
      });
      if (outputDeviceId) await this.updateOutputDevice(outputDeviceId);
      window.performance.mark(PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING);

      // set initial mute state
      await this.setMuted(muteMicByDefault);
      if (!this._options.disablePolling) this.startPollingMuteState();
      window.performance.measure(
        PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO,
        PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING,
        PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING
      );

      this.emit(Events.JOINED_CONVERSATION, conversationUuid);
      const timeToLoadNexmo = window.performance.getEntriesByName(
        PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO
      )[0].duration;
      const message = `Time to complete connecting to Vonage conversation from source '${source}': ${timeToLoadNexmo}ms`;
      logger.log(`Performance: ${message}`);
      void this._options.logEvent({
        clientEventType: ClientEventType.PERFORMANCE_MEASUREMENT,
        message,
        meta: { timeToConnectToNexmoConversation: timeToLoadNexmo, source, conversationUuid },
      });
      // clean up
      window.performance.clearMeasures(PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO);
      window.performance.clearMarks(PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING);
      window.performance.clearMarks(PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING);
    } catch (e: any) {
      logger.error(
        `Failed to connect Vonage media stream for conversation '${conversationUuid}' with muteMicByDefault set to '${muteMicByDefault}': ${JSON.stringify(
          e.message
        )}`
      );
      this.emit(Events.DISCONNECT_DETECTED);
      throw e;
    } finally {
      this._isAttaching = false;
    }
  }

  /**
   * Remove joined member from conversation
   * @param conversationUuid
   */
  private async _maybeLeaveConversation(conversationUuid: string) {
    if (!this._vonageClientInstance) return;

    try {
      // TODO: figure out what the correct memberState to use is, we should not
      // always have to leave. however the following commented code does not
      // return the memberState for the user. ideally we should only leave if
      // memberState === 'JOINED'
      //   const conversation = await this._vonageClientInstance.getConversation(conversationUuid);
      //   logger.log('Conversation member state:', conversation.memberState);
      //    if (conversation.memberState !== 'JOINED') {
      //      logger.log('Leaving conversation', conversationUuid);
      //      await this._vonageClientInstance.leaveConversation(conversationUuid);
      //    }

      logger.log('Leaving conversation', conversationUuid);
      await this._vonageClientInstance.leaveConversation(conversationUuid);
    } catch (e: any) {
      logger.error(`Failed to leave conversation ${conversationUuid}: ${e.message}`);
    }
  }

  /**
   * Add Event listeners for the vonage client instance after removing any
   * existing ones
   */
  private attachEventListeners() {
    // To avoid stacking up event listeners, remove them first
    this.removeEventListeners();
    if (!this._vonageClientInstance) return;
    logger.log('Attaching event listeners for this conversation');

    this._listenerSymbols = {
      conversationEvent: this._vonageClientInstance.on('conversationEvent', (event) => {
        switch (event.kind) {
          case 'member:invited':
          case 'member:joined':
          case 'member:left':
            logger.log(
              `[event] conversationEvent: kind=${event.kind} user=${event.body.user.name}`
            );
            break;
          case 'custom':
          case 'ephemeral':
          case 'event:delete':
          case 'message:audio':
          case 'message:custom':
          case 'message:delivered':
          case 'message:file':
          case 'message:image':
          case 'message:location':
          case 'message:rejected':
          case 'message:seen':
          case 'message:submitted':
          case 'message:template':
          case 'message:text':
          case 'message:undeliverable':
          case 'message:vcard':
          case 'message:video':
            logger.log(
              `[event] conversationEvent: kind=${event.kind} body=${JSON.stringify(event.body)}`
            );
            break;
        }
      }),
      callHangup: this._vonageClientInstance.on('callHangup', (callId, callQuality, reason) => {
        logger.log(
          `[event] callHangup: callId=${callId}, MOS=${callQuality.mos_score}, reason=${reason}`
        );
      }),
      callMediaDisconnect: this._vonageClientInstance.on(
        'callMediaDisconnect',
        (callId, reason) => {
          logger.log(`[event] callMediaDisconnect: callId=${callId}, reason=${reason}`);
        }
      ),
      legStatusUpdate: this._vonageClientInstance.on('legStatusUpdate', (callId, legId, status) => {
        logger.log(`[event] legStatusUpdate: callId=${callId}, legId=${legId}, status=${status}`);
      }),
      // We are not logging rtcStatsUpdate here because it is too noisy, we
      // already track rtc scores by monkey patching the RTCPeerConnection
      // creation using the WebRtcIssueDetector.
      rtcStatsUpdate: null,
      callInvite: this._vonageClientInstance.on('callInvite', (callId, from, channelType) => {
        logger.log(
          `[event] callInvite: callId=${callId}, from=${from}, channelType=${channelType}`
        );
      }),
      callInviteCancel: this._vonageClientInstance.on('callInviteCancel', (callId, reason) => {
        logger.log(`[event] callInviteCancel: callId=${callId}, reason=${reason}`);
      }),
      callTransfer: this._vonageClientInstance.on('callTransfer', (callId, conversationId) => {
        logger.log(`[event] callTransfer: callId=${callId}, conversationId=${conversationId}`);
      }),
      mute: this._vonageClientInstance.on('mute', (callId, legId, isMuted) => {
        logger.log(`[event] mute: callId=${callId}, legId=${legId}, isMuted=${isMuted}`);
      }),
      earmuff: this._vonageClientInstance.on('earmuff', (callId, legId, earmuffStatus) => {
        logger.log(
          `[event] earmuff: callId=${callId}, legId=${legId}, earmuffStatus=${earmuffStatus}`
        );
      }),
      dtmf: this._vonageClientInstance.on('dtmf', (callId, legId, digits) => {
        logger.log(`[event] dtmf: callId=${callId}, legId=${legId}, digits=${digits}`);
      }),
      callMediaReconnecting: this._vonageClientInstance.on('callMediaReconnecting', (callId) => {
        logger.log(`[event] callMediaReconnecting: callId=${callId}`);
      }),
      callMediaReconnection: this._vonageClientInstance.on('callMediaReconnection', (callId) => {
        logger.log(`[event] callMediaReconnection: callId=${callId}`);
      }),
      callMediaError: this._vonageClientInstance.on('callMediaError', (callId, error) => {
        logger.error(
          `[event] callMediaError: callId=${callId}, error=${JSON.stringify(error, null, 2)}`
        );
      }),
      sessionError: this._vonageClientInstance.on('sessionError', (reason) => {
        logger.error(`[event] sessionError: reason=${JSON.stringify(reason, null, 2)}`);
      }),
      reconnecting: this._vonageClientInstance.on('reconnecting', () => {
        logger.log(`[event] reconnecting`);
      }),
      reconnection: this._vonageClientInstance.on('reconnection', () => {
        logger.log(`[event] reconnection`);
      }),
    };
  }

  /**
   * Remove Event listeners for the vonage client instance
   */
  private removeEventListeners() {
    if (!this._vonageClientInstance) return;
    if (!this._listenerSymbols) return;
    logger.log('Removing event listeners');
    for (const [event, symbol] of TypedObjectEntries(this._listenerSymbols)) {
      if (symbol) {
        this._vonageClientInstance.off(event, symbol);
      }
    }
  }

  /**
   * Leaves the conversation if currently attached to one
   *
   * @example
   * Leave the conversation
   * ```ts
   * const vonageClientService = new VonageClientService();
   * vonageClientService.maybeLeaveConversation();
   * ```
   */
  async maybeLeaveConversation() {
    if (!this._vonageClientInstance) return;
    if (!this._callId) return;

    try {
      logger.log('Leaving Vonage conversation...');
      this.stopPollingMuteState();
      await this._vonageClientInstance.hangup(this._callId);
      this.emit(Events.LEFT_CONVERSATION);
      logger.log(`Disabled media for Vonage conversation.`);
    } catch (e: any) {
      logger.error(`Failed when leaving Vonage conversation: ${e.message}
            ${e.stack}`);
      throw e;
    } finally {
      this._conversationId = undefined;
      this._callId = undefined;
    }
  }

  /**
   * Toggles playing audio via the speaker
   *
   * @param bool - Whether to mute or unmute the speaker
   *
   * @example
   * mute audio output
   * ```ts
   * const vonageClientService = new VonageClientService();
   * vonageClientService.setEarmuffed(true);
   * ```
   */
  async setEarmuffed(bool: boolean) {
    if (!this._vonageClientInstance) return;
    if (!this._callId) return;

    try {
      if (bool) {
        logger.log('Muting playback (earmuffing)');
        await this._vonageClientInstance.enableEarmuff(this._callId);
      } else {
        logger.log('Unmuting playback (removing earmuffs)');
        await this._vonageClientInstance.disableEarmuff(this._callId);
      }
    } catch (e: any) {
      logger.error(`Failed ${bool ? '' : 'un'}earmuff attempt: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  /**
   * Toggles transmitting audio via the microphone
   *
   * @param bool - Whether to mute or unmute the microphone
   *
   * @example
   * Mute the microphone
   * ```ts
   * const vonageClientService = new VonageClientService();
   * vonageClientService.setMuted(true);
   * ```
   */
  async setMuted(shouldMute: boolean) {
    if (!this._vonageClientInstance) return;
    if (!this._callId) return;
    if (this._isMuteOperationInProgress) {
      logger.log(
        `Mute operation in progress. continuing with unsafe ${
          shouldMute ? 'mute' : 'unmute'
        } operation`
      );
    }

    this._isMuteOperationInProgress = true;
    try {
      this.emit(Events.CHANGING_MUTE_STATE);
      this._callShouldBeMuted = shouldMute;
      if (shouldMute) {
        logger.log('Disabling the microphone (muting)', this._callId);
        await this._vonageClientInstance.mute(this._callId);
      } else {
        logger.log('Enabling the microphone (unmuting)', this._callId);
        await this._vonageClientInstance.unmute(this._callId);
      }
      const newMuteState = shouldMute ? MuteState.MUTED : MuteState.UNMUTED;
      this.emit(Events.MUTE_STATE_CHANGE, newMuteState);
    } catch (e: any) {
      logger.error(`Failed ${shouldMute ? '' : 'un'}mute attempt: ${e.message}
          ${e.stack}`);
      throw e;
    } finally {
      this._isMuteOperationInProgress = false;
      this.emit(Events.CHANGED_MUTE_STATE);
    }
  }

  /**
   * Updates the audio constraints for the current conversation
   *
   * @param constraints - The constraints for the audio input device
   *
   * @example
   * Update the audio input device with constraints
   * ```ts
   * const vonageClientService = new VonageClientService();
   *
   * const constraints: MediaTrackConstraints = {
   *   deviceId: 'audio-input-device-id',
   *   autoGainControl: false,
   *   echoCancellation: true,
   *   noiseSuppression: true,
   * }
   * vonageClientService.updateAudioConstraints(constraints);
   * ```
   */
  async updateAudioConstraints(constraints: MediaTrackConstraints) {
    if (!this._vonageClientInstance) return;

    await this._updateInputDevice(constraints);
  }

  /**
   * Update the audio input device
   *
   * @param constraints - The constraints for the audio input device
   *
   * @example
   * Update the audio input device with constraints
   * ```ts
   * const constraints = {
   *   deviceId: 'audio-input-device-id',
   *   autoGainControl: false,
   *   echoCancellation: true,
   *   noiseSuppression: true,
   * }
   * this._updateInputDevice(constraints);
   * ```
   */
  private async _updateInputDevice(constraints: MediaTrackConstraints) {
    if (!this._vonageClientInstance) return;
    if (!this._callId) return;

    const pc: RTCPeerConnection | undefined = this._vonageClientInstance.getPeerConnection(
      this._callId
    );
    if (!pc) {
      logger.error('No peer connection found');
      return;
    }

    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: constraints,
    });
    const track = localStream.getAudioTracks().at(0);
    if (!track) {
      logger.error('No audio track found');
      return;
    }

    logger.log(`Getting RTC sender for ${track.kind}`);
    const sender = pc.getSenders().find((sender) => sender.track?.kind === track.kind);
    logger.log(`Updating audio input device to ${constraints.deviceId}`);
    await sender?.replaceTrack(track);
  }

  /**
   * Update the audio output device
   *
   * @param deviceId - The deviceId of the audio output device
   * @returns void
   *
   * @example
   * Update the audio output device to the first available audio output device
   * ```ts
   * const audioOutputDevices = await navigator.mediaDevices.enumerateDevices()
   *   .then(devices => devices.filter(d => d.kind == "audiooutput"));
   * const deviceId = audioOutputDevices[0].deviceId;
   * this._updateOutputDevice(deviceId);
   * ```
   *
   * @note This method will not work in safari because `setSinkId` is not
   * supported in Safari. See compatibility: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId#browser_compatibility
   */
  async updateOutputDevice(deviceId: string) {
    if (!this._vonageClientInstance) return;

    const audioOutputElement = this._vonageClientInstance.getAudioOutputElement();
    if (!audioOutputElement) {
      logger.error('No audio output element found');
      return;
    }

    logger.log(`Updating speaker device to ${deviceId}`);
    await (audioOutputElement as VonageHTMLAudioElement).setSinkId(deviceId);
  }
}

export default VonageClientService;
