// When you import dayjs into any file via `utils/dayjs`, you will
// have access to the `relativeTime` and `updateLocale` plugins.
// If you need additional plugins, import them and extend dayjs here.

import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import type dayjs from '@infinitus/utils/dayjs';
import timezoneOptionalDate, {
  DEFAULT_TIMEZONE as defaultTz,
} from '@infinitus/utils/dayjsTimezoneOptionalDatePlugin';

export const DEFAULT_TIMEZONE = defaultTz;

/**
 *
 * @param _dayjs - `dayjs` factory imported from `dayjs`.
 *
 * Required to ensure that the instance of `dayjs` that gets extended
 * matches the `dayjs` resolved when importing from `dayjs`
 */
export function initDayjsPlugins(_dayjs: typeof dayjs) {
  // Extend dayjs so extensions are attached to all imports
  // Alphabetized unless there are dependencies
  // objectSupport needs to be before duration
  _dayjs.extend(objectSupport);

  _dayjs.extend(calendar);
  _dayjs.extend(customParseFormat);
  _dayjs.extend(duration);
  _dayjs.extend(isoWeek);
  // Required to maintain date.io adapter compatibility
  _dayjs.extend(weekOfYear);
  _dayjs.extend(localeData);
  _dayjs.extend(localizedFormat);
  // Use strict time thresholds, and use "minutes" under 2 hours
  _dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'm', r: 1 },
      { l: 'mm', r: 119, d: 'minute' },
      { l: 'h', r: 2 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y', r: 1 },
      { l: 'yy', d: 'year' },
    ],
  });
  _dayjs.extend(updateLocale);
  // Timezone depends on UTC plugin
  _dayjs.extend(utc);
  _dayjs.extend(timezone);
  _dayjs.extend(advancedFormat);
  _dayjs.extend(timezoneOptionalDate);

  // We should eventually choose better strings
  // and round better than the defaults as well:
  // https://day.js.org/docs/en/customization/relative-time
  _dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  });

  _dayjs.tz.setDefault(DEFAULT_TIMEZONE);
}
