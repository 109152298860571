import { RecordedGreetingApprovalStatus } from '@infinitus/generated/frontend-common';
import { getRecordedGreetingApprovalStatusDisplayName } from '@infinitus/utils/displayNames';

export const statuses = ['APPROVED', 'PENDING', 'MISSING'] as const;
export type Status = (typeof statuses)[number];

export function getChipLabel(status: Status | RecordedGreetingApprovalStatus) {
  switch (status) {
    case RecordedGreetingApprovalStatus.GREETING_APPROVED:
    case RecordedGreetingApprovalStatus.GREETING_PENDING:
    case RecordedGreetingApprovalStatus.GREETING_REJECTED:
      return getRecordedGreetingApprovalStatusDisplayName(status);
    case 'APPROVED':
      return 'Greetings Approved';
    case 'PENDING':
      return 'Pending Approval';
    case 'MISSING':
      return 'Missing Greetings';
    default:
      return 'Unknown Greeting Status';
  }
}
