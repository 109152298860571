export type IconColors =
  | 'action'
  | 'disabled'
  | 'error'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'textPrimary'
  | 'textSecondary'
  | 'warning'
  | 'white';

// Names sourced from:
// https://github.com/daimoonis/material-icons-font/blob/master/sass/_codepoints.scss
// Preview material icons (can search by icon name):
// https://fonts.google.com/icons?selected=Material+Icons
export enum IconNames {
  '10K' = '10k',
  '10MP' = '10mp',
  '11MP' = '11mp',
  '123_TEXT' = '123',
  '12MP' = '12mp',
  '13MP' = '13mp',
  '14MP' = '14mp',
  '15MP' = '15mp',
  '16MP' = '16mp',
  '17MP' = '17mp',
  '18_UP_RATING' = '18_up_rating',
  '18MP' = '18mp',
  '19MP' = '19mp',
  '1K' = '1k',
  '1K_PLUS' = '1k_plus',
  '1X_MOBILEDATA' = '1x_mobiledata',
  '20MP' = '20mp',
  '21MP' = '21mp',
  '22MP' = '22mp',
  '23MP' = '23mp',
  '24MP' = '24mp',
  '2K' = '2k',
  '2K_PLUS' = '2k_plus',
  '2MP' = '2mp',
  '30FPS' = '30fps',
  '30FPS_SELECT' = '30fps_select',
  '360_TEXT' = '360',
  '3D_ROTATION' = '3d_rotation',
  '3G_MOBILEDATA' = '3g_mobiledata',
  '3K' = '3k',
  '3K_PLUS' = '3k_plus',
  '3MP' = '3mp',
  '3P' = '3p',
  '4G_MOBILEDATA' = '4g_mobiledata',
  '4G_PLUS_MOBILEDATA' = '4g_plus_mobiledata',
  '4K' = '4k',
  '4K_PLUS' = '4k_plus',
  '4MP' = '4mp',
  '5G' = '5g',
  '5K' = '5k',
  '5K_PLUS' = '5k_plus',
  '5MP' = '5mp',
  '60FPS' = '60fps',
  '60FPS_SELECT' = '60fps_select',
  '6_FT_APART' = '6_ft_apart',
  '6K' = '6k',
  '6K_PLUS' = '6k_plus',
  '6MP' = '6mp',
  '7K' = '7k',
  '7K_PLUS' = '7k_plus',
  '7MP' = '7mp',
  '8K' = '8k',
  '8K_PLUS' = '8k_plus',
  '8MP' = '8mp',
  '9K' = '9k',
  '9K_PLUS' = '9k_plus',
  '9MP' = '9mp',
  'ABC' = 'abc',
  'AC_UNIT' = 'ac_unit',
  'ACCESS_ALARM' = 'access_alarm',
  'ACCESS_ALARMS' = 'access_alarms',
  'ACCESS_TIME' = 'access_time',
  'ACCESS_TIME_FILLED' = 'access_time_filled',
  'ACCESSIBILITY' = 'accessibility',
  'ACCESSIBILITY_NEW' = 'accessibility_new',
  'ACCESSIBLE' = 'accessible',
  'ACCESSIBLE_FORWARD' = 'accessible_forward',
  'ACCOUNT_BALANCE' = 'account_balance',
  'ACCOUNT_BALANCE_WALLET' = 'account_balance_wallet',
  'ACCOUNT_BOX' = 'account_box',
  'ACCOUNT_CIRCLE' = 'account_circle',
  'ACCOUNT_TREE' = 'account_tree',
  'AD_UNITS' = 'ad_units',
  'ADB' = 'adb',
  'ADD' = 'add',
  'ADD_A_PHOTO' = 'add_a_photo',
  'ADD_ALARM' = 'add_alarm',
  'ADD_ALERT' = 'add_alert',
  'ADD_BOX' = 'add_box',
  'ADD_BUSINESS' = 'add_business',
  'ADD_CALL' = 'add_call',
  'ADD_CARD' = 'add_card',
  'ADD_CHART' = 'add_chart',
  'ADD_CIRCLE' = 'add_circle',
  'ADD_CIRCLE_OUTLINE' = 'add_circle_outline',
  'ADD_COMMENT' = 'add_comment',
  'ADD_HOME' = 'add_home',
  'ADD_HOME_WORK' = 'add_home_work',
  'ADD_IC_CALL' = 'add_ic_call',
  'ADD_LINK' = 'add_link',
  'ADD_LOCATION' = 'add_location',
  'ADD_LOCATION_ALT' = 'add_location_alt',
  'ADD_MODERATOR' = 'add_moderator',
  'ADD_PHOTO_ALTERNATE' = 'add_photo_alternate',
  'ADD_REACTION' = 'add_reaction',
  'ADD_ROAD' = 'add_road',
  'ADD_SHOPPING_CART' = 'add_shopping_cart',
  'ADD_TASK' = 'add_task',
  'ADD_TO_DRIVE' = 'add_to_drive',
  'ADD_TO_HOME_SCREEN' = 'add_to_home_screen',
  'ADD_TO_PHOTOS' = 'add_to_photos',
  'ADD_TO_QUEUE' = 'add_to_queue',
  'ADDCHART' = 'addchart',
  'ADF_SCANNER' = 'adf_scanner',
  'ADJUST' = 'adjust',
  'ADMIN_PANEL_SETTINGS' = 'admin_panel_settings',
  'ADOBE' = 'adobe',
  'ADS_CLICK' = 'ads_click',
  'AGRICULTURE' = 'agriculture',
  'AIR' = 'air',
  'AIRLINE_SEAT_FLAT' = 'airline_seat_flat',
  'AIRLINE_SEAT_FLAT_ANGLED' = 'airline_seat_flat_angled',
  'AIRLINE_SEAT_INDIVIDUAL_SUITE' = 'airline_seat_individual_suite',
  'AIRLINE_SEAT_LEGROOM_EXTRA' = 'airline_seat_legroom_extra',
  'AIRLINE_SEAT_LEGROOM_NORMAL' = 'airline_seat_legroom_normal',
  'AIRLINE_SEAT_LEGROOM_REDUCED' = 'airline_seat_legroom_reduced',
  'AIRLINE_SEAT_RECLINE_EXTRA' = 'airline_seat_recline_extra',
  'AIRLINE_SEAT_RECLINE_NORMAL' = 'airline_seat_recline_normal',
  'AIRLINE_STOPS' = 'airline_stops',
  'AIRLINES' = 'airlines',
  'AIRPLANE_TICKET' = 'airplane_ticket',
  'AIRPLANEMODE_ACTIVE' = 'airplanemode_active',
  'AIRPLANEMODE_INACTIVE' = 'airplanemode_inactive',
  'AIRPLANEMODE_OFF' = 'airplanemode_off',
  'AIRPLANEMODE_ON' = 'airplanemode_on',
  'AIRPLAY' = 'airplay',
  'AIRPORT_SHUTTLE' = 'airport_shuttle',
  'ALARM' = 'alarm',
  'ALARM_ADD' = 'alarm_add',
  'ALARM_OFF' = 'alarm_off',
  'ALARM_ON' = 'alarm_on',
  'ALBUM' = 'album',
  'ALIGN_HORIZONTAL_CENTER' = 'align_horizontal_center',
  'ALIGN_HORIZONTAL_LEFT' = 'align_horizontal_left',
  'ALIGN_HORIZONTAL_RIGHT' = 'align_horizontal_right',
  'ALIGN_VERTICAL_BOTTOM' = 'align_vertical_bottom',
  'ALIGN_VERTICAL_CENTER' = 'align_vertical_center',
  'ALIGN_VERTICAL_TOP' = 'align_vertical_top',
  'ALL_INBOX' = 'all_inbox',
  'ALL_INCLUSIVE' = 'all_inclusive',
  'ALL_OUT' = 'all_out',
  'ALT_ROUTE' = 'alt_route',
  'ALTERNATE_EMAIL' = 'alternate_email',
  'AMP_STORIES' = 'amp_stories',
  'ANALYTICS' = 'analytics',
  'ANCHOR' = 'anchor',
  'ANDROID' = 'android',
  'ANIMATION' = 'animation',
  'ANNOUNCEMENT' = 'announcement',
  'AOD' = 'aod',
  'APARTMENT' = 'apartment',
  'API' = 'api',
  'APP_BLOCKING' = 'app_blocking',
  'APP_REGISTRATION' = 'app_registration',
  'APP_SETTINGS_ALT' = 'app_settings_alt',
  'APP_SHORTCUT' = 'app_shortcut',
  'APPLE' = 'apple',
  'APPROVAL' = 'approval',
  'APPS' = 'apps',
  'APPS_OUTAGE' = 'apps_outage',
  'ARCHITECTURE' = 'architecture',
  'ARCHIVE' = 'archive',
  'AREA_CHART' = 'area_chart',
  'ARROW_BACK' = 'arrow_back',
  'ARROW_BACK_IOS' = 'arrow_back_ios',
  'ARROW_BACK_IOS_NEW' = 'arrow_back_ios_new',
  'ARROW_CIRCLE_DOWN' = 'arrow_circle_down',
  'ARROW_CIRCLE_LEFT' = 'arrow_circle_left',
  'ARROW_CIRCLE_RIGHT' = 'arrow_circle_right',
  'ARROW_CIRCLE_UP' = 'arrow_circle_up',
  'ARROW_DOWNWARD' = 'arrow_downward',
  'ARROW_DROP_DOWN' = 'arrow_drop_down',
  'ARROW_DROP_DOWN_CIRCLE' = 'arrow_drop_down_circle',
  'ARROW_DROP_UP' = 'arrow_drop_up',
  'ARROW_FORWARD' = 'arrow_forward',
  'ARROW_FORWARD_IOS' = 'arrow_forward_ios',
  'ARROW_LEFT' = 'arrow_left',
  'ARROW_RIGHT' = 'arrow_right',
  'ARROW_RIGHT_ALT' = 'arrow_right_alt',
  'ARROW_UPWARD' = 'arrow_upward',
  'ART_TRACK' = 'art_track',
  'ARTICLE' = 'article',
  'ASPECT_RATIO' = 'aspect_ratio',
  'ASSESSMENT' = 'assessment',
  'ASSIGNMENT' = 'assignment',
  'ASSIGNMENT_IND' = 'assignment_ind',
  'ASSIGNMENT_LATE' = 'assignment_late',
  'ASSIGNMENT_RETURN' = 'assignment_return',
  'ASSIGNMENT_RETURNED' = 'assignment_returned',
  'ASSIGNMENT_TURNED_IN' = 'assignment_turned_in',
  'ASSISTANT' = 'assistant',
  'ASSISTANT_DIRECTION' = 'assistant_direction',
  'ASSISTANT_NAVIGATION' = 'assistant_navigation',
  'ASSISTANT_PHOTO' = 'assistant_photo',
  'ASSURED_WORKLOAD' = 'assured_workload',
  'ATM' = 'atm',
  'ATTACH_EMAIL' = 'attach_email',
  'ATTACH_FILE' = 'attach_file',
  'ATTACH_MONEY' = 'attach_money',
  'ATTACHMENT' = 'attachment',
  'ATTRACTIONS' = 'attractions',
  'ATTRIBUTION' = 'attribution',
  'AUDIO_FILE' = 'audio_file',
  'AUDIOTRACK' = 'audiotrack',
  'AUTO_AWESOME' = 'auto_awesome',
  'AUTO_AWESOME_MOSAIC' = 'auto_awesome_mosaic',
  'AUTO_AWESOME_MOTION' = 'auto_awesome_motion',
  'AUTO_DELETE' = 'auto_delete',
  'AUTO_FIX_HIGH' = 'auto_fix_high',
  'AUTO_FIX_NORMAL' = 'auto_fix_normal',
  'AUTO_FIX_OFF' = 'auto_fix_off',
  'AUTO_GRAPH' = 'auto_graph',
  'AUTO_MODE' = 'auto_mode',
  'AUTO_STORIES' = 'auto_stories',
  'AUTOFPS_SELECT' = 'autofps_select',
  'AUTORENEW' = 'autorenew',
  'AV_TIMER' = 'av_timer',
  'BABY_CHANGING_STATION' = 'baby_changing_station',
  'BACK_HAND' = 'back_hand',
  'BACKPACK' = 'backpack',
  'BACKSPACE' = 'backspace',
  'BACKUP' = 'backup',
  'BACKUP_TABLE' = 'backup_table',
  'BADGE' = 'badge',
  'BAKERY_DINING' = 'bakery_dining',
  'BALANCE' = 'balance',
  'BALCONY' = 'balcony',
  'BALLOT' = 'ballot',
  'BAR_CHART' = 'bar_chart',
  'BATCH_PREDICTION' = 'batch_prediction',
  'BATHROOM' = 'bathroom',
  'BATHTUB' = 'bathtub',
  'BATTERY_0_BAR' = 'battery_0_bar',
  'BATTERY_1_BAR' = 'battery_1_bar',
  'BATTERY_2_BAR' = 'battery_2_bar',
  'BATTERY_3_BAR' = 'battery_3_bar',
  'BATTERY_4_BAR' = 'battery_4_bar',
  'BATTERY_5_BAR' = 'battery_5_bar',
  'BATTERY_6_BAR' = 'battery_6_bar',
  'BATTERY_ALERT' = 'battery_alert',
  'BATTERY_CHARGING_FULL' = 'battery_charging_full',
  'BATTERY_FULL' = 'battery_full',
  'BATTERY_SAVER' = 'battery_saver',
  'BATTERY_STD' = 'battery_std',
  'BATTERY_UNKNOWN' = 'battery_unknown',
  'BEACH_ACCESS' = 'beach_access',
  'BED' = 'bed',
  'BEDROOM_BABY' = 'bedroom_baby',
  'BEDROOM_CHILD' = 'bedroom_child',
  'BEDROOM_PARENT' = 'bedroom_parent',
  'BEDTIME' = 'bedtime',
  'BEDTIME_OFF' = 'bedtime_off',
  'BEDTIME_OUTLINED' = 'bedtime_outlined',
  'BEENHERE' = 'beenhere',
  'BENTO' = 'bento',
  'BIKE_SCOOTER' = 'bike_scooter',
  'BIOTECH' = 'biotech',
  'BLENDER' = 'blender',
  'BLINDS' = 'blinds',
  'BLINDS_CLOSED' = 'blinds_closed',
  'BLOCK' = 'block',
  'BLOCK_FLIPPED' = 'block_flipped',
  'BLOODTYPE' = 'bloodtype',
  'BLUETOOTH' = 'bluetooth',
  'BLUETOOTH_AUDIO' = 'bluetooth_audio',
  'BLUETOOTH_CONNECTED' = 'bluetooth_connected',
  'BLUETOOTH_DISABLED' = 'bluetooth_disabled',
  'BLUETOOTH_DRIVE' = 'bluetooth_drive',
  'BLUETOOTH_SEARCHING' = 'bluetooth_searching',
  'BLUR_CIRCULAR' = 'blur_circular',
  'BLUR_LINEAR' = 'blur_linear',
  'BLUR_OFF' = 'blur_off',
  'BLUR_ON' = 'blur_on',
  'BOLT' = 'bolt',
  'BOOK' = 'book',
  'BOOK_ONLINE' = 'book_online',
  'BOOKMARK' = 'bookmark',
  'BOOKMARK_ADD' = 'bookmark_add',
  'BOOKMARK_ADDED' = 'bookmark_added',
  'BOOKMARK_BORDER' = 'bookmark_border',
  'BOOKMARK_OUTLINE' = 'bookmark_outline',
  'BOOKMARK_REMOVE' = 'bookmark_remove',
  'BOOKMARKS' = 'bookmarks',
  'BORDER_ALL' = 'border_all',
  'BORDER_BOTTOM' = 'border_bottom',
  'BORDER_CLEAR' = 'border_clear',
  'BORDER_COLOR' = 'border_color',
  'BORDER_HORIZONTAL' = 'border_horizontal',
  'BORDER_INNER' = 'border_inner',
  'BORDER_LEFT' = 'border_left',
  'BORDER_OUTER' = 'border_outer',
  'BORDER_RIGHT' = 'border_right',
  'BORDER_STYLE' = 'border_style',
  'BORDER_TOP' = 'border_top',
  'BORDER_VERTICAL' = 'border_vertical',
  'BOY' = 'boy',
  'BRANDING_WATERMARK' = 'branding_watermark',
  'BREAKFAST_DINING' = 'breakfast_dining',
  'BRIGHTNESS_1' = 'brightness_1',
  'BRIGHTNESS_2' = 'brightness_2',
  'BRIGHTNESS_3' = 'brightness_3',
  'BRIGHTNESS_4' = 'brightness_4',
  'BRIGHTNESS_5' = 'brightness_5',
  'BRIGHTNESS_6' = 'brightness_6',
  'BRIGHTNESS_7' = 'brightness_7',
  'BRIGHTNESS_AUTO' = 'brightness_auto',
  'BRIGHTNESS_HIGH' = 'brightness_high',
  'BRIGHTNESS_LOW' = 'brightness_low',
  'BRIGHTNESS_MEDIUM' = 'brightness_medium',
  'BROADCAST_ON_HOME' = 'broadcast_on_home',
  'BROADCAST_ON_PERSONAL' = 'broadcast_on_personal',
  'BROKEN_IMAGE' = 'broken_image',
  'BROWSE_GALLERY' = 'browse_gallery',
  'BROWSER_NOT_SUPPORTED' = 'browser_not_supported',
  'BROWSER_UPDATED' = 'browser_updated',
  'BRUNCH_DINING' = 'brunch_dining',
  'BRUSH' = 'brush',
  'BUBBLE_CHART' = 'bubble_chart',
  'BUG_REPORT' = 'bug_report',
  'BUILD' = 'build',
  'BUILD_CIRCLE' = 'build_circle',
  'BUNGALOW' = 'bungalow',
  'BURST_MODE' = 'burst_mode',
  'BUS_ALERT' = 'bus_alert',
  'BUSINESS' = 'business',
  'BUSINESS_CENTER' = 'business_center',
  'CABIN' = 'cabin',
  'CABLE' = 'cable',
  'CACHED' = 'cached',
  'CAKE' = 'cake',
  'CALCULATE' = 'calculate',
  'CALENDAR_MONTH' = 'calendar_month',
  'CALENDAR_TODAY' = 'calendar_today',
  'CALENDAR_VIEW_DAY' = 'calendar_view_day',
  'CALENDAR_VIEW_MONTH' = 'calendar_view_month',
  'CALENDAR_VIEW_WEEK' = 'calendar_view_week',
  'CALL' = 'call',
  'CALL_END' = 'call_end',
  'CALL_MADE' = 'call_made',
  'CALL_MERGE' = 'call_merge',
  'CALL_MISSED' = 'call_missed',
  'CALL_MISSED_OUTGOING' = 'call_missed_outgoing',
  'CALL_RECEIVED' = 'call_received',
  'CALL_SPLIT' = 'call_split',
  'CALL_TO_ACTION' = 'call_to_action',
  'CAMERA' = 'camera',
  'CAMERA_ALT' = 'camera_alt',
  'CAMERA_ENHANCE' = 'camera_enhance',
  'CAMERA_FRONT' = 'camera_front',
  'CAMERA_INDOOR' = 'camera_indoor',
  'CAMERA_OUTDOOR' = 'camera_outdoor',
  'CAMERA_REAR' = 'camera_rear',
  'CAMERA_ROLL' = 'camera_roll',
  'CAMERASWITCH' = 'cameraswitch',
  'CAMPAIGN' = 'campaign',
  'CANCEL' = 'cancel',
  'CANCEL_PRESENTATION' = 'cancel_presentation',
  'CANCEL_SCHEDULE_SEND' = 'cancel_schedule_send',
  'CANDLESTICK_CHART' = 'candlestick_chart',
  'CAR_CRASH' = 'car_crash',
  'CAR_RENTAL' = 'car_rental',
  'CAR_REPAIR' = 'car_repair',
  'CARD_GIFTCARD' = 'card_giftcard',
  'CARD_MEMBERSHIP' = 'card_membership',
  'CARD_TRAVEL' = 'card_travel',
  'CARPENTER' = 'carpenter',
  'CASES' = 'cases',
  'CASINO' = 'casino',
  'CAST' = 'cast',
  'CAST_CONNECTED' = 'cast_connected',
  'CAST_FOR_EDUCATION' = 'cast_for_education',
  'CASTLE' = 'castle',
  'CATCHING_POKEMON' = 'catching_pokemon',
  'CATEGORY' = 'category',
  'CELEBRATION' = 'celebration',
  'CELL_TOWER' = 'cell_tower',
  'CELL_WIFI' = 'cell_wifi',
  'CENTER_FOCUS_STRONG' = 'center_focus_strong',
  'CENTER_FOCUS_WEAK' = 'center_focus_weak',
  'CHAIR' = 'chair',
  'CHAIR_ALT' = 'chair_alt',
  'CHALET' = 'chalet',
  'CHANGE_CIRCLE' = 'change_circle',
  'CHANGE_HISTORY' = 'change_history',
  'CHARGING_STATION' = 'charging_station',
  'CHAT' = 'chat',
  'CHAT_BUBBLE' = 'chat_bubble',
  'CHAT_BUBBLE_OUTLINE' = 'chat_bubble_outline',
  'CHECK' = 'check',
  'CHECK_BOX' = 'check_box',
  'CHECK_BOX_OUTLINE_BLANK' = 'check_box_outline_blank',
  'CHECK_CIRCLE' = 'check_circle',
  'CHECK_CIRCLE_OUTLINE' = 'check_circle_outline',
  'CHECKLIST' = 'checklist',
  'CHECKLIST_RTL' = 'checklist_rtl',
  'CHECKROOM' = 'checkroom',
  'CHEVRON_LEFT' = 'chevron_left',
  'CHEVRON_RIGHT' = 'chevron_right',
  'CHILD_CARE' = 'child_care',
  'CHILD_FRIENDLY' = 'child_friendly',
  'CHROME_READER_MODE' = 'chrome_reader_mode',
  'CHURCH' = 'church',
  'CIRCLE' = 'circle',
  'CIRCLE_NOTIFICATIONS' = 'circle_notifications',
  'CLASS' = 'class',
  'CLEAN_HANDS' = 'clean_hands',
  'CLEANING_SERVICES' = 'cleaning_services',
  'CLEAR' = 'clear',
  'CLEAR_ALL' = 'clear_all',
  'CLOSE' = 'close',
  'CLOSE_FULLSCREEN' = 'close_fullscreen',
  'CLOSED_CAPTION' = 'closed_caption',
  'CLOSED_CAPTION_DISABLED' = 'closed_caption_disabled',
  'CLOSED_CAPTION_OFF' = 'closed_caption_off',
  'CLOUD' = 'cloud',
  'CLOUD_CIRCLE' = 'cloud_circle',
  'CLOUD_DONE' = 'cloud_done',
  'CLOUD_DOWNLOAD' = 'cloud_download',
  'CLOUD_OFF' = 'cloud_off',
  'CLOUD_QUEUE' = 'cloud_queue',
  'CLOUD_SYNC' = 'cloud_sync',
  'CLOUD_UPLOAD' = 'cloud_upload',
  'CLOUDY_SNOWING' = 'cloudy_snowing',
  'CO2' = 'co2',
  'CO_PRESENT' = 'co_present',
  'CODE' = 'code',
  'CODE_OFF' = 'code_off',
  'COFFEE' = 'coffee',
  'COFFEE_MAKER' = 'coffee_maker',
  'COLLECTIONS' = 'collections',
  'COLLECTIONS_BOOKMARK' = 'collections_bookmark',
  'COLOR_LENS' = 'color_lens',
  'COLORIZE' = 'colorize',
  'COMMENT' = 'comment',
  'COMMENT_BANK' = 'comment_bank',
  'COMMENTS_DISABLED' = 'comments_disabled',
  'COMMIT' = 'commit',
  'COMMUTE' = 'commute',
  'COMPARE' = 'compare',
  'COMPARE_ARROWS' = 'compare_arrows',
  'COMPASS_CALIBRATION' = 'compass_calibration',
  'COMPOST' = 'compost',
  'COMPRESS' = 'compress',
  'COMPUTER' = 'computer',
  'CONFIRMATION_NUM' = 'confirmation_num',
  'CONFIRMATION_NUMBER' = 'confirmation_number',
  'CONNECT_WITHOUT_CONTACT' = 'connect_without_contact',
  'CONNECTED_TV' = 'connected_tv',
  'CONNECTING_AIRPORTS' = 'connecting_airports',
  'CONSTRUCTION' = 'construction',
  'CONTACT_MAIL' = 'contact_mail',
  'CONTACT_PAGE' = 'contact_page',
  'CONTACT_PHONE' = 'contact_phone',
  'CONTACT_SUPPORT' = 'contact_support',
  'CONTACTLESS' = 'contactless',
  'CONTACTS' = 'contacts',
  'CONTENT_COPY' = 'content_copy',
  'CONTENT_CUT' = 'content_cut',
  'CONTENT_PASTE' = 'content_paste',
  'CONTENT_PASTE_GO' = 'content_paste_go',
  'CONTENT_PASTE_OFF' = 'content_paste_off',
  'CONTENT_PASTE_SEARCH' = 'content_paste_search',
  'CONTRAST' = 'contrast',
  'CONTROL_CAMERA' = 'control_camera',
  'CONTROL_POINT' = 'control_point',
  'CONTROL_POINT_DUPLICATE' = 'control_point_duplicate',
  'COOKIE' = 'cookie',
  'COPY_ALL' = 'copy_all',
  'COPYRIGHT' = 'copyright',
  'CORONAVIRUS' = 'coronavirus',
  'CORPORATE_FARE' = 'corporate_fare',
  'COTTAGE' = 'cottage',
  'COUNTERTOPS' = 'countertops',
  'CREATE' = 'create',
  'CREATE_NEW_FOLDER' = 'create_new_folder',
  'CREDIT_CARD' = 'credit_card',
  'CREDIT_CARD_OFF' = 'credit_card_off',
  'CREDIT_SCORE' = 'credit_score',
  'CRIB' = 'crib',
  'CRISIS_ALERT' = 'crisis_alert',
  'CROP' = 'crop',
  'CROP_16_9' = 'crop_16_9',
  'CROP_3_2' = 'crop_3_2',
  'CROP_5_4' = 'crop_5_4',
  'CROP_7_5' = 'crop_7_5',
  'CROP_DIN' = 'crop_din',
  'CROP_FREE' = 'crop_free',
  'CROP_LANDSCAPE' = 'crop_landscape',
  'CROP_ORIGINAL' = 'crop_original',
  'CROP_PORTRAIT' = 'crop_portrait',
  'CROP_ROTATE' = 'crop_rotate',
  'CROP_SQUARE' = 'crop_square',
  'CRUELTY_FREE' = 'cruelty_free',
  'CSS' = 'css',
  'CURRENCY_BITCOIN' = 'currency_bitcoin',
  'CURRENCY_EXCHANGE' = 'currency_exchange',
  'CURRENCY_FRANC' = 'currency_franc',
  'CURRENCY_LIRA' = 'currency_lira',
  'CURRENCY_POUND' = 'currency_pound',
  'CURRENCY_RUBLE' = 'currency_ruble',
  'CURRENCY_RUPEE' = 'currency_rupee',
  'CURRENCY_YEN' = 'currency_yen',
  'CURRENCY_YUAN' = 'currency_yuan',
  'CURTAINS' = 'curtains',
  'CURTAINS_CLOSED' = 'curtains_closed',
  'CYCLONE' = 'cyclone',
  'DANGEROUS' = 'dangerous',
  'DARK_MODE' = 'dark_mode',
  'DASHBOARD' = 'dashboard',
  'DASHBOARD_CUSTOMIZE' = 'dashboard_customize',
  'DATA_ARRAY' = 'data_array',
  'DATA_EXPLORATION' = 'data_exploration',
  'DATA_OBJECT' = 'data_object',
  'DATA_SAVER_OFF' = 'data_saver_off',
  'DATA_SAVER_ON' = 'data_saver_on',
  'DATA_THRESHOLDING' = 'data_thresholding',
  'DATA_USAGE' = 'data_usage',
  'DATASET' = 'dataset',
  'DATASET_LINKED' = 'dataset_linked',
  'DATE_RANGE' = 'date_range',
  'DEBLUR' = 'deblur',
  'DECK' = 'deck',
  'DEHAZE' = 'dehaze',
  'DELETE' = 'delete',
  'DELETE_FOREVER' = 'delete_forever',
  'DELETE_OUTLINE' = 'delete_outline',
  'DELETE_SWEEP' = 'delete_sweep',
  'DELIVERY_DINING' = 'delivery_dining',
  'DENSITY_LARGE' = 'density_large',
  'DENSITY_MEDIUM' = 'density_medium',
  'DENSITY_SMALL' = 'density_small',
  'DEPARTURE_BOARD' = 'departure_board',
  'DESCRIPTION' = 'description',
  'DESELECT' = 'deselect',
  'DESIGN_SERVICES' = 'design_services',
  'DESK' = 'desk',
  'DESKTOP_ACCESS_DISABLED' = 'desktop_access_disabled',
  'DESKTOP_MAC' = 'desktop_mac',
  'DESKTOP_WINDOWS' = 'desktop_windows',
  'DETAILS' = 'details',
  'DEVELOPER_BOARD' = 'developer_board',
  'DEVELOPER_BOARD_OFF' = 'developer_board_off',
  'DEVELOPER_MODE' = 'developer_mode',
  'DEVICE_HUB' = 'device_hub',
  'DEVICE_THERMOSTAT' = 'device_thermostat',
  'DEVICE_UNKNOWN' = 'device_unknown',
  'DEVICES' = 'devices',
  'DEVICES_FOLD' = 'devices_fold',
  'DEVICES_OTHER' = 'devices_other',
  'DIALER_SIP' = 'dialer_sip',
  'DIALPAD' = 'dialpad',
  'DIAMOND' = 'diamond',
  'DIFFERENCE' = 'difference',
  'DINING' = 'dining',
  'DINNER_DINING' = 'dinner_dining',
  'DIRECTIONS' = 'directions',
  'DIRECTIONS_BIKE' = 'directions_bike',
  'DIRECTIONS_BOAT' = 'directions_boat',
  'DIRECTIONS_BOAT_FILLED' = 'directions_boat_filled',
  'DIRECTIONS_BUS' = 'directions_bus',
  'DIRECTIONS_BUS_FILLED' = 'directions_bus_filled',
  'DIRECTIONS_CAR' = 'directions_car',
  'DIRECTIONS_CAR_FILLED' = 'directions_car_filled',
  'DIRECTIONS_FERRY' = 'directions_ferry',
  'DIRECTIONS_OFF' = 'directions_off',
  'DIRECTIONS_RAILWAY' = 'directions_railway',
  'DIRECTIONS_RAILWAY_FILLED' = 'directions_railway_filled',
  'DIRECTIONS_RUN' = 'directions_run',
  'DIRECTIONS_SUBWAY' = 'directions_subway',
  'DIRECTIONS_SUBWAY_FILLED' = 'directions_subway_filled',
  'DIRECTIONS_TRAIN' = 'directions_train',
  'DIRECTIONS_TRANSIT' = 'directions_transit',
  'DIRECTIONS_TRANSIT_FILLED' = 'directions_transit_filled',
  'DIRECTIONS_WALK' = 'directions_walk',
  'DIRTY_LENS' = 'dirty_lens',
  'DISABLED_BY_DEFAULT' = 'disabled_by_default',
  'DISABLED_VISIBLE' = 'disabled_visible',
  'DISC_FULL' = 'disc_full',
  'DISCORD' = 'discord',
  'DISCOUNT' = 'discount',
  'DISPLAY_SETTINGS' = 'display_settings',
  'DND_FORWARDSLASH' = 'dnd_forwardslash',
  'DNS' = 'dns',
  'DO_DISTURB' = 'do_disturb',
  'DO_DISTURB_ALT' = 'do_disturb_alt',
  'DO_DISTURB_OFF' = 'do_disturb_off',
  'DO_DISTURB_ON' = 'do_disturb_on',
  'DO_NOT_DISTURB' = 'do_not_disturb',
  'DO_NOT_DISTURB_ALT' = 'do_not_disturb_alt',
  'DO_NOT_DISTURB_OFF' = 'do_not_disturb_off',
  'DO_NOT_DISTURB_ON' = 'do_not_disturb_on',
  'DO_NOT_DISTURB_ON_TOTAL_SILENCE' = 'do_not_disturb_on_total_silence',
  'DO_NOT_STEP' = 'do_not_step',
  'DO_NOT_TOUCH' = 'do_not_touch',
  'DOCK' = 'dock',
  'DOCUMENT_SCANNER' = 'document_scanner',
  'DOMAIN' = 'domain',
  'DOMAIN_ADD' = 'domain_add',
  'DOMAIN_DISABLED' = 'domain_disabled',
  'DOMAIN_VERIFICATION' = 'domain_verification',
  'DONE' = 'done',
  'DONE_ALL' = 'done_all',
  'DONE_OUTLINE' = 'done_outline',
  'DONUT_LARGE' = 'donut_large',
  'DONUT_SMALL' = 'donut_small',
  'DOOR_BACK' = 'door_back',
  'DOOR_FRONT' = 'door_front',
  'DOOR_SLIDING' = 'door_sliding',
  'DOORBELL' = 'doorbell',
  'DOUBLE_ARROW' = 'double_arrow',
  'DOWNHILL_SKIING' = 'downhill_skiing',
  'DOWNLOAD' = 'download',
  'DOWNLOAD_DONE' = 'download_done',
  'DOWNLOAD_FOR_OFFLINE' = 'download_for_offline',
  'DOWNLOADING' = 'downloading',
  'DRAFTS' = 'drafts',
  'DRAG_HANDLE' = 'drag_handle',
  'DRAG_INDICATOR' = 'drag_indicator',
  'DRAW' = 'draw',
  'DRIVE_ETA' = 'drive_eta',
  'DRIVE_FILE_MOVE' = 'drive_file_move',
  'DRIVE_FILE_MOVE_OUTLINE' = 'drive_file_move_outline',
  'DRIVE_FILE_MOVE_RTL' = 'drive_file_move_rtl',
  'DRIVE_FILE_RENAME_OUTLINE' = 'drive_file_rename_outline',
  'DRIVE_FOLDER_UPLOAD' = 'drive_folder_upload',
  'DRY' = 'dry',
  'DRY_CLEANING' = 'dry_cleaning',
  'DUO' = 'duo',
  'DVR' = 'dvr',
  'DYNAMIC_FEED' = 'dynamic_feed',
  'DYNAMIC_FORM' = 'dynamic_form',
  'E_MOBILEDATA' = 'e_mobiledata',
  'EARBUDS' = 'earbuds',
  'EARBUDS_BATTERY' = 'earbuds_battery',
  'EAST' = 'east',
  'ECO' = 'eco',
  'EDGESENSOR_HIGH' = 'edgesensor_high',
  'EDGESENSOR_LOW' = 'edgesensor_low',
  'EDIT' = 'edit',
  'EDIT_ATTRIBUTES' = 'edit_attributes',
  'EDIT_CALENDAR' = 'edit_calendar',
  'EDIT_LOCATION' = 'edit_location',
  'EDIT_LOCATION_ALT' = 'edit_location_alt',
  'EDIT_NOTE' = 'edit_note',
  'EDIT_NOTIFICATIONS' = 'edit_notifications',
  'EDIT_OFF' = 'edit_off',
  'EDIT_ROAD' = 'edit_road',
  'EGG' = 'egg',
  'EGG_ALT' = 'egg_alt',
  'EJECT' = 'eject',
  'ELDERLY' = 'elderly',
  'ELDERLY_WOMAN' = 'elderly_woman',
  'ELECTRIC_BIKE' = 'electric_bike',
  'ELECTRIC_BOLT' = 'electric_bolt',
  'ELECTRIC_CAR' = 'electric_car',
  'ELECTRIC_METER' = 'electric_meter',
  'ELECTRIC_MOPED' = 'electric_moped',
  'ELECTRIC_RICKSHAW' = 'electric_rickshaw',
  'ELECTRIC_SCOOTER' = 'electric_scooter',
  'ELECTRICAL_SERVICES' = 'electrical_services',
  'ELEVATOR' = 'elevator',
  'EMAIL' = 'email',
  'EMERGENCY' = 'emergency',
  'EMERGENCY_RECORDING' = 'emergency_recording',
  'EMERGENCY_SHARE' = 'emergency_share',
  'EMOJI_EMOTIONS' = 'emoji_emotions',
  'EMOJI_EVENTS' = 'emoji_events',
  'EMOJI_FLAGS' = 'emoji_flags',
  'EMOJI_FOOD_BEVERAGE' = 'emoji_food_beverage',
  'EMOJI_NATURE' = 'emoji_nature',
  'EMOJI_OBJECTS' = 'emoji_objects',
  'EMOJI_PEOPLE' = 'emoji_people',
  'EMOJI_SYMBOLS' = 'emoji_symbols',
  'EMOJI_TRANSPORTATION' = 'emoji_transportation',
  'ENERGY_SAVINGS_LEAF' = 'energy_savings_leaf',
  'ENGINEERING' = 'engineering',
  'ENHANCE_PHOTO_TRANSLATE' = 'enhance_photo_translate',
  'ENHANCED_ENCRYPTION' = 'enhanced_encryption',
  'EQUALIZER' = 'equalizer',
  'ERROR' = 'error',
  'ERROR_OUTLINE' = 'error_outline',
  'ESCALATOR' = 'escalator',
  'ESCALATOR_WARNING' = 'escalator_warning',
  'EURO' = 'euro',
  'EURO_SYMBOL' = 'euro_symbol',
  'EV_STATION' = 'ev_station',
  'EVENT' = 'event',
  'EVENT_AVAILABLE' = 'event_available',
  'EVENT_BUSY' = 'event_busy',
  'EVENT_NOTE' = 'event_note',
  'EVENT_REPEAT' = 'event_repeat',
  'EVENT_SEAT' = 'event_seat',
  'EXIT_TO_APP' = 'exit_to_app',
  'EXPAND' = 'expand',
  'EXPAND_CIRCLE_DOWN' = 'expand_circle_down',
  'EXPAND_LESS' = 'expand_less',
  'EXPAND_MORE' = 'expand_more',
  'EXPLICIT' = 'explicit',
  'EXPLORE' = 'explore',
  'EXPLORE_OFF' = 'explore_off',
  'EXPOSURE' = 'exposure',
  'EXPOSURE_MINUS_1' = 'exposure_minus_1',
  'EXPOSURE_MINUS_2' = 'exposure_minus_2',
  'EXPOSURE_NEG_1' = 'exposure_neg_1',
  'EXPOSURE_NEG_2' = 'exposure_neg_2',
  'EXPOSURE_PLUS_1' = 'exposure_plus_1',
  'EXPOSURE_PLUS_2' = 'exposure_plus_2',
  'EXPOSURE_ZERO' = 'exposure_zero',
  'EXTENSION' = 'extension',
  'EXTENSION_OFF' = 'extension_off',
  'FACE' = 'face',
  'FACE_RETOUCHING_NATURAL' = 'face_retouching_natural',
  'FACE_RETOUCHING_OFF' = 'face_retouching_off',
  'FACEBOOK' = 'facebook',
  'FACT_CHECK' = 'fact_check',
  'FACTORY' = 'factory',
  'FAMILY_RESTROOM' = 'family_restroom',
  'FAST_FORWARD' = 'fast_forward',
  'FAST_REWIND' = 'fast_rewind',
  'FASTFOOD' = 'fastfood',
  'FAVORITE' = 'favorite',
  'FAVORITE_BORDER' = 'favorite_border',
  'FAVORITE_OUTLINE' = 'favorite_outline',
  'FAX' = 'fax',
  'FEATURED_PLAY_LIST' = 'featured_play_list',
  'FEATURED_VIDEO' = 'featured_video',
  'FEED' = 'feed',
  'FEEDBACK' = 'feedback',
  'FEMALE' = 'female',
  'FENCE' = 'fence',
  'FESTIVAL' = 'festival',
  'FIBER_DVR' = 'fiber_dvr',
  'FIBER_MANUAL_RECORD' = 'fiber_manual_record',
  'FIBER_NEW' = 'fiber_new',
  'FIBER_PIN' = 'fiber_pin',
  'FIBER_SMART_RECORD' = 'fiber_smart_record',
  'FILE_COPY' = 'file_copy',
  'FILE_DOWNLOAD' = 'file_download',
  'FILE_DOWNLOAD_DONE' = 'file_download_done',
  'FILE_DOWNLOAD_OFF' = 'file_download_off',
  'FILE_OPEN' = 'file_open',
  'FILE_PRESENT' = 'file_present',
  'FILE_UPLOAD' = 'file_upload',
  'FILTER' = 'filter',
  'FILTER_1' = 'filter_1',
  'FILTER_2' = 'filter_2',
  'FILTER_3' = 'filter_3',
  'FILTER_4' = 'filter_4',
  'FILTER_5' = 'filter_5',
  'FILTER_6' = 'filter_6',
  'FILTER_7' = 'filter_7',
  'FILTER_8' = 'filter_8',
  'FILTER_9' = 'filter_9',
  'FILTER_9_PLUS' = 'filter_9_plus',
  'FILTER_ALT' = 'filter_alt',
  'FILTER_ALT_OFF' = 'filter_alt_off',
  'FILTER_B_AND_W' = 'filter_b_and_w',
  'FILTER_CENTER_FOCUS' = 'filter_center_focus',
  'FILTER_DRAMA' = 'filter_drama',
  'FILTER_FRAMES' = 'filter_frames',
  'FILTER_HDR' = 'filter_hdr',
  'FILTER_LIST' = 'filter_list',
  'FILTER_LIST_ALT' = 'filter_list_alt',
  'FILTER_LIST_OFF' = 'filter_list_off',
  'FILTER_NONE' = 'filter_none',
  'FILTER_TILT_SHIFT' = 'filter_tilt_shift',
  'FILTER_VINTAGE' = 'filter_vintage',
  'FIND_IN_PAGE' = 'find_in_page',
  'FIND_REPLACE' = 'find_replace',
  'FINGERPRINT' = 'fingerprint',
  'FIRE_EXTINGUISHER' = 'fire_extinguisher',
  'FIRE_HYDRANT' = 'fire_hydrant',
  'FIRE_HYDRANT_ALT' = 'fire_hydrant_alt',
  'FIRE_TRUCK' = 'fire_truck',
  'FIREPLACE' = 'fireplace',
  'FIRST_PAGE' = 'first_page',
  'FIT_SCREEN' = 'fit_screen',
  'FITBIT' = 'fitbit',
  'FITNESS_CENTER' = 'fitness_center',
  'FLAG' = 'flag',
  'FLAG_CIRCLE' = 'flag_circle',
  'FLAKY' = 'flaky',
  'FLARE' = 'flare',
  'FLASH_AUTO' = 'flash_auto',
  'FLASH_OFF' = 'flash_off',
  'FLASH_ON' = 'flash_on',
  'FLASHLIGHT_OFF' = 'flashlight_off',
  'FLASHLIGHT_ON' = 'flashlight_on',
  'FLATWARE' = 'flatware',
  'FLIGHT' = 'flight',
  'FLIGHT_CLASS' = 'flight_class',
  'FLIGHT_LAND' = 'flight_land',
  'FLIGHT_TAKEOFF' = 'flight_takeoff',
  'FLIP' = 'flip',
  'FLIP_CAMERA_ANDROID' = 'flip_camera_android',
  'FLIP_CAMERA_IOS' = 'flip_camera_ios',
  'FLIP_TO_BACK' = 'flip_to_back',
  'FLIP_TO_FRONT' = 'flip_to_front',
  'FLOOD' = 'flood',
  'FLOURESCENT' = 'flourescent',
  'FLUTTER_DASH' = 'flutter_dash',
  'FMD_BAD' = 'fmd_bad',
  'FMD_GOOD' = 'fmd_good',
  'FOGGY' = 'foggy',
  'FOLDER' = 'folder',
  'FOLDER_COPY' = 'folder_copy',
  'FOLDER_DELETE' = 'folder_delete',
  'FOLDER_OFF' = 'folder_off',
  'FOLDER_OPEN' = 'folder_open',
  'FOLDER_SHARED' = 'folder_shared',
  'FOLDER_SPECIAL' = 'folder_special',
  'FOLDER_ZIP' = 'folder_zip',
  'FOLLOW_THE_SIGNS' = 'follow_the_signs',
  'FONT_DOWNLOAD' = 'font_download',
  'FONT_DOWNLOAD_OFF' = 'font_download_off',
  'FOOD_BANK' = 'food_bank',
  'FOREST' = 'forest',
  'FORK_LEFT' = 'fork_left',
  'FORK_RIGHT' = 'fork_right',
  'FORMAT_ALIGN_CENTER' = 'format_align_center',
  'FORMAT_ALIGN_JUSTIFY' = 'format_align_justify',
  'FORMAT_ALIGN_LEFT' = 'format_align_left',
  'FORMAT_ALIGN_RIGHT' = 'format_align_right',
  'FORMAT_BOLD' = 'format_bold',
  'FORMAT_CLEAR' = 'format_clear',
  'FORMAT_COLOR_FILL' = 'format_color_fill',
  'FORMAT_COLOR_RESET' = 'format_color_reset',
  'FORMAT_COLOR_TEXT' = 'format_color_text',
  'FORMAT_INDENT_DECREASE' = 'format_indent_decrease',
  'FORMAT_INDENT_INCREASE' = 'format_indent_increase',
  'FORMAT_ITALIC' = 'format_italic',
  'FORMAT_LINE_SPACING' = 'format_line_spacing',
  'FORMAT_LIST_BULLETED' = 'format_list_bulleted',
  'FORMAT_LIST_NUMBERED' = 'format_list_numbered',
  'FORMAT_LIST_NUMBERED_RTL' = 'format_list_numbered_rtl',
  'FORMAT_OVERLINE' = 'format_overline',
  'FORMAT_PAINT' = 'format_paint',
  'FORMAT_QUOTE' = 'format_quote',
  'FORMAT_SHAPES' = 'format_shapes',
  'FORMAT_SIZE' = 'format_size',
  'FORMAT_STRIKETHROUGH' = 'format_strikethrough',
  'FORMAT_TEXTDIRECTION_L_TO_R' = 'format_textdirection_l_to_r',
  'FORMAT_TEXTDIRECTION_R_TO_L' = 'format_textdirection_r_to_l',
  'FORMAT_UNDERLINE' = 'format_underline',
  'FORMAT_UNDERLINED' = 'format_underlined',
  'FORT' = 'fort',
  'FORUM' = 'forum',
  'FORWARD' = 'forward',
  'FORWARD_10' = 'forward_10',
  'FORWARD_30' = 'forward_30',
  'FORWARD_5' = 'forward_5',
  'FORWARD_TO_INBOX' = 'forward_to_inbox',
  'FOUNDATION' = 'foundation',
  'FREE_BREAKFAST' = 'free_breakfast',
  'FREE_CANCELLATION' = 'free_cancellation',
  'FRONT_HAND' = 'front_hand',
  'FULLSCREEN' = 'fullscreen',
  'FULLSCREEN_EXIT' = 'fullscreen_exit',
  'FUNCTIONS' = 'functions',
  'G_MOBILEDATA' = 'g_mobiledata',
  'G_TRANSLATE' = 'g_translate',
  'GAMEPAD' = 'gamepad',
  'GAMES' = 'games',
  'GARAGE' = 'garage',
  'GAS_METER' = 'gas_meter',
  'GAVEL' = 'gavel',
  'GENERATING_TOKENS' = 'generating_tokens',
  'GESTURE' = 'gesture',
  'GET_APP' = 'get_app',
  'GIF' = 'gif',
  'GIF_BOX' = 'gif_box',
  'GIRL' = 'girl',
  'GITE' = 'gite',
  'GOAT' = 'goat',
  'GOLF_COURSE' = 'golf_course',
  'GPP_BAD' = 'gpp_bad',
  'GPP_GOOD' = 'gpp_good',
  'GPP_MAYBE' = 'gpp_maybe',
  'GPS_FIXED' = 'gps_fixed',
  'GPS_NOT_FIXED' = 'gps_not_fixed',
  'GPS_OFF' = 'gps_off',
  'GRADE' = 'grade',
  'GRADIENT' = 'gradient',
  'GRADING' = 'grading',
  'GRAIN' = 'grain',
  'GRAPHIC_EQ' = 'graphic_eq',
  'GRASS' = 'grass',
  'GRID_3X3' = 'grid_3x3',
  'GRID_4X4' = 'grid_4x4',
  'GRID_GOLDENRATIO' = 'grid_goldenratio',
  'GRID_OFF' = 'grid_off',
  'GRID_ON' = 'grid_on',
  'GRID_VIEW' = 'grid_view',
  'GROUP' = 'group',
  'GROUP_ADD' = 'group_add',
  'GROUP_OFF' = 'group_off',
  'GROUP_REMOVE' = 'group_remove',
  'GROUP_WORK' = 'group_work',
  'GROUPS' = 'groups',
  'H_MOBILEDATA' = 'h_mobiledata',
  'H_PLUS_MOBILEDATA' = 'h_plus_mobiledata',
  'HAIL' = 'hail',
  'HANDSHAKE' = 'handshake',
  'HANDYMAN' = 'handyman',
  'HARDWARE' = 'hardware',
  'HD' = 'hd',
  'HDR_AUTO' = 'hdr_auto',
  'HDR_AUTO_SELECT' = 'hdr_auto_select',
  'HDR_ENHANCED_SELECT' = 'hdr_enhanced_select',
  'HDR_OFF' = 'hdr_off',
  'HDR_OFF_SELECT' = 'hdr_off_select',
  'HDR_ON' = 'hdr_on',
  'HDR_ON_SELECT' = 'hdr_on_select',
  'HDR_PLUS' = 'hdr_plus',
  'HDR_STRONG' = 'hdr_strong',
  'HDR_WEAK' = 'hdr_weak',
  'HEADPHONES' = 'headphones',
  'HEADPHONES_BATTERY' = 'headphones_battery',
  'HEADSET' = 'headset',
  'HEADSET_MIC' = 'headset_mic',
  'HEADSET_OFF' = 'headset_off',
  'HEALING' = 'healing',
  'HEALTH_AND_SAFETY' = 'health_and_safety',
  'HEARING' = 'hearing',
  'HEARING_DISABLED' = 'hearing_disabled',
  'HEART_BROKEN' = 'heart_broken',
  'HEAT_PUMP' = 'heat_pump',
  'HEIGHT' = 'height',
  'HELP' = 'help',
  'HELP_CENTER' = 'help_center',
  'HELP_OUTLINE' = 'help_outline',
  'HEVC' = 'hevc',
  'HEXAGON' = 'hexagon',
  'HIDE_IMAGE' = 'hide_image',
  'HIDE_SOURCE' = 'hide_source',
  'HIGH_QUALITY' = 'high_quality',
  'HIGHLIGHT' = 'highlight',
  'HIGHLIGHT_ALT' = 'highlight_alt',
  'HIGHLIGHT_OFF' = 'highlight_off',
  'HIGHLIGHT_REMOVE' = 'highlight_remove',
  'HIKING' = 'hiking',
  'HISTORY' = 'history',
  'HISTORY_EDU' = 'history_edu',
  'HISTORY_TOGGLE_OFF' = 'history_toggle_off',
  'HIVE' = 'hive',
  'HLS' = 'hls',
  'HLS_OFF' = 'hls_off',
  'HOLIDAY_VILLAGE' = 'holiday_village',
  'HOME' = 'home',
  'HOME_FILLED' = 'home_filled',
  'HOME_MAX' = 'home_max',
  'HOME_MINI' = 'home_mini',
  'HOME_REPAIR_SERVICE' = 'home_repair_service',
  'HOME_WORK' = 'home_work',
  'HORIZONTAL_DISTRIBUTE' = 'horizontal_distribute',
  'HORIZONTAL_RULE' = 'horizontal_rule',
  'HORIZONTAL_SPLIT' = 'horizontal_split',
  'HOT_TUB' = 'hot_tub',
  'HOTEL' = 'hotel',
  'HOTEL_CLASS' = 'hotel_class',
  'HOURGLASS_BOTTOM' = 'hourglass_bottom',
  'HOURGLASS_DISABLED' = 'hourglass_disabled',
  'HOURGLASS_EMPTY' = 'hourglass_empty',
  'HOURGLASS_FULL' = 'hourglass_full',
  'HOURGLASS_TOP' = 'hourglass_top',
  'HOUSE' = 'house',
  'HOUSE_SIDING' = 'house_siding',
  'HOUSEBOAT' = 'houseboat',
  'HOW_TO_REG' = 'how_to_reg',
  'HOW_TO_VOTE' = 'how_to_vote',
  'HTML' = 'html',
  'HTTP' = 'http',
  'HTTPS' = 'https',
  'HUB' = 'hub',
  'HVAC' = 'hvac',
  'ICE_SKATING' = 'ice_skating',
  'ICECREAM' = 'icecream',
  'IMAGE' = 'image',
  'IMAGE_ASPECT_RATIO' = 'image_aspect_ratio',
  'IMAGE_NOT_SUPPORTED' = 'image_not_supported',
  'IMAGE_SEARCH' = 'image_search',
  'IMAGESEARCH_ROLLER' = 'imagesearch_roller',
  'IMPORT_CONTACTS' = 'import_contacts',
  'IMPORT_EXPORT' = 'import_export',
  'IMPORTANT_DEVICES' = 'important_devices',
  'INBOX' = 'inbox',
  'INCOMPLETE_CIRCLE' = 'incomplete_circle',
  'INDETERMINATE_CHECK_BOX' = 'indeterminate_check_box',
  'INFO' = 'info',
  'INFO_OUTLINE' = 'info_outline',
  'INPUT' = 'input',
  'INSERT_CHART' = 'insert_chart',
  'INSERT_CHART_OUTLINED' = 'insert_chart_outlined',
  'INSERT_COMMENT' = 'insert_comment',
  'INSERT_DRIVE_FILE' = 'insert_drive_file',
  'INSERT_EMOTICON' = 'insert_emoticon',
  'INSERT_INVITATION' = 'insert_invitation',
  'INSERT_LINK' = 'insert_link',
  'INSERT_PAGE_BREAK' = 'insert_page_break',
  'INSERT_PHOTO' = 'insert_photo',
  'INSIGHTS' = 'insights',
  'INSTALL_DESKTOP' = 'install_desktop',
  'INSTALL_MOBILE' = 'install_mobile',
  'INTEGRATION_INSTRUCTIONS' = 'integration_instructions',
  'INTERESTS' = 'interests',
  'INTERPRETER_MODE' = 'interpreter_mode',
  'INVENTORY' = 'inventory',
  'INVENTORY_2' = 'inventory_2',
  'INVERT_COLORS' = 'invert_colors',
  'INVERT_COLORS_OFF' = 'invert_colors_off',
  'INVERT_COLORS_ON' = 'invert_colors_on',
  'IOS_SHARE' = 'ios_share',
  'IRON' = 'iron',
  'ISO' = 'iso',
  'JAVASCRIPT' = 'javascript',
  'JOIN_FULL' = 'join_full',
  'JOIN_INNER' = 'join_inner',
  'JOIN_LEFT' = 'join_left',
  'JOIN_RIGHT' = 'join_right',
  'KAYAKING' = 'kayaking',
  'KEBAB_DINING' = 'kebab_dining',
  'KEY' = 'key',
  'KEY_OFF' = 'key_off',
  'KEYBOARD' = 'keyboard',
  'KEYBOARD_ALT' = 'keyboard_alt',
  'KEYBOARD_ARROW_DOWN' = 'keyboard_arrow_down',
  'KEYBOARD_ARROW_LEFT' = 'keyboard_arrow_left',
  'KEYBOARD_ARROW_RIGHT' = 'keyboard_arrow_right',
  'KEYBOARD_ARROW_UP' = 'keyboard_arrow_up',
  'KEYBOARD_BACKSPACE' = 'keyboard_backspace',
  'KEYBOARD_CAPSLOCK' = 'keyboard_capslock',
  'KEYBOARD_COMMAND' = 'keyboard_command',
  'KEYBOARD_COMMAND_KEY' = 'keyboard_command_key',
  'KEYBOARD_CONTROL' = 'keyboard_control',
  'KEYBOARD_CONTROL_KEY' = 'keyboard_control_key',
  'KEYBOARD_DOUBLE_ARROW_DOWN' = 'keyboard_double_arrow_down',
  'KEYBOARD_DOUBLE_ARROW_LEFT' = 'keyboard_double_arrow_left',
  'KEYBOARD_DOUBLE_ARROW_RIGHT' = 'keyboard_double_arrow_right',
  'KEYBOARD_DOUBLE_ARROW_UP' = 'keyboard_double_arrow_up',
  'KEYBOARD_HIDE' = 'keyboard_hide',
  'KEYBOARD_OPTION' = 'keyboard_option',
  'KEYBOARD_OPTION_KEY' = 'keyboard_option_key',
  'KEYBOARD_RETURN' = 'keyboard_return',
  'KEYBOARD_TAB' = 'keyboard_tab',
  'KEYBOARD_VOICE' = 'keyboard_voice',
  'KING_BED' = 'king_bed',
  'KITCHEN' = 'kitchen',
  'KITESURFING' = 'kitesurfing',
  'LABEL' = 'label',
  'LABEL_IMPORTANT' = 'label_important',
  'LABEL_IMPORTANT_OUTLINE' = 'label_important_outline',
  'LABEL_OFF' = 'label_off',
  'LABEL_OUTLINE' = 'label_outline',
  'LAN' = 'lan',
  'LANDSCAPE' = 'landscape',
  'LANDSLIDE' = 'landslide',
  'LANGUAGE' = 'language',
  'LAPTOP' = 'laptop',
  'LAPTOP_CHROMEBOOK' = 'laptop_chromebook',
  'LAPTOP_MAC' = 'laptop_mac',
  'LAPTOP_WINDOWS' = 'laptop_windows',
  'LAST_PAGE' = 'last_page',
  'LAUNCH' = 'launch',
  'LAYERS' = 'layers',
  'LAYERS_CLEAR' = 'layers_clear',
  'LEADERBOARD' = 'leaderboard',
  'LEAK_ADD' = 'leak_add',
  'LEAK_REMOVE' = 'leak_remove',
  'LEAVE_BAGS_AT_HOME' = 'leave_bags_at_home',
  'LEGEND_TOGGLE' = 'legend_toggle',
  'LENS' = 'lens',
  'LENS_BLUR' = 'lens_blur',
  'LIBRARY_ADD' = 'library_add',
  'LIBRARY_ADD_CHECK' = 'library_add_check',
  'LIBRARY_BOOKS' = 'library_books',
  'LIBRARY_MUSIC' = 'library_music',
  'LIGHT' = 'light',
  'LIGHT_MODE' = 'light_mode',
  'LIGHTBULB' = 'lightbulb',
  'LIGHTBULB_CIRCLE' = 'lightbulb_circle',
  'LIGHTBULB_OUTLINE' = 'lightbulb_outline',
  'LINE_AXIS' = 'line_axis',
  'LINE_STYLE' = 'line_style',
  'LINE_WEIGHT' = 'line_weight',
  'LINEAR_SCALE' = 'linear_scale',
  'LINK' = 'link',
  'LINK_OFF' = 'link_off',
  'LINKED_CAMERA' = 'linked_camera',
  'LIQUOR' = 'liquor',
  'LIST' = 'list',
  'LIST_ALT' = 'list_alt',
  'LIVE_HELP' = 'live_help',
  'LIVE_TV' = 'live_tv',
  'LIVING' = 'living',
  'LOCAL_ACTIVITY' = 'local_activity',
  'LOCAL_AIRPORT' = 'local_airport',
  'LOCAL_ATM' = 'local_atm',
  'LOCAL_ATTRACTION' = 'local_attraction',
  'LOCAL_BAR' = 'local_bar',
  'LOCAL_CAFE' = 'local_cafe',
  'LOCAL_CAR_WASH' = 'local_car_wash',
  'LOCAL_CONVENIENCE_STORE' = 'local_convenience_store',
  'LOCAL_DINING' = 'local_dining',
  'LOCAL_DRINK' = 'local_drink',
  'LOCAL_FIRE_DEPARTMENT' = 'local_fire_department',
  'LOCAL_FLORIST' = 'local_florist',
  'LOCAL_GAS_STATION' = 'local_gas_station',
  'LOCAL_GROCERY_STORE' = 'local_grocery_store',
  'LOCAL_HOSPITAL' = 'local_hospital',
  'LOCAL_HOTEL' = 'local_hotel',
  'LOCAL_LAUNDRY_SERVICE' = 'local_laundry_service',
  'LOCAL_LIBRARY' = 'local_library',
  'LOCAL_MALL' = 'local_mall',
  'LOCAL_MOVIES' = 'local_movies',
  'LOCAL_OFFER' = 'local_offer',
  'LOCAL_PARKING' = 'local_parking',
  'LOCAL_PHARMACY' = 'local_pharmacy',
  'LOCAL_PHONE' = 'local_phone',
  'LOCAL_PIZZA' = 'local_pizza',
  'LOCAL_PLAY' = 'local_play',
  'LOCAL_POLICE' = 'local_police',
  'LOCAL_POST_OFFICE' = 'local_post_office',
  'LOCAL_PRINT_SHOP' = 'local_print_shop',
  'LOCAL_PRINTSHOP' = 'local_printshop',
  'LOCAL_RESTAURANT' = 'local_restaurant',
  'LOCAL_SEE' = 'local_see',
  'LOCAL_SHIPPING' = 'local_shipping',
  'LOCAL_TAXI' = 'local_taxi',
  'LOCATION_CITY' = 'location_city',
  'LOCATION_DISABLED' = 'location_disabled',
  'LOCATION_HISTORY' = 'location_history',
  'LOCATION_OFF' = 'location_off',
  'LOCATION_ON' = 'location_on',
  'LOCATION_PIN' = 'location_pin',
  'LOCATION_SEARCHING' = 'location_searching',
  'LOCK' = 'lock',
  'LOCK_CLOCK' = 'lock_clock',
  'LOCK_OPEN' = 'lock_open',
  'LOCK_OUTLINE' = 'lock_outline',
  'LOCK_PERSON' = 'lock_person',
  'LOCK_RESET' = 'lock_reset',
  'LOGIN' = 'login',
  'LOGO_DEV' = 'logo_dev',
  'LOGOUT' = 'logout',
  'LOOKS' = 'looks',
  'LOOKS_3' = 'looks_3',
  'LOOKS_4' = 'looks_4',
  'LOOKS_5' = 'looks_5',
  'LOOKS_6' = 'looks_6',
  'LOOKS_ONE' = 'looks_one',
  'LOOKS_TWO' = 'looks_two',
  'LOOP' = 'loop',
  'LOUPE' = 'loupe',
  'LOW_PRIORITY' = 'low_priority',
  'LOYALTY' = 'loyalty',
  'LTE_MOBILEDATA' = 'lte_mobiledata',
  'LTE_PLUS_MOBILEDATA' = 'lte_plus_mobiledata',
  'LUGGAGE' = 'luggage',
  'LUNCH_DINING' = 'lunch_dining',
  'LYRICS' = 'lyrics',
  'MAIL' = 'mail',
  'MAIL_LOCK' = 'mail_lock',
  'MAIL_OUTLINE' = 'mail_outline',
  'MALE' = 'male',
  'MAN' = 'man',
  'MANAGE_ACCOUNTS' = 'manage_accounts',
  'MANAGE_HISTORY' = 'manage_history',
  'MANAGE_SEARCH' = 'manage_search',
  'MAP' = 'map',
  'MAPS_HOME_WORK' = 'maps_home_work',
  'MAPS_UGC' = 'maps_ugc',
  'MARGIN' = 'margin',
  'MARK_AS_UNREAD' = 'mark_as_unread',
  'MARK_CHAT_READ' = 'mark_chat_read',
  'MARK_CHAT_UNREAD' = 'mark_chat_unread',
  'MARK_EMAIL_READ' = 'mark_email_read',
  'MARK_EMAIL_UNREAD' = 'mark_email_unread',
  'MARK_UNREAD_CHAT_ALT' = 'mark_unread_chat_alt',
  'MARKUNREAD' = 'markunread',
  'MARKUNREAD_MAILBOX' = 'markunread_mailbox',
  'MASKS' = 'masks',
  'MAXIMIZE' = 'maximize',
  'MEDIA_BLUETOOTH_OFF' = 'media_bluetooth_off',
  'MEDIA_BLUETOOTH_ON' = 'media_bluetooth_on',
  'MEDIATION' = 'mediation',
  'MEDICAL_INFORMATION' = 'medical_information',
  'MEDICAL_SERVICES' = 'medical_services',
  'MEDICATION' = 'medication',
  'MEDICATION_LIQUID' = 'medication_liquid',
  'MEETING_ROOM' = 'meeting_room',
  'MEMORY' = 'memory',
  'MENU' = 'menu',
  'MENU_BOOK' = 'menu_book',
  'MENU_OPEN' = 'menu_open',
  'MERGE' = 'merge',
  'MERGE_TYPE' = 'merge_type',
  'MESSAGE' = 'message',
  'MESSENGER' = 'messenger',
  'MESSENGER_OUTLINE' = 'messenger_outline',
  'MIC' = 'mic',
  'MIC_EXTERNAL_OFF' = 'mic_external_off',
  'MIC_EXTERNAL_ON' = 'mic_external_on',
  'MIC_NONE' = 'mic_none',
  'MIC_OFF' = 'mic_off',
  'MICROWAVE' = 'microwave',
  'MILITARY_TECH' = 'military_tech',
  'MINIMIZE' = 'minimize',
  'MINOR_CRASH' = 'minor_crash',
  'MISCELLANEOUS_SERVICES' = 'miscellaneous_services',
  'MISSED_VIDEO_CALL' = 'missed_video_call',
  'MMS' = 'mms',
  'MOBILE_FRIENDLY' = 'mobile_friendly',
  'MOBILE_OFF' = 'mobile_off',
  'MOBILE_SCREEN_SHARE' = 'mobile_screen_share',
  'MOBILEDATA_OFF' = 'mobiledata_off',
  'MODE' = 'mode',
  'MODE_COMMENT' = 'mode_comment',
  'MODE_EDIT' = 'mode_edit',
  'MODE_EDIT_OUTLINE' = 'mode_edit_outline',
  'MODE_FAN_OFF' = 'mode_fan_off',
  'MODE_NIGHT' = 'mode_night',
  'MODE_OF_TRAVEL' = 'mode_of_travel',
  'MODE_STANDBY' = 'mode_standby',
  'MODEL_TRAINING' = 'model_training',
  'MONETIZATION_ON' = 'monetization_on',
  'MONEY' = 'money',
  'MONEY_OFF' = 'money_off',
  'MONEY_OFF_CSRED' = 'money_off_csred',
  'MONITOR' = 'monitor',
  'MONITOR_HEART' = 'monitor_heart',
  'MONITOR_WEIGHT' = 'monitor_weight',
  'MONOCHROME_PHOTOS' = 'monochrome_photos',
  'MOOD' = 'mood',
  'MOOD_BAD' = 'mood_bad',
  'MOPED' = 'moped',
  'MORE' = 'more',
  'MORE_HORIZ' = 'more_horiz',
  'MORE_TIME' = 'more_time',
  'MORE_VERT' = 'more_vert',
  'MOSQUE' = 'mosque',
  'MOTION_PHOTOS_AUTO' = 'motion_photos_auto',
  'MOTION_PHOTOS_OFF' = 'motion_photos_off',
  'MOTION_PHOTOS_ON' = 'motion_photos_on',
  'MOTION_PHOTOS_PAUSE' = 'motion_photos_pause',
  'MOTION_PHOTOS_PAUSED' = 'motion_photos_paused',
  'MOTORCYCLE' = 'motorcycle',
  'MOUSE' = 'mouse',
  'MOVE_DOWN' = 'move_down',
  'MOVE_TO_INBOX' = 'move_to_inbox',
  'MOVE_UP' = 'move_up',
  'MOVIE' = 'movie',
  'MOVIE_CREATION' = 'movie_creation',
  'MOVIE_FILTER' = 'movie_filter',
  'MOVING' = 'moving',
  'MP' = 'mp',
  'MULTILINE_CHART' = 'multiline_chart',
  'MULTIPLE_STOP' = 'multiple_stop',
  'MULTITRACK_AUDIO' = 'multitrack_audio',
  'MUSEUM' = 'museum',
  'MUSIC_NOTE' = 'music_note',
  'MUSIC_OFF' = 'music_off',
  'MUSIC_VIDEO' = 'music_video',
  'MY_LIBRARY_ADD' = 'my_library_add',
  'MY_LIBRARY_BOOKS' = 'my_library_books',
  'MY_LIBRARY_MUSIC' = 'my_library_music',
  'MY_LOCATION' = 'my_location',
  'NAT' = 'nat',
  'NATURE' = 'nature',
  'NATURE_PEOPLE' = 'nature_people',
  'NAVIGATE_BEFORE' = 'navigate_before',
  'NAVIGATE_NEXT' = 'navigate_next',
  'NAVIGATION' = 'navigation',
  'NEAR_ME' = 'near_me',
  'NEAR_ME_DISABLED' = 'near_me_disabled',
  'NEARBY_ERROR' = 'nearby_error',
  'NEARBY_OFF' = 'nearby_off',
  'NEST_CAM_WIRED_STAND' = 'nest_cam_wired_stand',
  'NETWORK_CELL' = 'network_cell',
  'NETWORK_CHECK' = 'network_check',
  'NETWORK_LOCKED' = 'network_locked',
  'NETWORK_PING' = 'network_ping',
  'NETWORK_WIFI' = 'network_wifi',
  'NETWORK_WIFI_1_BAR' = 'network_wifi_1_bar',
  'NETWORK_WIFI_2_BAR' = 'network_wifi_2_bar',
  'NETWORK_WIFI_3_BAR' = 'network_wifi_3_bar',
  'NEW_LABEL' = 'new_label',
  'NEW_RELEASES' = 'new_releases',
  'NEWSPAPER' = 'newspaper',
  'NEXT_PLAN' = 'next_plan',
  'NEXT_WEEK' = 'next_week',
  'NFC' = 'nfc',
  'NIGHT_SHELTER' = 'night_shelter',
  'NIGHTLIFE' = 'nightlife',
  'NIGHTLIGHT' = 'nightlight',
  'NIGHTLIGHT_ROUND' = 'nightlight_round',
  'NIGHTS_STAY' = 'nights_stay',
  'NO_ACCOUNTS' = 'no_accounts',
  'NO_ADULT_CONTENT' = 'no_adult_content',
  'NO_BACKPACK' = 'no_backpack',
  'NO_CELL' = 'no_cell',
  'NO_CRASH' = 'no_crash',
  'NO_DRINKS' = 'no_drinks',
  'NO_ENCRYPTION' = 'no_encryption',
  'NO_ENCRYPTION_GMAILERRORRED' = 'no_encryption_gmailerrorred',
  'NO_FLASH' = 'no_flash',
  'NO_FOOD' = 'no_food',
  'NO_LUGGAGE' = 'no_luggage',
  'NO_MEALS' = 'no_meals',
  'NO_MEALS_OULINE' = 'no_meals_ouline',
  'NO_MEETING_ROOM' = 'no_meeting_room',
  'NO_PHOTOGRAPHY' = 'no_photography',
  'NO_SIM' = 'no_sim',
  'NO_STROLLER' = 'no_stroller',
  'NO_TRANSFER' = 'no_transfer',
  'NOISE_AWARE' = 'noise_aware',
  'NOISE_CONTROL_OFF' = 'noise_control_off',
  'NORDIC_WALKING' = 'nordic_walking',
  'NORTH' = 'north',
  'NORTH_EAST' = 'north_east',
  'NORTH_WEST' = 'north_west',
  'NOT_ACCESSIBLE' = 'not_accessible',
  'NOT_INTERESTED' = 'not_interested',
  'NOT_LISTED_LOCATION' = 'not_listed_location',
  'NOT_STARTED' = 'not_started',
  'NOTE' = 'note',
  'NOTE_ADD' = 'note_add',
  'NOTE_ALT' = 'note_alt',
  'NOTES' = 'notes',
  'NOTIFICATION_ADD' = 'notification_add',
  'NOTIFICATION_IMPORTANT' = 'notification_important',
  'NOTIFICATIONS' = 'notifications',
  'NOTIFICATIONS_ACTIVE' = 'notifications_active',
  'NOTIFICATIONS_NONE' = 'notifications_none',
  'NOTIFICATIONS_OFF' = 'notifications_off',
  'NOTIFICATIONS_ON' = 'notifications_on',
  'NOTIFICATIONS_PAUSED' = 'notifications_paused',
  'NOW_WALLPAPER' = 'now_wallpaper',
  'NOW_WIDGETS' = 'now_widgets',
  'NUMBERS' = 'numbers',
  'OFFLINE_BOLT' = 'offline_bolt',
  'OFFLINE_PIN' = 'offline_pin',
  'OFFLINE_SHARE' = 'offline_share',
  'OIL_BARREL' = 'oil_barrel',
  'ON_DEVICE_TRAINING' = 'on_device_training',
  'ONDEMAND_VIDEO' = 'ondemand_video',
  'ONLINE_PREDICTION' = 'online_prediction',
  'OPACITY' = 'opacity',
  'OPEN_IN_BROWSER' = 'open_in_browser',
  'OPEN_IN_FULL' = 'open_in_full',
  'OPEN_IN_NEW' = 'open_in_new',
  'OPEN_IN_NEW_OFF' = 'open_in_new_off',
  'OPEN_WITH' = 'open_with',
  'OTHER_HOUSES' = 'other_houses',
  'OUTBOND' = 'outbond',
  'OUTBOUND' = 'outbound',
  'OUTBOX' = 'outbox',
  'OUTDOOR_GRILL' = 'outdoor_grill',
  'OUTGOING_MAIL' = 'outgoing_mail',
  'OUTLET' = 'outlet',
  'OUTLINED_FLAG' = 'outlined_flag',
  'OUTPUT' = 'output',
  'PADDING' = 'padding',
  'PAGES' = 'pages',
  'PAGEVIEW' = 'pageview',
  'PAID' = 'paid',
  'PALETTE' = 'palette',
  'PAN_TOOL' = 'pan_tool',
  'PAN_TOOL_ALT' = 'pan_tool_alt',
  'PANORAMA' = 'panorama',
  'PANORAMA_FISH_EYE' = 'panorama_fish_eye',
  'PANORAMA_FISHEYE' = 'panorama_fisheye',
  'PANORAMA_HORIZONTAL' = 'panorama_horizontal',
  'PANORAMA_HORIZONTAL_SELECT' = 'panorama_horizontal_select',
  'PANORAMA_PHOTOSPHERE' = 'panorama_photosphere',
  'PANORAMA_PHOTOSPHERE_SELECT' = 'panorama_photosphere_select',
  'PANORAMA_VERTICAL' = 'panorama_vertical',
  'PANORAMA_VERTICAL_SELECT' = 'panorama_vertical_select',
  'PANORAMA_WIDE_ANGLE' = 'panorama_wide_angle',
  'PANORAMA_WIDE_ANGLE_SELECT' = 'panorama_wide_angle_select',
  'PARAGLIDING' = 'paragliding',
  'PARK' = 'park',
  'PARTY_MODE' = 'party_mode',
  'PASSWORD' = 'password',
  'PATTERN' = 'pattern',
  'PAUSE' = 'pause',
  'PAUSE_CIRCLE' = 'pause_circle',
  'PAUSE_CIRCLE_FILLED' = 'pause_circle_filled',
  'PAUSE_CIRCLE_OUTLINE' = 'pause_circle_outline',
  'PAUSE_PRESENTATION' = 'pause_presentation',
  'PAYMENT' = 'payment',
  'PAYMENTS' = 'payments',
  'PAYPAL' = 'paypal',
  'PEDAL_BIKE' = 'pedal_bike',
  'PENDING' = 'pending',
  'PENDING_ACTIONS' = 'pending_actions',
  'PENTAGON' = 'pentagon',
  'PEOPLE' = 'people',
  'PEOPLE_ALT' = 'people_alt',
  'PEOPLE_OUTLINE' = 'people_outline',
  'PERCENT' = 'percent',
  'PERM_CAMERA_MIC' = 'perm_camera_mic',
  'PERM_CONTACT_CAL' = 'perm_contact_cal',
  'PERM_CONTACT_CALENDAR' = 'perm_contact_calendar',
  'PERM_DATA_SETTING' = 'perm_data_setting',
  'PERM_DEVICE_INFO' = 'perm_device_info',
  'PERM_DEVICE_INFORMATION' = 'perm_device_information',
  'PERM_IDENTITY' = 'perm_identity',
  'PERM_MEDIA' = 'perm_media',
  'PERM_PHONE_MSG' = 'perm_phone_msg',
  'PERM_SCAN_WIFI' = 'perm_scan_wifi',
  'PERSON' = 'person',
  'PERSON_ADD' = 'person_add',
  'PERSON_ADD_ALT' = 'person_add_alt',
  'PERSON_ADD_ALT_1' = 'person_add_alt_1',
  'PERSON_ADD_DISABLED' = 'person_add_disabled',
  'PERSON_OFF' = 'person_off',
  'PERSON_OUTLINE' = 'person_outline',
  'PERSON_PIN' = 'person_pin',
  'PERSON_PIN_CIRCLE' = 'person_pin_circle',
  'PERSON_REMOVE' = 'person_remove',
  'PERSON_REMOVE_ALT_1' = 'person_remove_alt_1',
  'PERSON_SEARCH' = 'person_search',
  'PERSONAL_INJURY' = 'personal_injury',
  'PERSONAL_VIDEO' = 'personal_video',
  'PEST_CONTROL' = 'pest_control',
  'PEST_CONTROL_RODENT' = 'pest_control_rodent',
  'PETS' = 'pets',
  'PHISHING' = 'phishing',
  'PHONE' = 'phone',
  'PHONE_ANDROID' = 'phone_android',
  'PHONE_BLUETOOTH_SPEAKER' = 'phone_bluetooth_speaker',
  'PHONE_CALLBACK' = 'phone_callback',
  'PHONE_DISABLED' = 'phone_disabled',
  'PHONE_ENABLED' = 'phone_enabled',
  'PHONE_FORWARDED' = 'phone_forwarded',
  'PHONE_IN_TALK' = 'phone_in_talk',
  'PHONE_IPHONE' = 'phone_iphone',
  'PHONE_LOCKED' = 'phone_locked',
  'PHONE_MISSED' = 'phone_missed',
  'PHONE_PAUSED' = 'phone_paused',
  'PHONELINK' = 'phonelink',
  'PHONELINK_ERASE' = 'phonelink_erase',
  'PHONELINK_LOCK' = 'phonelink_lock',
  'PHONELINK_OFF' = 'phonelink_off',
  'PHONELINK_RING' = 'phonelink_ring',
  'PHONELINK_SETUP' = 'phonelink_setup',
  'PHOTO' = 'photo',
  'PHOTO_ALBUM' = 'photo_album',
  'PHOTO_CAMERA' = 'photo_camera',
  'PHOTO_CAMERA_BACK' = 'photo_camera_back',
  'PHOTO_CAMERA_FRONT' = 'photo_camera_front',
  'PHOTO_FILTER' = 'photo_filter',
  'PHOTO_LIBRARY' = 'photo_library',
  'PHOTO_SIZE_SELECT_ACTUAL' = 'photo_size_select_actual',
  'PHOTO_SIZE_SELECT_LARGE' = 'photo_size_select_large',
  'PHOTO_SIZE_SELECT_SMALL' = 'photo_size_select_small',
  'PHP' = 'php',
  'PIANO' = 'piano',
  'PIANO_OFF' = 'piano_off',
  'PICTURE_AS_PDF' = 'picture_as_pdf',
  'PICTURE_IN_PICTURE' = 'picture_in_picture',
  'PICTURE_IN_PICTURE_ALT' = 'picture_in_picture_alt',
  'PIE_CHART' = 'pie_chart',
  'PIE_CHART_OUTLINE' = 'pie_chart_outline',
  'PIE_CHART_OUTLINED' = 'pie_chart_outlined',
  'PIN' = 'pin',
  'PIN_DROP' = 'pin_drop',
  'PIN_END' = 'pin_end',
  'PIN_INVOKE' = 'pin_invoke',
  'PINCH' = 'pinch',
  'PIVOT_TABLE_CHART' = 'pivot_table_chart',
  'PIX' = 'pix',
  'PLACE' = 'place',
  'PLAGIARISM' = 'plagiarism',
  'PLAY_ARROW' = 'play_arrow',
  'PLAY_CIRCLE' = 'play_circle',
  'PLAY_CIRCLE_FILL' = 'play_circle_fill',
  'PLAY_CIRCLE_FILLED' = 'play_circle_filled',
  'PLAY_CIRCLE_OUTLINE' = 'play_circle_outline',
  'PLAY_DISABLED' = 'play_disabled',
  'PLAY_FOR_WORK' = 'play_for_work',
  'PLAY_LESSON' = 'play_lesson',
  'PLAYLIST_ADD' = 'playlist_add',
  'PLAYLIST_ADD_CHECK' = 'playlist_add_check',
  'PLAYLIST_ADD_CHECK_CIRCLE' = 'playlist_add_check_circle',
  'PLAYLIST_ADD_CIRCLE' = 'playlist_add_circle',
  'PLAYLIST_PLAY' = 'playlist_play',
  'PLAYLIST_REMOVE' = 'playlist_remove',
  'PLUMBING' = 'plumbing',
  'PLUS_ONE' = 'plus_one',
  'PODCASTS' = 'podcasts',
  'POINT_OF_SALE' = 'point_of_sale',
  'POLICY' = 'policy',
  'POLL' = 'poll',
  'POLYLINE' = 'polyline',
  'POLYMER' = 'polymer',
  'POOL' = 'pool',
  'PORTABLE_WIFI_OFF' = 'portable_wifi_off',
  'PORTRAIT' = 'portrait',
  'POST_ADD' = 'post_add',
  'POWER' = 'power',
  'POWER_INPUT' = 'power_input',
  'POWER_OFF' = 'power_off',
  'POWER_SETTINGS_NEW' = 'power_settings_new',
  'PRECISION_MANUFACTURING' = 'precision_manufacturing',
  'PREGNANT_WOMAN' = 'pregnant_woman',
  'PRESENT_TO_ALL' = 'present_to_all',
  'PREVIEW' = 'preview',
  'PRICE_CHANGE' = 'price_change',
  'PRICE_CHECK' = 'price_check',
  'PRINT' = 'print',
  'PRINT_DISABLED' = 'print_disabled',
  'PRIORITY_HIGH' = 'priority_high',
  'PRIVACY_TIP' = 'privacy_tip',
  'PRIVATE_CONNECTIVITY' = 'private_connectivity',
  'PRODUCTION_QUANTITY_LIMITS' = 'production_quantity_limits',
  'PROPANE' = 'propane',
  'PROPANE_TANK' = 'propane_tank',
  'PSYCHOLOGY' = 'psychology',
  'PSYCHOLOGY_ALT' = 'psychology_alt',
  'PUBLIC' = 'public',
  'PUBLIC_OFF' = 'public_off',
  'PUBLISH' = 'publish',
  'PUBLISHED_WITH_CHANGES' = 'published_with_changes',
  'PUNCH_CLOCK' = 'punch_clock',
  'PUSH_PIN' = 'push_pin',
  'QR_CODE' = 'qr_code',
  'QR_CODE_2' = 'qr_code_2',
  'QR_CODE_SCANNER' = 'qr_code_scanner',
  'QUERY_BUILDER' = 'query_builder',
  'QUERY_STATS' = 'query_stats',
  'QUESTION_ANSWER' = 'question_answer',
  'QUESTION_MARK' = 'question_mark',
  'QUEUE' = 'queue',
  'QUEUE_MUSIC' = 'queue_music',
  'QUEUE_PLAY_NEXT' = 'queue_play_next',
  'QUICK_CONTACTS_DIALER' = 'quick_contacts_dialer',
  'QUICK_CONTACTS_MAIL' = 'quick_contacts_mail',
  'QUICKREPLY' = 'quickreply',
  'QUIZ' = 'quiz',
  'QUORA' = 'quora',
  'R_MOBILEDATA' = 'r_mobiledata',
  'RADAR' = 'radar',
  'RADIO' = 'radio',
  'RADIO_BUTTON_CHECKED' = 'radio_button_checked',
  'RADIO_BUTTON_OFF' = 'radio_button_off',
  'RADIO_BUTTON_ON' = 'radio_button_on',
  'RADIO_BUTTON_UNCHECKED' = 'radio_button_unchecked',
  'RAILWAY_ALERT' = 'railway_alert',
  'RAMEN_DINING' = 'ramen_dining',
  'RAMP_LEFT' = 'ramp_left',
  'RAMP_RIGHT' = 'ramp_right',
  'RATE_REVIEW' = 'rate_review',
  'RAW_OFF' = 'raw_off',
  'RAW_ON' = 'raw_on',
  'READ_MORE' = 'read_more',
  'REAL_ESTATE_AGENT' = 'real_estate_agent',
  'RECEIPT' = 'receipt',
  'RECEIPT_LONG' = 'receipt_long',
  'RECENT_ACTORS' = 'recent_actors',
  'RECOMMEND' = 'recommend',
  'RECORD_VOICE_OVER' = 'record_voice_over',
  'RECTANGLE' = 'rectangle',
  'RECYCLING' = 'recycling',
  'REDDIT' = 'reddit',
  'REDEEM' = 'redeem',
  'REDO' = 'redo',
  'REDUCE_CAPACITY' = 'reduce_capacity',
  'REFRESH' = 'refresh',
  'REMEMBER_ME' = 'remember_me',
  'REMOVE' = 'remove',
  'REMOVE_CIRCLE' = 'remove_circle',
  'REMOVE_CIRCLE_OUTLINE' = 'remove_circle_outline',
  'REMOVE_DONE' = 'remove_done',
  'REMOVE_FROM_QUEUE' = 'remove_from_queue',
  'REMOVE_MODERATOR' = 'remove_moderator',
  'REMOVE_RED_EYE' = 'remove_red_eye',
  'REMOVE_ROAD' = 'remove_road',
  'REMOVE_SHOPPING_CART' = 'remove_shopping_cart',
  'REORDER' = 'reorder',
  'REPARTITION' = 'repartition',
  'REPEAT' = 'repeat',
  'REPEAT_ON' = 'repeat_on',
  'REPEAT_ONE' = 'repeat_one',
  'REPEAT_ONE_ON' = 'repeat_one_on',
  'REPLAY' = 'replay',
  'REPLAY_10' = 'replay_10',
  'REPLAY_30' = 'replay_30',
  'REPLAY_5' = 'replay_5',
  'REPLAY_CIRCLE_FILLED' = 'replay_circle_filled',
  'REPLY' = 'reply',
  'REPLY_ALL' = 'reply_all',
  'REPORT' = 'report',
  'REPORT_GMAILERRORRED' = 'report_gmailerrorred',
  'REPORT_OFF' = 'report_off',
  'REPORT_PROBLEM' = 'report_problem',
  'REQUEST_PAGE' = 'request_page',
  'REQUEST_QUOTE' = 'request_quote',
  'RESET_TV' = 'reset_tv',
  'RESTART_ALT' = 'restart_alt',
  'RESTAURANT' = 'restaurant',
  'RESTAURANT_MENU' = 'restaurant_menu',
  'RESTORE' = 'restore',
  'RESTORE_FROM_TRASH' = 'restore_from_trash',
  'RESTORE_PAGE' = 'restore_page',
  'REVIEWS' = 'reviews',
  'RICE_BOWL' = 'rice_bowl',
  'RING_VOLUME' = 'ring_volume',
  'ROCKET' = 'rocket',
  'ROCKET_LAUNCH' = 'rocket_launch',
  'ROLLER_SHADES' = 'roller_shades',
  'ROLLER_SHADES_CLOSED' = 'roller_shades_closed',
  'ROLLER_SKATING' = 'roller_skating',
  'ROOFING' = 'roofing',
  'ROOM' = 'room',
  'ROOM_PREFERENCES' = 'room_preferences',
  'ROOM_SERVICE' = 'room_service',
  'ROTATE_90_DEGREES_CCW' = 'rotate_90_degrees_ccw',
  'ROTATE_90_DEGREES_CW' = 'rotate_90_degrees_cw',
  'ROTATE_LEFT' = 'rotate_left',
  'ROTATE_RIGHT' = 'rotate_right',
  'ROUNDABOUT_LEFT' = 'roundabout_left',
  'ROUNDABOUT_RIGHT' = 'roundabout_right',
  'ROUNDED_CORNER' = 'rounded_corner',
  'ROUTE' = 'route',
  'ROUTER' = 'router',
  'ROWING' = 'rowing',
  'RSS_FEED' = 'rss_feed',
  'RSVP' = 'rsvp',
  'RTT' = 'rtt',
  'RULE' = 'rule',
  'RULE_FOLDER' = 'rule_folder',
  'RUN_CIRCLE' = 'run_circle',
  'RUNNING_WITH_ERRORS' = 'running_with_errors',
  'RV_HOOKUP' = 'rv_hookup',
  'SAFETY_CHECK' = 'safety_check',
  'SAFETY_DIVIDER' = 'safety_divider',
  'SAILING' = 'sailing',
  'SANITIZER' = 'sanitizer',
  'SATELLITE' = 'satellite',
  'SATELLITE_ALT' = 'satellite_alt',
  'SAVE' = 'save',
  'SAVE_ALT' = 'save_alt',
  'SAVE_AS' = 'save_as',
  'SAVED_SEARCH' = 'saved_search',
  'SAVINGS' = 'savings',
  'SCALE' = 'scale',
  'SCANNER' = 'scanner',
  'SCATTER_PLOT' = 'scatter_plot',
  'SCHEDULE' = 'schedule',
  'SCHEDULE_SEND' = 'schedule_send',
  'SCHEMA' = 'schema',
  'SCHOOL' = 'school',
  'SCIENCE' = 'science',
  'SCORE' = 'score',
  'SCOREBOARD' = 'scoreboard',
  'SCREEN_LOCK_LANDSCAPE' = 'screen_lock_landscape',
  'SCREEN_LOCK_PORTRAIT' = 'screen_lock_portrait',
  'SCREEN_LOCK_ROTATION' = 'screen_lock_rotation',
  'SCREEN_ROTATION' = 'screen_rotation',
  'SCREEN_ROTATION_ALT' = 'screen_rotation_alt',
  'SCREEN_SEARCH_DESKTOP' = 'screen_search_desktop',
  'SCREEN_SHARE' = 'screen_share',
  'SCREENSHOT' = 'screenshot',
  'SCREENSHOT_MONITOR' = 'screenshot_monitor',
  'SCUBA_DIVING' = 'scuba_diving',
  'SD' = 'sd',
  'SD_CARD' = 'sd_card',
  'SD_CARD_ALERT' = 'sd_card_alert',
  'SD_STORAGE' = 'sd_storage',
  'SEARCH' = 'search',
  'SEARCH_OFF' = 'search_off',
  'SECURITY' = 'security',
  'SECURITY_UPDATE' = 'security_update',
  'SECURITY_UPDATE_GOOD' = 'security_update_good',
  'SECURITY_UPDATE_WARNING' = 'security_update_warning',
  'SEGMENT' = 'segment',
  'SELECT_ALL' = 'select_all',
  'SELF_IMPROVEMENT' = 'self_improvement',
  'SELL' = 'sell',
  'SEND' = 'send',
  'SEND_AND_ARCHIVE' = 'send_and_archive',
  'SEND_TIME_EXTENSION' = 'send_time_extension',
  'SEND_TO_MOBILE' = 'send_to_mobile',
  'SENSOR_DOOR' = 'sensor_door',
  'SENSOR_OCCUPIED' = 'sensor_occupied',
  'SENSOR_WINDOW' = 'sensor_window',
  'SENSORS' = 'sensors',
  'SENSORS_OFF' = 'sensors_off',
  'SENTIMENT_DISSATISFIED' = 'sentiment_dissatisfied',
  'SENTIMENT_NEUTRAL' = 'sentiment_neutral',
  'SENTIMENT_SATISFIED' = 'sentiment_satisfied',
  'SENTIMENT_SATISFIED_ALT' = 'sentiment_satisfied_alt',
  'SENTIMENT_VERY_DISSATISFIED' = 'sentiment_very_dissatisfied',
  'SENTIMENT_VERY_SATISFIED' = 'sentiment_very_satisfied',
  'SET_MEAL' = 'set_meal',
  'SETTINGS' = 'settings',
  'SETTINGS_ACCESSIBILITY' = 'settings_accessibility',
  'SETTINGS_APPLICATIONS' = 'settings_applications',
  'SETTINGS_BACKUP_RESTORE' = 'settings_backup_restore',
  'SETTINGS_BLUETOOTH' = 'settings_bluetooth',
  'SETTINGS_BRIGHTNESS' = 'settings_brightness',
  'SETTINGS_CELL' = 'settings_cell',
  'SETTINGS_DISPLAY' = 'settings_display',
  'SETTINGS_ETHERNET' = 'settings_ethernet',
  'SETTINGS_INPUT_ANTENNA' = 'settings_input_antenna',
  'SETTINGS_INPUT_COMPONENT' = 'settings_input_component',
  'SETTINGS_INPUT_COMPOSITE' = 'settings_input_composite',
  'SETTINGS_INPUT_HDMI' = 'settings_input_hdmi',
  'SETTINGS_INPUT_SVIDEO' = 'settings_input_svideo',
  'SETTINGS_OVERSCAN' = 'settings_overscan',
  'SETTINGS_PHONE' = 'settings_phone',
  'SETTINGS_POWER' = 'settings_power',
  'SETTINGS_REMOTE' = 'settings_remote',
  'SETTINGS_SUGGEST' = 'settings_suggest',
  'SETTINGS_SYSTEM_DAYDREAM' = 'settings_system_daydream',
  'SETTINGS_VOICE' = 'settings_voice',
  'SEVERE_COLD' = 'severe_cold',
  'SHARE' = 'share',
  'SHARE_ARRIVAL_TIME' = 'share_arrival_time',
  'SHARE_LOCATION' = 'share_location',
  'SHIELD' = 'shield',
  'SHIELD_MOON' = 'shield_moon',
  'SHOP' = 'shop',
  'SHOP_2' = 'shop_2',
  'SHOP_TWO' = 'shop_two',
  'SHOPIFY' = 'shopify',
  'SHOPPING_BAG' = 'shopping_bag',
  'SHOPPING_BASKET' = 'shopping_basket',
  'SHOPPING_CART' = 'shopping_cart',
  'SHOPPING_CART_CHECKOUT' = 'shopping_cart_checkout',
  'SHORT_TEXT' = 'short_text',
  'SHORTCUT' = 'shortcut',
  'SHOW_CHART' = 'show_chart',
  'SHOWER' = 'shower',
  'SHUFFLE' = 'shuffle',
  'SHUFFLE_ON' = 'shuffle_on',
  'SHUTTER_SPEED' = 'shutter_speed',
  'SICK' = 'sick',
  'SIGN_LANGUAGE' = 'sign_language',
  'SIGNAL_CELLULAR_0_BAR' = 'signal_cellular_0_bar',
  'SIGNAL_CELLULAR_4_BAR' = 'signal_cellular_4_bar',
  'SIGNAL_CELLULAR_ALT' = 'signal_cellular_alt',
  'SIGNAL_CELLULAR_ALT_1_BAR' = 'signal_cellular_alt_1_bar',
  'SIGNAL_CELLULAR_ALT_2_BAR' = 'signal_cellular_alt_2_bar',
  'SIGNAL_CELLULAR_CONNECTED_NO_INTERNET_0_BAR' = 'signal_cellular_connected_no_internet_0_bar',
  'SIGNAL_CELLULAR_CONNECTED_NO_INTERNET_4_BAR' = 'signal_cellular_connected_no_internet_4_bar',
  'SIGNAL_CELLULAR_NO_SIM' = 'signal_cellular_no_sim',
  'SIGNAL_CELLULAR_NODATA' = 'signal_cellular_nodata',
  'SIGNAL_CELLULAR_NULL' = 'signal_cellular_null',
  'SIGNAL_CELLULAR_OFF' = 'signal_cellular_off',
  'SIGNAL_WIFI_0_BAR' = 'signal_wifi_0_bar',
  'SIGNAL_WIFI_4_BAR' = 'signal_wifi_4_bar',
  'SIGNAL_WIFI_4_BAR_LOCK' = 'signal_wifi_4_bar_lock',
  'SIGNAL_WIFI_BAD' = 'signal_wifi_bad',
  'SIGNAL_WIFI_CONNECTED_NO_INTERNET_4' = 'signal_wifi_connected_no_internet_4',
  'SIGNAL_WIFI_OFF' = 'signal_wifi_off',
  'SIGNAL_WIFI_STATUSBAR_4_BAR' = 'signal_wifi_statusbar_4_bar',
  'SIGNAL_WIFI_STATUSBAR_CONNECTED_NO_INTERNET_4' = 'signal_wifi_statusbar_connected_no_internet_4',
  'SIGNAL_WIFI_STATUSBAR_NULL' = 'signal_wifi_statusbar_null',
  'SIGNPOST' = 'signpost',
  'SIM_CARD' = 'sim_card',
  'SIM_CARD_ALERT' = 'sim_card_alert',
  'SIM_CARD_DOWNLOAD' = 'sim_card_download',
  'SINGLE_BED' = 'single_bed',
  'SIP' = 'sip',
  'SKATEBOARDING' = 'skateboarding',
  'SKIP_NEXT' = 'skip_next',
  'SKIP_PREVIOUS' = 'skip_previous',
  'SLEDDING' = 'sledding',
  'SLIDESHOW' = 'slideshow',
  'SLOW_MOTION_VIDEO' = 'slow_motion_video',
  'SMART_BUTTON' = 'smart_button',
  'SMART_DISPLAY' = 'smart_display',
  'SMART_SCREEN' = 'smart_screen',
  'SMART_TOY' = 'smart_toy',
  'SMARTPHONE' = 'smartphone',
  'SMOKE_FREE' = 'smoke_free',
  'SMOKING_ROOMS' = 'smoking_rooms',
  'SMS' = 'sms',
  'SMS_FAILED' = 'sms_failed',
  'SNAPCHAT' = 'snapchat',
  'SNIPPET_FOLDER' = 'snippet_folder',
  'SNOOZE' = 'snooze',
  'SNOWBOARDING' = 'snowboarding',
  'SNOWING' = 'snowing',
  'SNOWMOBILE' = 'snowmobile',
  'SNOWSHOEING' = 'snowshoeing',
  'SOAP' = 'soap',
  'SOCIAL_DISTANCE' = 'social_distance',
  'SOLAR_POWER' = 'solar_power',
  'SORT' = 'sort',
  'SORT_BY_ALPHA' = 'sort_by_alpha',
  'SOS' = 'sos',
  'SOUP_KITCHEN' = 'soup_kitchen',
  'SOURCE' = 'source',
  'SOUTH' = 'south',
  'SOUTH_AMERICA' = 'south_america',
  'SOUTH_EAST' = 'south_east',
  'SOUTH_WEST' = 'south_west',
  'SPA' = 'spa',
  'SPACE_BAR' = 'space_bar',
  'SPACE_DASHBOARD' = 'space_dashboard',
  'SPATIAL_AUDIO' = 'spatial_audio',
  'SPATIAL_AUDIO_OFF' = 'spatial_audio_off',
  'SPATIAL_TRACKING' = 'spatial_tracking',
  'SPEAKER' = 'speaker',
  'SPEAKER_GROUP' = 'speaker_group',
  'SPEAKER_NOTES' = 'speaker_notes',
  'SPEAKER_NOTES_OFF' = 'speaker_notes_off',
  'SPEAKER_PHONE' = 'speaker_phone',
  'SPEED' = 'speed',
  'SPELLCHECK' = 'spellcheck',
  'SPLITSCREEN' = 'splitscreen',
  'SPOKE' = 'spoke',
  'SPORTS' = 'sports',
  'SPORTS_BAR' = 'sports_bar',
  'SPORTS_BASEBALL' = 'sports_baseball',
  'SPORTS_BASKETBALL' = 'sports_basketball',
  'SPORTS_CRICKET' = 'sports_cricket',
  'SPORTS_ESPORTS' = 'sports_esports',
  'SPORTS_FOOTBALL' = 'sports_football',
  'SPORTS_GOLF' = 'sports_golf',
  'SPORTS_GYMNASTICS' = 'sports_gymnastics',
  'SPORTS_HANDBALL' = 'sports_handball',
  'SPORTS_HOCKEY' = 'sports_hockey',
  'SPORTS_KABADDI' = 'sports_kabaddi',
  'SPORTS_MARTIAL_ARTS' = 'sports_martial_arts',
  'SPORTS_MMA' = 'sports_mma',
  'SPORTS_MOTORSPORTS' = 'sports_motorsports',
  'SPORTS_RUGBY' = 'sports_rugby',
  'SPORTS_SCORE' = 'sports_score',
  'SPORTS_SOCCER' = 'sports_soccer',
  'SPORTS_TENNIS' = 'sports_tennis',
  'SPORTS_VOLLEYBALL' = 'sports_volleyball',
  'SQUARE' = 'square',
  'SQUARE_FOOT' = 'square_foot',
  'SSID_CHART' = 'ssid_chart',
  'STACKED_BAR_CHART' = 'stacked_bar_chart',
  'STACKED_LINE_CHART' = 'stacked_line_chart',
  'STADIUM' = 'stadium',
  'STAIRS' = 'stairs',
  'STAR' = 'star',
  'STAR_BORDER' = 'star_border',
  'STAR_BORDER_PURPLE500' = 'star_border_purple500',
  'STAR_HALF' = 'star_half',
  'STAR_OUTLINE' = 'star_outline',
  'STAR_PURPLE500' = 'star_purple500',
  'STAR_RATE' = 'star_rate',
  'STARS' = 'stars',
  'START' = 'start',
  'STAY_CURRENT_LANDSCAPE' = 'stay_current_landscape',
  'STAY_CURRENT_PORTRAIT' = 'stay_current_portrait',
  'STAY_PRIMARY_LANDSCAPE' = 'stay_primary_landscape',
  'STAY_PRIMARY_PORTRAIT' = 'stay_primary_portrait',
  'STICKY_NOTE_2' = 'sticky_note_2',
  'STOP' = 'stop',
  'STOP_CIRCLE' = 'stop_circle',
  'STOP_SCREEN_SHARE' = 'stop_screen_share',
  'STORAGE' = 'storage',
  'STORE' = 'store',
  'STORE_MALL_DIRECTORY' = 'store_mall_directory',
  'STOREFRONT' = 'storefront',
  'STORM' = 'storm',
  'STRAIGHT' = 'straight',
  'STRAIGHTEN' = 'straighten',
  'STREAM' = 'stream',
  'STREETVIEW' = 'streetview',
  'STRIKETHROUGH_S' = 'strikethrough_s',
  'STROLLER' = 'stroller',
  'STYLE' = 'style',
  'SUBDIRECTORY_ARROW_LEFT' = 'subdirectory_arrow_left',
  'SUBDIRECTORY_ARROW_RIGHT' = 'subdirectory_arrow_right',
  'SUBJECT' = 'subject',
  'SUBSCRIPT' = 'subscript',
  'SUBSCRIPTIONS' = 'subscriptions',
  'SUBTITLES' = 'subtitles',
  'SUBTITLES_OFF' = 'subtitles_off',
  'SUBWAY' = 'subway',
  'SUMMARIZE' = 'summarize',
  'SUNNY' = 'sunny',
  'SUNNY_SNOWING' = 'sunny_snowing',
  'SUPERSCRIPT' = 'superscript',
  'SUPERVISED_USER_CIRCLE' = 'supervised_user_circle',
  'SUPERVISOR_ACCOUNT' = 'supervisor_account',
  'SUPPORT' = 'support',
  'SUPPORT_AGENT' = 'support_agent',
  'SURFING' = 'surfing',
  'SURROUND_SOUND' = 'surround_sound',
  'SWAP_CALLS' = 'swap_calls',
  'SWAP_HORIZ' = 'swap_horiz',
  'SWAP_HORIZONTAL_CIRCLE' = 'swap_horizontal_circle',
  'SWAP_VERT' = 'swap_vert',
  'SWAP_VERT_CIRCLE' = 'swap_vert_circle',
  'SWAP_VERTICAL_CIRCLE' = 'swap_vertical_circle',
  'SWIPE' = 'swipe',
  'SWIPE_DOWN' = 'swipe_down',
  'SWIPE_DOWN_ALT' = 'swipe_down_alt',
  'SWIPE_LEFT' = 'swipe_left',
  'SWIPE_LEFT_ALT' = 'swipe_left_alt',
  'SWIPE_RIGHT' = 'swipe_right',
  'SWIPE_RIGHT_ALT' = 'swipe_right_alt',
  'SWIPE_UP' = 'swipe_up',
  'SWIPE_UP_ALT' = 'swipe_up_alt',
  'SWIPE_VERTICAL' = 'swipe_vertical',
  'SWITCH_ACCESS_SHORTCUT' = 'switch_access_shortcut',
  'SWITCH_ACCESS_SHORTCUT_ADD' = 'switch_access_shortcut_add',
  'SWITCH_ACCOUNT' = 'switch_account',
  'SWITCH_CAMERA' = 'switch_camera',
  'SWITCH_LEFT' = 'switch_left',
  'SWITCH_RIGHT' = 'switch_right',
  'SWITCH_VIDEO' = 'switch_video',
  'SYNAGOGUE' = 'synagogue',
  'SYNC' = 'sync',
  'SYNC_ALT' = 'sync_alt',
  'SYNC_DISABLED' = 'sync_disabled',
  'SYNC_LOCK' = 'sync_lock',
  'SYNC_PROBLEM' = 'sync_problem',
  'SYSTEM_SECURITY_UPDATE' = 'system_security_update',
  'SYSTEM_SECURITY_UPDATE_GOOD' = 'system_security_update_good',
  'SYSTEM_SECURITY_UPDATE_WARNING' = 'system_security_update_warning',
  'SYSTEM_UPDATE' = 'system_update',
  'SYSTEM_UPDATE_ALT' = 'system_update_alt',
  'SYSTEM_UPDATE_TV' = 'system_update_tv',
  'TAB' = 'tab',
  'TAB_UNSELECTED' = 'tab_unselected',
  'TABLE_BAR' = 'table_bar',
  'TABLE_CHART' = 'table_chart',
  'TABLE_RESTAURANT' = 'table_restaurant',
  'TABLE_ROWS' = 'table_rows',
  'TABLE_VIEW' = 'table_view',
  'TABLET' = 'tablet',
  'TABLET_ANDROID' = 'tablet_android',
  'TABLET_MAC' = 'tablet_mac',
  'TAG' = 'tag',
  'TAG_FACES' = 'tag_faces',
  'TAKEOUT_DINING' = 'takeout_dining',
  'TAP_AND_PLAY' = 'tap_and_play',
  'TAPAS' = 'tapas',
  'TASK' = 'task',
  'TASK_ALT' = 'task_alt',
  'TAXI_ALERT' = 'taxi_alert',
  'TELEGRAM' = 'telegram',
  'TEMPLE_BUDDHIST' = 'temple_buddhist',
  'TEMPLE_HINDU' = 'temple_hindu',
  'TERMINAL' = 'terminal',
  'TERRAIN' = 'terrain',
  'TEXT_DECREASE' = 'text_decrease',
  'TEXT_FIELDS' = 'text_fields',
  'TEXT_FORMAT' = 'text_format',
  'TEXT_INCREASE' = 'text_increase',
  'TEXT_ROTATE_UP' = 'text_rotate_up',
  'TEXT_ROTATE_VERTICAL' = 'text_rotate_vertical',
  'TEXT_ROTATION_ANGLEDOWN' = 'text_rotation_angledown',
  'TEXT_ROTATION_ANGLEUP' = 'text_rotation_angleup',
  'TEXT_ROTATION_DOWN' = 'text_rotation_down',
  'TEXT_ROTATION_NONE' = 'text_rotation_none',
  'TEXT_SNIPPET' = 'text_snippet',
  'TEXTSMS' = 'textsms',
  'TEXTURE' = 'texture',
  'THEATER_COMEDY' = 'theater_comedy',
  'THEATERS' = 'theaters',
  'THERMOSTAT' = 'thermostat',
  'THERMOSTAT_AUTO' = 'thermostat_auto',
  'THUMB_DOWN' = 'thumb_down',
  'THUMB_DOWN_ALT' = 'thumb_down_alt',
  'THUMB_DOWN_OFF_ALT' = 'thumb_down_off_alt',
  'THUMB_UP' = 'thumb_up',
  'THUMB_UP_ALT' = 'thumb_up_alt',
  'THUMB_UP_OFF_ALT' = 'thumb_up_off_alt',
  'THUMBS_UP_DOWN' = 'thumbs_up_down',
  'THUNDERSTORM' = 'thunderstorm',
  'TIKTOK' = 'tiktok',
  'TIME_TO_LEAVE' = 'time_to_leave',
  'TIMELAPSE' = 'timelapse',
  'TIMELINE' = 'timeline',
  'TIMER' = 'timer',
  'TIMER_10' = 'timer_10',
  'TIMER_10_SELECT' = 'timer_10_select',
  'TIMER_3' = 'timer_3',
  'TIMER_3_SELECT' = 'timer_3_select',
  'TIMER_OFF' = 'timer_off',
  'TIPS_AND_UPDATES' = 'tips_and_updates',
  'TIRE_REPAIR' = 'tire_repair',
  'TITLE' = 'title',
  'TOC' = 'toc',
  'TODAY' = 'today',
  'TOGGLE_OFF' = 'toggle_off',
  'TOGGLE_ON' = 'toggle_on',
  'TOKEN' = 'token',
  'TOLL' = 'toll',
  'TONALITY' = 'tonality',
  'TOPIC' = 'topic',
  'TORNADO' = 'tornado',
  'TOUCH_APP' = 'touch_app',
  'TOUR' = 'tour',
  'TOYS' = 'toys',
  'TRACK_CHANGES' = 'track_changes',
  'TRAFFIC' = 'traffic',
  'TRAIN' = 'train',
  'TRAM' = 'tram',
  'TRANSCRIBE' = 'transcribe',
  'TRANSFER_WITHIN_A_STATION' = 'transfer_within_a_station',
  'TRANSFORM' = 'transform',
  'TRANSGENDER' = 'transgender',
  'TRANSIT_ENTEREXIT' = 'transit_enterexit',
  'TRANSLATE' = 'translate',
  'TRAVEL_EXPLORE' = 'travel_explore',
  'TRENDING_DOWN' = 'trending_down',
  'TRENDING_FLAT' = 'trending_flat',
  'TRENDING_NEUTRAL' = 'trending_neutral',
  'TRENDING_UP' = 'trending_up',
  'TRIP_ORIGIN' = 'trip_origin',
  'TROUBLESHOOT' = 'troubleshoot',
  'TRY' = 'try',
  'TSUNAMI' = 'tsunami',
  'TTY' = 'tty',
  'TUNE' = 'tune',
  'TUNGSTEN' = 'tungsten',
  'TURN_LEFT' = 'turn_left',
  'TURN_RIGHT' = 'turn_right',
  'TURN_SHARP_LEFT' = 'turn_sharp_left',
  'TURN_SHARP_RIGHT' = 'turn_sharp_right',
  'TURN_SLIGHT_LEFT' = 'turn_slight_left',
  'TURN_SLIGHT_RIGHT' = 'turn_slight_right',
  'TURNED_IN' = 'turned_in',
  'TURNED_IN_NOT' = 'turned_in_not',
  'TV' = 'tv',
  'TV_OFF' = 'tv_off',
  'TWO_WHEELER' = 'two_wheeler',
  'TYPE_SPECIMEN' = 'type_specimen',
  'U_TURN_LEFT' = 'u_turn_left',
  'U_TURN_RIGHT' = 'u_turn_right',
  'UMBRELLA' = 'umbrella',
  'UNARCHIVE' = 'unarchive',
  'UNDO' = 'undo',
  'UNFOLD_LESS' = 'unfold_less',
  'UNFOLD_MORE' = 'unfold_more',
  'UNPUBLISHED' = 'unpublished',
  'UNSUBSCRIBE' = 'unsubscribe',
  'UPCOMING' = 'upcoming',
  'UPDATE' = 'update',
  'UPDATE_DISABLED' = 'update_disabled',
  'UPGRADE' = 'upgrade',
  'UPLOAD' = 'upload',
  'UPLOAD_FILE' = 'upload_file',
  'USB' = 'usb',
  'USB_OFF' = 'usb_off',
  'VACCINES' = 'vaccines',
  'VAPE_FREE' = 'vape_free',
  'VAPING_ROOMS' = 'vaping_rooms',
  'VERIFIED' = 'verified',
  'VERIFIED_USER' = 'verified_user',
  'VERTICAL_ALIGN_BOTTOM' = 'vertical_align_bottom',
  'VERTICAL_ALIGN_CENTER' = 'vertical_align_center',
  'VERTICAL_ALIGN_TOP' = 'vertical_align_top',
  'VERTICAL_DISTRIBUTE' = 'vertical_distribute',
  'VERTICAL_SHADES' = 'vertical_shades',
  'VERTICAL_SHADES_CLOSED' = 'vertical_shades_closed',
  'VERTICAL_SPLIT' = 'vertical_split',
  'VIBRATION' = 'vibration',
  'VIDEO_CALL' = 'video_call',
  'VIDEO_CAMERA_BACK' = 'video_camera_back',
  'VIDEO_CAMERA_FRONT' = 'video_camera_front',
  'VIDEO_COLLECTION' = 'video_collection',
  'VIDEO_FILE' = 'video_file',
  'VIDEO_LABEL' = 'video_label',
  'VIDEO_LIBRARY' = 'video_library',
  'VIDEO_SETTINGS' = 'video_settings',
  'VIDEO_STABLE' = 'video_stable',
  'VIDEOCAM' = 'videocam',
  'VIDEOCAM_OFF' = 'videocam_off',
  'VIDEOGAME_ASSET' = 'videogame_asset',
  'VIDEOGAME_ASSET_OFF' = 'videogame_asset_off',
  'VIEW_AGENDA' = 'view_agenda',
  'VIEW_ARRAY' = 'view_array',
  'VIEW_CAROUSEL' = 'view_carousel',
  'VIEW_COLUMN' = 'view_column',
  'VIEW_COMFORTABLE' = 'view_comfortable',
  'VIEW_COMFY' = 'view_comfy',
  'VIEW_COMFY_ALT' = 'view_comfy_alt',
  'VIEW_COMPACT' = 'view_compact',
  'VIEW_COMPACT_ALT' = 'view_compact_alt',
  'VIEW_COZY' = 'view_cozy',
  'VIEW_DAY' = 'view_day',
  'VIEW_HEADLINE' = 'view_headline',
  'VIEW_IN_AR' = 'view_in_ar',
  'VIEW_KANBAN' = 'view_kanban',
  'VIEW_LIST' = 'view_list',
  'VIEW_MODULE' = 'view_module',
  'VIEW_QUILT' = 'view_quilt',
  'VIEW_SIDEBAR' = 'view_sidebar',
  'VIEW_STREAM' = 'view_stream',
  'VIEW_TIMELINE' = 'view_timeline',
  'VIEW_WEEK' = 'view_week',
  'VIGNETTE' = 'vignette',
  'VILLA' = 'villa',
  'VISIBILITY' = 'visibility',
  'VISIBILITY_OFF' = 'visibility_off',
  'VOICE_CHAT' = 'voice_chat',
  'VOICE_OVER_OFF' = 'voice_over_off',
  'VOICEMAIL' = 'voicemail',
  'VOLCANO' = 'volcano',
  'VOLUME_DOWN' = 'volume_down',
  'VOLUME_DOWN_ALT' = 'volume_down_alt',
  'VOLUME_MUTE' = 'volume_mute',
  'VOLUME_OFF' = 'volume_off',
  'VOLUME_UP' = 'volume_up',
  'VOLUNTEER_ACTIVISM' = 'volunteer_activism',
  'VPN_KEY' = 'vpn_key',
  'VPN_KEY_OFF' = 'vpn_key_off',
  'VPN_LOCK' = 'vpn_lock',
  'VRPANO' = 'vrpano',
  'WALLET' = 'wallet',
  'WALLET_GIFTCARD' = 'wallet_giftcard',
  'WALLET_MEMBERSHIP' = 'wallet_membership',
  'WALLET_TRAVEL' = 'wallet_travel',
  'WALLPAPER' = 'wallpaper',
  'WAREHOUSE' = 'warehouse',
  'WARNING' = 'warning',
  'WARNING_AMBER' = 'warning_amber',
  'WASH' = 'wash',
  'WATCH' = 'watch',
  'WATCH_LATER' = 'watch_later',
  'WATCH_OFF' = 'watch_off',
  'WATER' = 'water',
  'WATER_DAMAGE' = 'water_damage',
  'WATER_DROP' = 'water_drop',
  'WATERFALL_CHART' = 'waterfall_chart',
  'WAVES' = 'waves',
  'WAVING_HAND' = 'waving_hand',
  'WB_AUTO' = 'wb_auto',
  'WB_CLOUDY' = 'wb_cloudy',
  'WB_INCANDESCENT' = 'wb_incandescent',
  'WB_IRIDESCENT' = 'wb_iridescent',
  'WB_SHADE' = 'wb_shade',
  'WB_SUNNY' = 'wb_sunny',
  'WB_TWIGHLIGHT' = 'wb_twighlight',
  'WB_TWILIGHT' = 'wb_twilight',
  'WC' = 'wc',
  'WEB' = 'web',
  'WEB_ASSET' = 'web_asset',
  'WEB_ASSET_OFF' = 'web_asset_off',
  'WEB_STORIES' = 'web_stories',
  'WEBHOOK' = 'webhook',
  'WECHAT' = 'wechat',
  'WEEKEND' = 'weekend',
  'WEST' = 'west',
  'WHATSAPP' = 'whatsapp',
  'WHATSHOT' = 'whatshot',
  'WHEELCHAIR_PICKUP' = 'wheelchair_pickup',
  'WHERE_TO_VOTE' = 'where_to_vote',
  'WIDGETS' = 'widgets',
  'WIDTH_FULL' = 'width_full',
  'WIDTH_NORMAL' = 'width_normal',
  'WIDTH_WIDE' = 'width_wide',
  'WIFI' = 'wifi',
  'WIFI_1_BAR' = 'wifi_1_bar',
  'WIFI_2_BAR' = 'wifi_2_bar',
  'WIFI_CALLING' = 'wifi_calling',
  'WIFI_CALLING_3' = 'wifi_calling_3',
  'WIFI_CHANNEL' = 'wifi_channel',
  'WIFI_FIND' = 'wifi_find',
  'WIFI_LOCK' = 'wifi_lock',
  'WIFI_OFF' = 'wifi_off',
  'WIFI_PASSWORD' = 'wifi_password',
  'WIFI_PROTECTED_SETUP' = 'wifi_protected_setup',
  'WIFI_TETHERING' = 'wifi_tethering',
  'WIFI_TETHERING_ERROR' = 'wifi_tethering_error',
  'WIFI_TETHERING_ERROR_ROUNDED' = 'wifi_tethering_error_rounded',
  'WIFI_TETHERING_OFF' = 'wifi_tethering_off',
  'WIND_POWER' = 'wind_power',
  'WINDOW' = 'window',
  'WINE_BAR' = 'wine_bar',
  'WOMAN' = 'woman',
  'WOO_COMMERCE' = 'woo_commerce',
  'WORDPRESS' = 'wordpress',
  'WORK' = 'work',
  'WORK_HISTORY' = 'work_history',
  'WORK_OFF' = 'work_off',
  'WORK_OUTLINE' = 'work_outline',
  'WORKSPACE_PREMIUM' = 'workspace_premium',
  'WORKSPACES' = 'workspaces',
  'WORKSPACES_FILLED' = 'workspaces_filled',
  'WORKSPACES_OUTLINE' = 'workspaces_outline',
  'WRAP_TEXT' = 'wrap_text',
  'WRONG_LOCATION' = 'wrong_location',
  'WYSIWYG' = 'wysiwyg',
  'YARD' = 'yard',
  'YOUTUBE_SEARCHED_FOR' = 'youtube_searched_for',
  'ZOOM_IN' = 'zoom_in',
  'ZOOM_IN_MAP' = 'zoom_in_map',
  'ZOOM_OUT' = 'zoom_out',
  'ZOOM_OUT_MAP' = 'zoom_out_map',
}
