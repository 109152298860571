import { graphql } from '@infinitus/generated/frontend-common';

export const TASKS_IN_PROGRESS_FOR_CURRENT_USER = graphql(/* GraphQL */ `
  query tasksInProgressForCurrentUser($orgUUID: ID!) {
    tasksInProgressForCurrentUser(orgUUID: $orgUUID) {
      id
      taskCreationMillis
      callUuids
      mostRecentCall {
        id
        audioStartMillis
        audioEndMillis
      }
      customerAssignedId
      taskType
      flexibleInputs {
        ...FlexibleInputFragment
      }
    }
  }
`);
