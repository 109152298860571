import { SxProps, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import { Icon, IconNames } from '@infinitus/components/Icon';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import ThemeColorTypes from '@infinitus/types/theme-color-types';

import { SwitchEvent, logSwitchEvent } from './SwitchLogs';

interface SwitchProps {
  ariaLabel?: string;
  color?: ThemeColorTypes;
  defaultChecked?: boolean;
  disabled?: boolean;
  hideLabels?: boolean;
  iconName?: IconNames;
  // Requiring this makes these always controlled
  isOn: boolean;
  label: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  logFunction?: (logData: SwitchEvent) => void;
  onChange: (isOn: boolean) => unknown;
  size?: 'small' | 'medium';
  sx?: SxProps;
  testId?: string;
}

function Switch({
  ariaLabel,
  color,
  disabled = false,
  hideLabels,
  iconName,
  isOn,
  label,
  labelPlacement = 'start',
  logFunction = logSwitchEvent,
  onChange,
  size,
  sx,
  testId,
  ...props
}: SwitchProps) {
  const theme = useTheme();
  // The following 2 conditions enforce accessibility
  if (label === '') {
    console.error(
      '<Switch /> must have an actual label for accessibility. To hide it, use `hideLabels` prop.'
    );
  }
  if (!ariaLabel) {
    ariaLabel = label;
  }

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    // Using event.target.value returns a string
    // This approach returns a boolean
    onChange(evt.target.checked);
    logFunction({
      eventValue: evt.target.checked ? 'on' : 'off',
      label: label,
    });
  }

  function renderThumb() {
    if (!iconName) return null;
    return (
      <div
        style={{
          alignItems: 'center',
          backgroundColor: isOn && !disabled ? theme.palette.primary.dark : 'currentcolor',
          borderRadius: '50%',
          display: 'flex',
          height: 24,
          justifyContent: 'center',
          marginBlockStart: -2,
          marginInlineStart: -2,
          width: 24,
        }}
      >
        <Icon
          name={iconName}
          size={ShirtSizes.XS}
          sx={{
            color: isOn && !disabled ? theme.palette.text.primary : theme.palette.primary.dark,
          }}
        />
      </div>
    );
  }

  // Including `icon` and `checkedIcon` with null or undefined values
  // eliminates the thumb/knob altogether
  function mergedProps() {
    if (!iconName) return props;
    return {
      ...props,
      checkedIcon: renderThumb(),
      // equivalent to uncheckedIcon
      icon: renderThumb(),
    };
  }

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          {...mergedProps()}
          checked={isOn}
          disabled={disabled}
          inputProps={
            {
              'aria-checked': isOn,
              'aria-label': ariaLabel,
              // This is used for selecting the element in tests
              'data-cy': testId,
            } as MuiSwitchProps['inputProps']
          }
          onChange={handleChange}
          size={size}
        />
      }
      disabled={disabled}
      label={hideLabels ? null : label}
      labelPlacement={labelPlacement}
      sx={sx}
    />
  );
}

export { Switch };
