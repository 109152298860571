import { SxProps } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { Link as RrLink } from 'react-router-dom';

interface LinkProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  onClick?: (evt: React.SyntheticEvent) => void;
  openInNewTab?: boolean;
  sx?: SxProps;
  testId?: string;
  to: string;
  underline?: 'always' | 'hover' | 'none';
}

export function Link({
  className,
  children,
  color,
  onClick,
  openInNewTab,
  sx,
  testId,
  to,
  underline = 'hover',
}: LinkProps) {
  return (
    <MuiLink
      className={className}
      color={color}
      component={RrLink}
      data-cy={testId}
      onClick={onClick}
      sx={{ ...sx }}
      target={openInNewTab ? '_blank' : undefined}
      to={to}
      underline={underline}
    >
      {children}
    </MuiLink>
  );
}
