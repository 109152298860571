import { CohortInput, ExperimentInput } from '@infinitus/generated/frontend-common';

export const OLD_VONAGE_SDK_COHORT_KEY = 'control';
export const NEW_VONAGE_SDK_COHORT_KEY = 'treatment';

// All participants will be in the control cohort unless explicitly assigned to
// the treatment cohort by an engineer in the db
const cohortDistribution: CohortInput[] = [
  { cohortName: OLD_VONAGE_SDK_COHORT_KEY, weight: 1 },
  { cohortName: NEW_VONAGE_SDK_COHORT_KEY, weight: 0 },
];

export function vonageSdkUpgradeParticipant(userEmail: string): ExperimentInput {
  const experimentName = 'vonage_sdk_upgrade';
  // participantID should be unique per experimental subject - i.e. if we want to apply treatments to calls;
  // each call should have a distinct participantID. The sample below will do the trick but is a bit overkill.
  const participantID = userEmail;
  return {
    participantID,
    experimentName,
    cohortDistribution,
  };
}
