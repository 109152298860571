import { graphql } from '@infinitus/generated/frontend-common';

// Not used anywhere?
export const FlexibleTaskInputsAndConfigDocument = graphql(/* GraphQL */ `
  query GetFlexibleTaskInputsAndConfig($orgUuid: ID!, $taskUuid: ID!, $taskType: TaskType!) {
    taskInputs(orgUUID: $orgUuid, taskUUID: $taskUuid) {
      customerAssignedId
      programName
      customerSegment
      inputs {
        ...FlexibleInputFragment
      }
    }
    taskInputsConfig(orgUUID: $orgUuid, taskType: $taskType) {
      ...InputConfigFragment
      # get nestedFields for objects
      nestedFields {
        ...InputConfigFragment
        # get scalar arrays for objects
        arrayOf {
          ...InputConfigFragment
        }
      }
      arrayOf {
        ...InputConfigFragment
        # get nestedFields for objects
        nestedFields {
          ...InputConfigFragment
        }
      }
    }
  }
`);

// Only used in FastTrack
export const FlexibleTaskInputsDocument = graphql(/* GraphQL */ `
  query GetFlexibleTaskInputs($orgUuid: ID!, $taskUuid: ID!) {
    taskInputs(orgUUID: $orgUuid, taskUUID: $taskUuid) {
      taskType
      customerAssignedId
      programName
      customerSegment
      inputs {
        ...FlexibleInputFragment
      }
    }
  }
`);
