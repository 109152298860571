import { graphql } from '@infinitus/generated/frontend-common';

// Used in both OperatorPortal and FastTrack via useMyRecordedGreetingsEditor
export const LIST_GREETINGS = graphql(/* GraphQL */ `
  query ListGreetings($orgUUID: ID!) {
    listGreetings(orgUUID: $orgUUID) {
      id
      greetingName
      recordedText
      gcsSignedURL
      approvalStatus
    }
  }
`);

// Used in both OperatorPortal and FastTrack via useMyRecordedGreetingsEditor
export const LIST_GREETING_TEMPLATES = graphql(/* GraphQL */ `
  query ListGreetingTemplates($orgUUID: ID!) {
    listGreetingTemplates(orgUUID: $orgUUID) {
      greetingName
      recordedText
    }
  }
`);

// Only used in FastTrack
export const LIST_ORG_GREETINGS = graphql(/* GraphQL */ `
  query ListOrgGreetings($orgUUID: ID!) {
    listOrgGreetings(orgUUID: $orgUUID) {
      orgId
      userEmail
      id
      createTime
      greetingName
      recordedText
      gcsPath
      gcsSignedURL
      approvalStatus
      approvedBy
    }
  }
`);

// Only used in FastTrack
export const APPROVE_GREETING = graphql(/* GraphQL */ `
  mutation ApproveGreeting($id: ID!) {
    approveRecordedGreeting(id: $id) {
      id
      approvalStatus
      approvedBy
    }
  }
`);

// Only used in FastTrack
export const DELETE_GREETING = graphql(/* GraphQL */ `
  mutation DeleteGreeting($id: ID!) {
    deleteRecordedGreeting(id: $id) {
      id
      gcsPath
      gcsSignedURL
    }
  }
`);

// Used in both OperatorPortal and FastTrack via useMyRecordedGreetingsEditor
export const UPLOAD_GREETING = graphql(/* GraphQL */ `
  mutation UploadGreeting(
    $orgUUID: ID!
    $greetingName: String!
    $recordedText: String!
    $base64AudioBytes: String!
  ) {
    uploadGreeting(
      orgUUID: $orgUUID
      greetingName: $greetingName
      recordedText: $recordedText
      base64AudioBytes: $base64AudioBytes
    ) {
      greetingName
    }
  }
`);

// Only used in FastTrack
export const UPDATE_GREETING_TEMPLATES = graphql(/* GraphQL */ `
  mutation UpdateGreetingTemplates(
    $orgUUID: ID!
    $newTemplates: [GreetingTemplateInput!]!
    $oldTemplates: [GreetingTemplateInput!]!
  ) {
    updateGreetingTemplates(
      orgUUID: $orgUUID
      newTemplates: $newTemplates
      oldTemplates: $oldTemplates
    ) {
      greetingName
      recordedText
    }
  }
`);
