import { Navigate, useParams, useLocation } from 'react-router-dom';

function OldOperatorRoute() {
  const { org } = useParams();
  const location = useLocation();

  return (
    <Navigate
      to={`/${org || 'infinitus'}${location.pathname.replace(`/${org || 'infinitus'}`, '')}${
        location.search
      }`}
    />
  );
}

export default OldOperatorRoute;
