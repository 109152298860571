import { ProviderContext, OptionsObject } from 'notistack';

let snackbarContext: ProviderContext;

// Extend notistack's OptionsObject to pass data-attribute to HTML element
export interface ExtendedOptionsObject extends OptionsObject {
  'data-cy': string;
}

export const useSnackbar = (): ProviderContext => {
  if (!snackbarContext) {
    throw new Error(
      'CustomSnackbarProvider must be used as a child of SnackbarProvider somewhere in the app'
    );
  }

  return snackbarContext;
};

export const setSnackbarContext = (context: ProviderContext): void => {
  snackbarContext = context;
};

export default useSnackbar;
