import { logUiEvent } from '@infinitus/hooks/useLogBuffer';

export interface AutocompleteEvent {
  eventName: 'blur' | 'focus' | 'change';
  eventValue: any;
  label: string;
}

export default function logAutocompleteEvent({ label, eventName, eventValue }: AutocompleteEvent) {
  logUiEvent({
    componentLabel: label,
    componentName: 'Autocomplete',
    eventName,
    eventValue,
  });
}
