const DEBUG_MODE_KEY = 'infWsDebugMode';

function isWsInDebugMode() {
  const isDebugMode = localStorage.getItem(DEBUG_MODE_KEY) === 'true';
  return isDebugMode;
}

function toggleDebugMode() {
  const isDebugMode = isWsInDebugMode();
  localStorage.setItem(DEBUG_MODE_KEY, String(!isDebugMode));
  (window as any).INF_WS_DEBUG_MODE = isWsInDebugMode();
}

(window as any).INF_WS_DEBUG_MODE = isWsInDebugMode();
(window as any).toggleDebugMode = toggleDebugMode;

export function isWSDebugEnabled() {
  return (window as any).INF_WS_DEBUG_MODE;
}
