import { useQuery, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { ExperimentInput, graphql } from '@infinitus/generated/frontend-common';

// Only used in OperatorPortal
export const ParticipatingInExperimentDocument = graphql(/* GraphQL */ `
  query ParticipatingInExperiment($experimentInput: ExperimentInput!) {
    participatingInExperiment(experimentInput: $experimentInput) {
      isEligible
      assignedCohort
    }
  }
`);

// Only used in OperatorPortal
export const LogMetricForExperimentDocument = graphql(/* GraphQL */ `
  mutation LogMetricForExperiment($experimentInput: ExperimentInput!, $metrics: Map!) {
    logMetricForExperiment(experimentInput: $experimentInput, metrics: $metrics)
  }
`);

export interface useExperimentArgs {
  experiment: ExperimentInput;
}

export default function useExperiment({ experiment }: useExperimentArgs) {
  const [logMetricsForExperiment] = useMutation(LogMetricForExperimentDocument);

  const res = useQuery(ParticipatingInExperimentDocument, {
    variables: {
      experimentInput: experiment,
    },
  });

  const logExperimentMetric = useCallback(
    async (metrics: any = {}) => {
      // No op when we try to log metrics for an experiment that the user is not participating in
      if (!res.data?.participatingInExperiment?.isEligible) {
        return;
      }

      try {
        await logMetricsForExperiment({
          variables: {
            experimentInput: experiment,
            metrics: metrics,
          },
        });
      } catch (e: any) {
        console.error(`failed to logMetricsForExperiment ${e?.message || e}`);
      }
    },
    [experiment, logMetricsForExperiment, res.data?.participatingInExperiment?.isEligible]
  );

  const isInCohort = useCallback(
    (cohort: string) => {
      return (
        !!res.data?.participatingInExperiment?.isEligible &&
        res.data?.participatingInExperiment?.assignedCohort === cohort
      );
    },
    [
      res.data?.participatingInExperiment?.assignedCohort,
      res.data?.participatingInExperiment?.isEligible,
    ]
  );

  return useMemo(
    () => ({
      ...res,
      logExperimentMetric,
      isInCohort,
    }),
    [isInCohort, logExperimentMetric, res]
  );
}
